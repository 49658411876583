import { boxShadow, borderRadius, formatTimestamp } from "../utility/utility";
import { FiXCircle } from "react-icons/fi";
import DarkOverlay from "./DarkOverlay";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  goToTranscript,
  actuallyCreateTranscript,
  saveTranscriptAudio,
} from "../transcriptSlice";
import { v4 as uuidv4 } from "uuid";
import Button from "./Button";

const UploadAudioPanel = ({ setDisplayUploadAudioPanel, supabase }) => {
  const [isUploadingAudio, setIsUploadingAudio] = useState(false);
  const [isValidFile, setIsValidFile] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const userId = useSelector((state) => state.routes.userId);
  const transcriptId = uuidv4();
  const dispatch = useDispatch();
  const errorMessage = useRef();
  const acceptedAudioFormats = [
    "mp3",
    "mp4",
    "mpeg",
    "mpga",
    "m4a",
    "wav",
    "webm",
  ];

  // Determine if the selected file is valid
  const checkIfValidFile = (file) => {
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / 1024 ** 2;
      const MAX_FILE_SIZE_IN_MB = 25;
      // check if file size is under MAX_FILE_SIZE_IN_MB
      if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
        errorMessage.current = `Max file size allowed is 25 MB. File Size: ${fileSizeInMB.toFixed(
          2
        )} MB`;
        setDisplayErrorMessage(true);
      }
      // check if file is in an accepted format
      else if (!acceptedAudioFormats.includes(fileType)) {
        errorMessage.current = `Allowed file types: ${acceptedAudioFormats.join(
          ", "
        )}. \n File type: ${fileType} `;
        setDisplayErrorMessage(true);
      }
      // file is valid
      else {
        setIsValidFile(true);
      }
    }
  };

  // Calls the backend server to transcribe audio file to text
  const transcribeAudio = () => {
    if (audioFile === null) {
      return;
    }
    setIsUploadingAudio(true);
    const formData = new FormData();
    formData.append("audio", audioFile);
    formData.append("userId", userId);
    formData.append("transcriptId", transcriptId);

    fetch(
      // `http://10.29.132.22:8080/transcribe_audio`,
      // `http://localhost:5000/transcribe_audio`,
      `https://ossy-backend-5sljdp5hja-uk.a.run.app/transcribe_audio`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then(async (response) => {
        setIsUploadingAudio(false);
        if (response.ok) {
          const transcriptUrl = await response.text();
          console.log(
            "Created uploaded transcript in supabase, transcript url: ",
            transcriptUrl
          );
          dispatch(goToTranscript({ transcriptUrl }));
        } else {
          // console.log(
          //   `Error transcribing the audio file: ${response.statusText}`
          // );
          setDisplayErrorMessage(true);
          errorMessage.current = "Could not upload audio";
          throw new Error(
            `Error transcribing the audio file: ${response.statusText}`
          );
        }
      })
      .catch((error) => {
        setIsUploadingAudio(false);
        setDisplayErrorMessage(true);
        errorMessage.current = "Could not upload audio";
        console.error(error.message);
      });
  };

  return (
    <>
      {/* dark overlay */}
      <DarkOverlay zIndex={1} />
      <div
        style={{
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: borderRadius,
          boxShadow: boxShadow,
          color: "black",
          display: "flex",
          flexDirection: "column",
          left: "50%",
          maxHeight: 400,
          maxWidth: "75dvw",
          marginBottom: 6,
          position: "fixed",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          zIndex: 2,
          padding: 20,
        }}
      >
        {/* Close button */}
        <div
          style={{
            position: "fixed",
            // height: 10,
            // width: 10,
            top: 20,
            left: 20,
            cursor: "pointer",
          }}
          onClick={() => setDisplayUploadAudioPanel(false)}
        >
          <FiXCircle color="gray" style={{ height: 20, width: 20 }} />
        </div>
        {/* Upload audio title */}
        <div
          style={{
            textAlign: "left",
            fontSize: 20,
            // margin: 20,
            fontWeight: 500,
            marginBottom: 32,
          }}
        >
          Upload Audio
        </div>
        {/* Choose audio file selector */}
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <input
            style={{ marginBottom: 20, fontSize: 16 }}
            type="file"
            name="audio"
            // accept="audio/"
            accept={acceptedAudioFormats
              .map((format) => "audio/" + format)
              .join(", ")}
            id="audio_input"
            onChange={(event) => {
              // reset error message
              setDisplayErrorMessage(false);
              setIsValidFile(false);

              // determine if audio file meets size conditions
              setAudioFile(event.target.files[0]);
              checkIfValidFile(event.target.files[0]);
              console.log(`audio file: ${event.target.files[0]}`);
            }}
          />
          {audioFile && (
            <>
              <p>{audioFile.name}</p>
              <p>{(audioFile.size / 1024 ** 2).toFixed(2)} mb</p>
              <p>{audioFile.type}</p>
            </>
          )}
          {displayErrorMessage && (
            <h4 style={{ whiteSpace: "pre-line" }}>{errorMessage.current}</h4>
          )}
        </div>
        {/* Upload audio button */}
        <Button
          style={{
            padding: 6,
            marginTop: 16,
            fontSize: 16,
            borderRadius: 6,
            display: "flex",
            color: "white",
            flexDirection: "row",
            backgroundColor: "dodgerblue",
            alignSelf: "flex-end",
            opacity: isValidFile ? 1 : 0.5,
            cursor: isValidFile ? "pointer" : "default",
          }}
          content={"Upload Audio"}
          loading={isUploadingAudio}
          onClick={() => {
            console.log("clicked");
            transcribeAudio();
          }}
          isClickable={isValidFile}
        ></Button>
      </div>
    </>
  );
};

export default UploadAudioPanel;
