import { useSelector } from "react-redux";
import { borderRadius, colors } from "../utility/utility";
import SelectFolder from "./SelectFolder";
import { FiXCircle } from "react-icons/fi";

const SelectFolderPanel = ({
  setSelectFolderPanel,
  setSelected,
  rightClickTranscriptIndex,
  setFolderSelected,
  supabase,
}) => {
  const folders = useSelector((state) => state.routes.folders);
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          opacity: 0.5,
          backgroundColor: "black",
        }}
        onClick={() => setSelectFolderPanel(false)}
      />
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          backgroundColor: darkMode ? colors.gray1 : "white",
          top: "50%",
          left: "50%",
          borderRadius: borderRadius,
          padding: 16,
          maxWidth: "50%",
          maxHeight: "80%",
          textAlign: "center",
          overflow: "clip",
          display: "flex",
          flexDirection: "column",
          overflowY: "clip",
        }}
      >
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <FiXCircle
            style={{ cursor: "pointer", minWidth: 24, maxHeight: 24 }}
            onClick={() => {
              setSelectFolderPanel(false);
            }}
          />
          <p style={{ fontWeight: 500, width: 500 }}>Select A Folder</p>
        </div>
        <div
          style={{
            // position: "auto",
            alignItems: "flex-start",
            flexDirection: "row",
            maxHeight: "100%",
            overflowY: "auto",
            scrollbarWidth: "thin",
            width: "100%",

            display: "grid",
            justifyContent: "space-between",
            gridTemplateColumns: "repeat(auto-fill, 100px)",
            rowGap: "10px",
            columnGap: "10px",
          }}
        >
          {folders.map((folderName, index) => (
            <SelectFolder
              rightClickTranscriptIndex={rightClickTranscriptIndex}
              folderName={folderName}
              setSelected={setSelected}
              setFolderSelected={setFolderSelected}
              setSelectFolderPanel={setSelectFolderPanel}
              supabase={supabase}
              index={index}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectFolderPanel;
