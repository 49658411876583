import { useState, useRef, useEffect } from "react";
import { colors, boxShadow } from "../utility/utility";
import DarkOverlay from "./DarkOverlay";
import { useSelector } from "react-redux";

const DropDown = ({ links, func, top, left, right, bottom, overlay }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  const panelRef = useRef(null);
  const [dropDownHover, setDropDownHover] = useState(false);
  const [points, setPoints] = useState({ x: 0, y: 0 });

  const useDetectClickOutside = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          func();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useDetectClickOutside(panelRef);

  const position = {};
  if (right !== undefined) {
    position.right = right;
  }
  if (left !== undefined) {
    position.left = left;
  }
  if (top !== undefined) {
    position.top = top;
  }
  if (bottom !== undefined) {
    position.bottom = bottom;
  }

  return (
    <>
      {/* make background unclickable */}
      {overlay && <DarkOverlay opacity={0} />}

      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          position: "absolute",
          boxShadow: boxShadow,
          borderRadius: 8,
          mihWidth: "fit-content",
          zIndex: 2,
          ...position,
        }}
        ref={panelRef}
        onContextMenu={(event) => {
          event.preventDefault();
        }}
      >
        {links.map(
          (
            { name, href, downloadName, onClick, disabled, dropdown, newTab },
            index
          ) => {
            return (
              <div style={{}} key={name}>
                <div
                  style={{
                    borderRadius: 8,
                    opacity: disabled ? 0.5 : 1,
                    borderTopRightRadius: index === 0 ? 8 : 0,
                    borderTopLeftRadius: index === 0 ? 8 : 0,
                    borderBottomRightRadius: index === links.length - 1 ? 8 : 0,
                    borderBottomLeftRadius: index === links.length - 1 ? 8 : 0,
                    cursor: disabled ? "default" : "pointer",
                    width: "100%",
                  }}
                  onClick={
                    disabled
                      ? (event) => {}
                      : (event) => {
                          if (onClick) {
                            onClick();
                          }
                          func();
                        }
                  }
                  onContextMenu={() => {}}
                  onMouseEnter={(event) => {
                    if (disabled) {
                      return;
                    }
                    event.currentTarget.style.backgroundColor = darkMode
                      ? colors.gray15
                      : colors.lightGray;
                    if (dropdown) {
                      setDropDownHover(true);
                      setPoints({ x: event.pageX, y: event.pageY });
                    }
                  }} // saveAudio &&
                  onMouseLeave={(event) => {
                    event.currentTarget.style.backgroundColor = "transparent";
                    if (dropdown) {
                      setDropDownHover(false);
                    }
                  }}
                >
                  {href ? (
                    <a
                      style={{
                        margin: 0,
                        padding: 0,
                        textDecoration: "none",

                        color: darkMode ? "white" : "gray",
                        borderRadius: 8,
                        borderTopRightRadius: index === 0 ? 8 : 0,
                        borderTopLeftRadius: index === 0 ? 8 : 0,
                        borderBottomRightRadius:
                          index === links.length - 1 ? 8 : 0,
                        borderBottomLeftRadius:
                          index === links.length - 1 ? 8 : 0,
                        display: "flex",
                        pointerEvents: disabled ? "none" : "auto",
                      }}
                      href={href}
                      target={newTab ? "_blank" : "_self"}
                      rel="noreferrer"
                      download={downloadName}
                      onContextMenu={() => {}}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 8,
                          fontSize: 12,
                          borderTopRightRadius: index === 0 ? 8 : 0,
                          borderTopLeftRadius: index === 0 ? 8 : 0,
                          borderBottomRightRadius:
                            index === links.length - 1 ? 8 : 0,
                          borderBottomLeftRadius:
                            index === links.length - 1 ? 8 : 0,
                          opacity: 1,
                          whiteSpace: "nowrap",
                          color: darkMode ? "white" : "gray",
                        }}
                      >
                        {name}
                      </p>

                      {dropdown && (
                        <div
                          style={{
                            color: darkMode ? "white" : "gray",
                          }}
                        ></div>
                      )}
                    </a>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 8,
                          fontSize: 12,
                          color: darkMode ? "white" : "gray",
                          minWidth: "fit-content",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {name}
                      </p>

                      {dropdown !== undefined && (
                        <div
                          style={{
                            paddingRight: 8,
                            display: "flex",
                            fontSize: 12,
                            color: darkMode ? "white" : "gray",
                            alignItems: "center",
                          }}
                          onMouseEnter={(event) => {}}
                          onMouseLeave={(event) => {}}
                        >
                          {">"}
                        </div>
                      )}
                    </div>
                  )}

                  {dropDownHover && dropdown && (
                    <DropDown
                      top={0}
                      left={0}
                      links={[
                        dropdown.map((dropdownName) => {
                          return { name: dropdownName };
                        }),
                      ]}
                    />
                  )}
                </div>
                {/* separating line between menu elements  */}
                {index !== links.length - 1 && (
                  <hr
                    style={{
                      margin: 0,
                      opacity: 1,
                      border: "0.1px solid lightgray",
                    }}
                  />
                )}
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

export default DropDown;
