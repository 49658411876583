import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../transcriptSlice";
import DropDown from "./DropDown";
import { useState } from "react";
import { colors } from "../utility/utility";

const ProfileButton = ({ removeNameWhenSmall, includeName, left, right }) => {
  const dispatch = useDispatch();
  const pfpUrl = useSelector((state) => state.routes.pfpUrl);
  const fullName = useSelector((state) => state.routes.fullName);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const [profileOptionsVisible, setProfileOptionsVisible] = useState(false);
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia(`(max-width: ${removeNameWhenSmall}px)`).matches
  );
  const darkMode = useSelector((state) => state.routes.darkMode);

  if (removeNameWhenSmall !== undefined) {
    window.addEventListener("resize", (event) => {
      setSmallWindow(
        window.matchMedia(`(max-width: ${removeNameWhenSmall}px)`).matches
      );
    });

    // window.onresize = () => {
    //   setSmallWindow(
    //     window.matchMedia(`(max-width: ${removeNameWhenSmall}px)`).matches
    //   );
    // };
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: 130,
        // width: "100%",
        // width: `calc(100% - 32px)`,
        overflow: "clip",
        justifyContent: "flex-start",
        margin: 0,
        padding: 0,
        // opacity: 0.5,
        // width: 0,
        // backgroundColor: "red",
        // backgroundColor: "blue",
        // width: "0",
      }}
      onClick={() => {
        if (!profileOptionsVisible) {
          setProfileOptionsVisible(true);
        }
      }}
    >
      {profileOptionsVisible && (
        <DropDown
          top={48}
          left={left ? -30 : undefined}
          right={right ? 50 : undefined}
          func={() => setProfileOptionsVisible(false)}
          links={[
            {
              name: "Settings",
              onClick: () => {
                window.location.pathname = "/settings";
              },
            },
            {
              name: "Log Out",
              onClick: () => {
                console.log("clicked");
                setProfileOptionsVisible(false);
                dispatch(logOut({}));
              },
            },
          ]}
        />
      )}
      <img
        alt="pfp"
        src={pfpUrl}
        style={{
          width: 32,
          minWidth: 32,
          height: 32,
          maxWidth: 32,
          maxHeight: 32,
          borderRadius: "100%",
        }}
      />
      {(!removeNameWhenSmall || !smallWindow) && includeName && (
        <p
          style={{
            fontWeight: 300,
            marginLeft: 10,
            fontSize: 12,
            overflow: "clip",
            textOverflow: "ellipsis",
            flexGrow: 1,
            padding: 0,
            // flexGrow: 1,
            // width: "90%",
            // display: "inline-grid",
            // width: `calc(100% - 100px)`,
            // width: "auto",
            whiteSpace: "nowrap",
            color: darkMode ? "white" : colors.black,
          }}
        >
          {isLoggedIn ? fullName : "not logged in"}
        </p>
      )}
    </div>
  );
};

export default ProfileButton;
