import {
  updateGoogleData,
  fetchUserData,
  getGoogleInfo,
  isNewUser,
} from "../../../transcriptSlice";

const decodeJwtResponseFromGoogleAPI = (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const onSignInGSI = async (credentialResponse, dispatch, supabase) => {
  const responsePayload = decodeJwtResponseFromGoogleAPI(
    credentialResponse.credential
  );

  const {
    given_name: firstName,
    family_name: lastName,
    sub: userId,
    email,
    name: fullName,
    picture: pfpUrl,
  } = responsePayload;

  // get pfp, name, userId, & email
  dispatch(
    getGoogleInfo({ pfpUrl, fullName, userId, firstName, lastName, email })
  );

  // determine if user is new
  await dispatch(isNewUser({ supabase })).unwrap();

  // push the google info to database
  await dispatch(updateGoogleData(supabase)).unwrap();

  // fetch all the user's data
  await dispatch(fetchUserData({ supabase })).unwrap();

  localStorage.setItem("login-event", "login" + Math.random());
};
