import "../App.css";
import { useState, useEffect, useRef } from "react";
import {
  FeedbackButton,
  FeedbackForm,
  GPTPanel,
  TranscriptPanel,
  ControlPanel,
  ConfirmDownloadPanel,
  SummaryPanel,
  TranscriptSettingsPanel,
  ProfileButton,
  OssyText,
  LogInPanel,
  OssyLogo,
  AutoPausePanel,
  Header,
} from "../components";
import { normalizeAudio } from "../data/normalizeAudio";
import PrivacyLock from "../features/transcription/components/PrivacyLock";

import { useSelector, useDispatch } from "react-redux";
import {
  resetPrevTranscriptType,
  resetStartIndex,
  handleResumeTranscribing,
  updateTranscript,
  resetNumBlurbsSinceLastSummary,
  setAsFinal,
  editTranscriptTitle,
  saveTranscriptAudio,
  resetUnfinishedText,
  logTranscriptSettings,
  logAutoPause,
  getLatestTranscriptSession,
  createSession,
  updateSessionEndDate,
} from "../transcriptSlice";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { slide as Menu } from "react-burger-menu";
import customVocabs from "../data/custom_vocabs.json";
import { onSignInGSI } from "../features/login/api/loginApi";
import { boxShadow, colors, GOOGLE_CLIENT_ID } from "../utility/utility";

// this needs to be defined outside of the function
// otherwise it keeps reading as empty
const audioBlobs = [];

const TranscriptPage = ({ supabase, ownerOfTranscript, dummy = false }) => {
  const [started, setStarted] = useState(false);
  const [language, setLanguage] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [confirmDownload, setConfirmDownload] = useState(false);
  const [saveAudio, setSaveAudio] = useState(false);
  const [audioFile, setAudioFile] = useState(
    new Blob([], {
      type: "audio/ogg",
    })
  );
  const scrollPosition = useRef(0);
  const [submitFeedbackLoading, setSubmitFeedbackLoading] = useState(false);
  const [autoPausePanel, setAutoPausePanel] = useState(false);

  const dispatch = useDispatch();

  const darkMode = useSelector((state) => state.routes.darkMode);
  const { transcriptTitle, transcriptArray, audioUrl, transcriptUrl } =
    useSelector((state) => state.routes.transcript);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const audioUploading = useSelector((state) => state.routes.audioUploading);
  const audioUploadProgress = useSelector(
    (state) => state.routes.audioUploadProgress
  );

  // Time of last updated transcript
  const timeOfLastUpdateTranscript = useRef(new Date());

  const LIVE_TRANSCRIPT_ATTEMPT = 1000;
  const UPLOAD_TRANSCRIPT_ATTEMPT = 1001;
  const TITLE_TRANSCRIPT_ATTEMPT = 1002;
  const VIEW_TRANSCRIPT_ATTEMPT = 1003;
  const [guestUserAction, setGuestUserAction] = useState(
    LIVE_TRANSCRIPT_ATTEMPT
  );

  const [downloadOptionsVisible, setDownloadOptionsVisible] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const [logInPanel, setLogInPanel] = useState(false);

  // AI helper
  const [GPTResponse, setGPTResponse] = useState("");
  const [loading, setLoading] = useState(false);

  // feedback form
  const [feedbackForm, setFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");

  const [transcriptionSettings, setTranscriptionSettings] = useState(false);

  const [autoscroll, setAutoscroll] = useState(started);
  const [paused, setPaused] = useState("");

  // resizing the window
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  const [gptPanelCutoff, setGptPanelCutoff] = useState(
    window.matchMedia("(max-width: 570px)").matches
  );

  // if the screen is really small, don't start transcription with panel open
  const [GPT, setGPT] = useState(!gptPanelCutoff);
  const [menuWindowSize, setMenuWindowSize] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );

  useEffect(() => {
    if (!isLoggedIn && !ownerOfTranscript) {
      setGuestUserAction(VIEW_TRANSCRIPT_ATTEMPT);
      setLogInPanel(true);
    }
  }, [isLoggedIn, ownerOfTranscript]);

  window.onresize = () => {
    setSmallWindow(window.matchMedia("(max-width: 768px)").matches);
    setMenuWindowSize(window.matchMedia("(max-width: 1000px)").matches);
    setGptPanelCutoff(window.matchMedia("(max-width: 650px)").matches);
  };

  const mediaRecorder = useRef(null);
  const websocket = useRef(null);
  const mediaStream = useRef(null);

  const [customVocab, setCustomVocab] = useState(false);
  const [filterProfanity, setFilterProfanity] = useState(false);
  const [filterDisfluencies, setFilterDisfluencies] = useState(false);

  const transcriptIndexToSummaryIndex = {};

  let prevWordsLength = 0;
  let counter = 1;
  let summaryIndex_ = 0;

  // Controls when to stop screen from sleeping/dimming
  const wakeLock = useRef(null);

  /*
    Assign each text blurb from the transcription to a corresponding summary blurb.
    Each summary blurb corresponds to max(50 words, len(one text blurb))
  */
  transcriptArray.forEach((text, index) => {
    // assign the transcript index a summary index
    transcriptIndexToSummaryIndex[index] = summaryIndex_;
    const words = text.split(" ");
    const newTextLength = prevWordsLength + words.length;
    // increase the summary index if we just passed another 50 words
    if (newTextLength > 50 * counter) {
      summaryIndex_ += 1;
    }
    // if one blurb is somehow longer than 50 words, increase the counter
    counter += Math.floor((newTextLength - 50 * counter) / 50) + 1;
    // update length of previous words
    prevWordsLength += words.length;
  });

  const trptRef = useRef(null);

  const headerHeight = 80;

  // the bottom should have more height when it's also displaying the audio seekbar
  const bottomHeight =
    !started && transcriptArray.length !== 0 && audioUrl !== "" ? 150 : 100;

  // convert all the latex on initial page load,
  // when exiting edit mode, and when more audio is transcribed
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  }, [editMode, transcriptArray]);

  // Prevent screen from sleeping/dimming when you start recording
  useEffect(() => {
    if (started && !paused) {
      navigator.wakeLock.request("screen").then((lock) => {
        wakeLock.current = lock;
      });
    }

    // Release wake lock
    return () => {
      if (wakeLock.current) {
        wakeLock.current.release().then(() => {
          wakeLock.current = null;
        });
      }
    };
  }, [paused, started]);

  // Create or update transcript session data
  useEffect(() => {
    if (isLoggedIn && transcriptUrl !== null) {
      // every 30 seconds, log the end of the session
      const logSessionInterval = setInterval(() => {
        dispatch(updateSessionEndDate({ supabase }));
      }, 5000);

      return () => clearInterval(logSessionInterval);
    }
  }, [dispatch, isLoggedIn, supabase, transcriptUrl]);

  // warn user if they try to reload the page while transcribing
  useEffect(() => {
    const preventUnload = (e) => {
      if (started) {
        e.preventDefault();
        e.returnValue =
          "Are you sure? Reloading this page will end your transcription.";
      }
    };

    if (started) {
      window.addEventListener("beforeunload", preventUnload);
    } else {
      window.removeEventListener("beforeunload", preventUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", preventUnload);
    };
  }, [started]);

  const connectToRevAPI = async (mediaStream_) => {
    const access_token = process.env.REACT_APP_REV_API_ACCESS_TOKEN;
    const websocketURL = `wss://api.rev.ai/speechtotext`;

    // streaming audio to Rev.AI API
    let websocketURI = `${websocketURL}/v1/stream?access_token=${access_token}&content_type=audio/flac`;

    // I only have custom vocabs for english + computer science right now
    if (
      customVocab &&
      ["en", false].includes(language) &&
      customVocab === "Computer Science"
    ) {
      websocketURI += `&custom_vocabulary_id=${customVocabs[customVocab]}`;
    }

    if (filterProfanity) {
      websocketURI += "&filter_profanity=true";
    }

    if (filterDisfluencies) {
      websocketURI += "&remove_disfluencies=true";
    }

    if (language) {
      websocketURI += `&language=${language}`;
    }
    // console.log("websocketURI: " + websocketURI);

    // connect to Rev.AI API for transcription
    websocket.current = new WebSocket(websocketURI);

    // when we first connect to Rev API...
    websocket.current.onopen = async (event) => {
      dispatch(resetPrevTranscriptType({}));

      timeOfLastUpdateTranscript.current = new Date();

      // use MediaStream Recording API to get audio data
      mediaRecorder.current = new MediaRecorder(mediaStream_, {
        audioBitsPerSecond: 128000,
      });

      // storing the audio that we record
      // const audio = [];

      // received some audio from microphone
      mediaRecorder.current.ondataavailable = (event) => {
        // get the audio
        let blob = event.data;
        blob = normalizeAudio(event.data);

        if (saveAudio) {
          audioBlobs.push(blob);
        }
        setAudioFile(
          new Blob(audioBlobs, {
            type: "audio/ogg",
          })
        );

        if (
          websocket.current &&
          websocket.current.readyState === websocket.current.OPEN
        ) {
          websocket.current.send(new Blob([blob], { type: "audio/flac" }));
        }
      };

      mediaRecorder.current.onstop = (event) => {};

      mediaRecorder.current.onstart = () => {
        setStarted(true);
        setAutoscroll(true);
        setPaused(false);
        setLoading(false);
      };

      // make data available event fire every 200 ms
      mediaRecorder.current.start(200);
    };

    // when we close the connection to Rev.AI API...
    websocket.current.onclose = (event) => {
      // there's some sort of problem
      if (event.code === 4002) {
        console.log("websocket closing with error");
        // pause transcribing & recording
        setPaused(true);

        // disconnecting the websocket since I get charged for it
        if (mediaRecorder.current) {
          mediaRecorder.current.stop();
        }
        // if (mediaStream.current) {
        mediaStream_.current.getTracks().forEach((mediaTrack) => {
          mediaTrack.stop();
        });
        // }
        mediaRecorder.current = null;
        websocket.current = null;
        mediaStream.current = null;

        // reset transcription settings
        setCustomVocab(false);
        setFilterDisfluencies(false);
        setFilterProfanity(false);

        // setStarted(false);
        // console.log("set started to false");
        // dispatch(resetStartIndex({}));
        // setGPT(false);
        // setGPTResponse("");
      }
    };

    // when we receive a message from the Rev.AI API...
    websocket.current.onmessage = async (event) => {
      const results = JSON.parse(event.data);
      // update the state with the new data
      if (["partial", "final"].includes(results.type)) {
        timeOfLastUpdateTranscript.current = new Date();
        await dispatch(updateTranscript({ supabase, results })).unwrap();
      }
    };

    // when we receive an error from the Rev.AI API...
    websocket.current.onerror = (event) => {
      console.log(
        "got an error: " + JSON.stringify(event) + "; event: " + event
      );

      // alert("Something happened, please try again.");
    };
  };

  const errorAccessingMicrophone = (error) => {
    setStarted(false);
    if (error.name === "NotAllowedError") {
      alert(
        "In order for Ossy to transcribe audio, you have to grant microphone access."
      );
    } else {
      alert("Error accessing microphone, please try again.");
    }
    setLoading(false);
  };

  const startTranscribing = async () => {
    // we need to store some information otherwise
    // if things go wrong it's excruciatingly hard to debug
    dispatch(
      logTranscriptSettings({
        customVocab: customVocab,
        filterProfanity: filterProfanity,
        filterDisfluencies: filterDisfluencies,
        saveAudio: saveAudio,
        supabase: supabase,
      })
    );

    // show loading until we've connected to the transcription API
    // and obtained browser permission to start recording audio
    setLoading(true);

    // reset the marker for keeping track of how many blurbs
    // have appeared since last request for a live summary
    dispatch(resetNumBlurbsSinceLastSummary({}));

    // reset marker for keeping track of transcript results that
    // didn't end with proper punctuation
    dispatch(resetUnfinishedText({}));

    // request permission to access microphone
    try {
      mediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      connectToRevAPI(mediaStream.current);
    } catch (error) {
      errorAccessingMicrophone(error);
    }
  };

  const resumeTranscribing = () => {
    // we need to update some state when we start transcribing
    dispatch(handleResumeTranscribing({}));

    // Set time of last update transcript to current time so auto pause feature resets
    timeOfLastUpdateTranscript.current = new Date();

    // show loading until we've connected to the transcription API
    // and obtained browser permission to start recording audio
    setLoading(true);
    setPaused(false);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(connectToRevAPI, errorAccessingMicrophone);
    setLoading(false);
  };

  const pauseTranscribing = () => {
    // pause transcribing & recording
    setPaused(true);

    // disconnecting the websocket since I get charged for it
    if (websocket.current !== null) {
      websocket.current.close();
    }
    if (mediaRecorder.current !== null) {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current !== null) {
      mediaStream.current.getTracks().forEach((mediaTrack) => {
        mediaTrack.stop();
      });
    }

    mediaRecorder.current = null;
    websocket.current = null;
    mediaStream.current = null;
  };

  // Pause transciption when the transcript has not updated in a while, meaning no one is talking
  useEffect(() => {
    console.log("entered the auto-pause useEffect");
    let intervalId;
    // Start interval if recorder started and not paused
    if (started && !paused) {
      // Difference in time (seconds) between last update transcript before pausing transcript
      const TIME_DIFFERENCE_CUTTOFF = 60;

      // Interval for verifying whether to pause the transcript
      const AUTO_PAUSE_CHECK_INTERVAL_SECS = 5;
      const PAUSE_TRANSCRIPT_INTERVAL_IN_MILLISECONDS =
        AUTO_PAUSE_CHECK_INTERVAL_SECS * 1000;

      // At each interval, assess the timestamp of the last transcription update;
      // if it occurred too far in the past, pause the transcription.
      intervalId = setInterval(() => {
        console.log("is this getting called?");
        const currentTime = new Date();
        const timeDifferenceInSeconds =
          (currentTime.getTime() -
            timeOfLastUpdateTranscript.current.getTime()) /
          1000;
        if (timeDifferenceInSeconds >= TIME_DIFFERENCE_CUTTOFF) {
          pauseTranscribing();
          setAutoPausePanel(true);
          console.log("just paused it");
          dispatch(logAutoPause({ supabase }));
        }
      }, PAUSE_TRANSCRIPT_INTERVAL_IN_MILLISECONDS);
    }
    // Stop interval if paused
    if (paused || !started) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [started, paused, dispatch, supabase]);

  const stopTranscribing = () => {
    dispatch(setAsFinal({}));

    // pause transcribing & recording
    setPaused(true);

    // disconnecting the websocket since I get charged for it
    if (websocket.current) {
      websocket.current.close();
    }

    // we need to set started to false so it knows to show the audio player
    setStarted(false);

    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((mediaTrack) => {
        mediaTrack.stop();
      });
    }

    mediaRecorder.current = null;
    websocket.current = null;
    mediaStream.current = null;

    // reset transcription settings
    setCustomVocab(false);
    setFilterDisfluencies(false);
    setFilterProfanity(false);

    if (!isLoggedIn) {
      // reminding the user to download their transcript
      setConfirmDownload(true);
    } else {
      // only save the audio if the user opted in to save the audio
      if (saveAudio) {
        dispatch(
          saveTranscriptAudio({ supabase: supabase, audioBlobs: audioBlobs })
        );
        setSaveAudio(false);
      }
    }
  };

  useEffect(() => {
    document.title = transcriptTitle;
  }, [transcriptTitle]);

  // keyboard shortcuts for ossy
  useEffect(() => {
    document.onkeyup = (event) => {
      // ignore key presses if user is editing a transcription blurb
      if (
        feedbackForm ||
        document.activeElement.classList.contains("transcript_array_box") ||
        document.activeElement.id === "title_box"
      ) {
        return;
      }

      // ignore key presses if user is typing feedback
      if (feedbackForm) {
        return;
      }

      // ignore key presses if user is typing in any textbox (title, edit blurb, etc)
      if (["input", "textarea"].includes(document.activeElement.tagName)) {
        return;
      }

      // toggle pause/play audio on spacebar press
      if (!started && [" "].includes(event.key)) {
        const audio = document.getElementById("audio");
        if (!audio) {
          return;
        }

        if (audio.paused) {
          audio.play();
        } else {
          audio.pause();
        }
      }

      // exit edit mode when enter or escape is pressed
      if (["Enter", "Escape"].includes(event.key) && editMode) {
        console.log("exiting edit mode");
        setEditMode(false);
      }

      // ctrl + e should enter/edit edit mode
      if (["E", "e"].includes(event.key) && event.ctrlKey) {
        setEditMode(!editMode);
      }

      // ctrl + a should enter/edit edit mode
      if (["A", "a"].includes(event.key) && event.ctrlKey) {
        setGPT(!GPT);
      }

      // ctrl + s should enter/edit edit mode
      if (["S", "s"].includes(event.key) && event.ctrlKey) {
        setShowSummary(!showSummary);
      }

      // ctrl + d should enter/edit edit mode
      if (["D", "d"].includes(event.key) && event.ctrlKey) {
        setDownloadOptionsVisible(!downloadOptionsVisible);
      }
    };
  }, [
    started,
    editMode,
    GPT,
    showSummary,
    downloadOptionsVisible,
    feedbackForm,
  ]);

  const smallWindowMenu = {
    bmBurgerButton: {
      position: "fixed",
      width: "25px",
      height: "25px",
      right: 16,
      top: "26px",
    },

    // the three horizontal bars
    bmBurgerBars: {
      borderRadius: 10,
      backgroundColor: "gray",
      background: "gray",
    },

    // three horizontal bars on hover
    bmBurgerBarsHover: {
      background: "lightgray",
      display: "none",
      backgroundColor: "lightgray",
      // background: "lightgray",
    },

    bmCrossButton: {
      height: "24px",
      width: "24px",
    },

    // the x to close it
    bmCross: {
      backgroundColor: "white",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },

    // the menu itself
    bmMenu: {
      background: "rgba(0, 0, 0, 0.5)",
      paddingTop: "2em",
      // backgroundColor: "white",
      fontSize: "1.15em",
      flex: 1,
      // left: 0,
      // position: "absolute",
      // position: "absolute",
      // left: 0,
    },

    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      width: "100%",
      margin: 0,
    },

    // container for items in menu
    bmItem: {
      display: "flex",
      padding: 5,
      fontSize: 20,
      width: "100%",
    },

    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      width: "100%",
      height: "100%",
    },
  };
  return (
    <div>
      {/* right menu when screen is small */}
      {!isLoggedIn && menuWindowSize && (
        <Menu styles={smallWindowMenu} width={250} right>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: 0,
            }}
          >
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  onSignInGSI(credentialResponse, dispatch, supabase);

                  // start a session becuase user just logged in on transcript page
                  dispatch(createSession({ supabase }));
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                // useOneTap
                auto_select
                width={200}
                shape="square"
              />
            </GoogleOAuthProvider>
          </div>
          {smallWindow && <FeedbackButton setFeedbackForm={setFeedbackForm} />}
        </Menu>
      )}

      <div
        className="App"
        style={{ backgroundColor: darkMode ? colors.black : "white" }}
      >
        <Header
          left={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: 12,
                fontWeight: 900,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <OssyLogo />
              <OssyText />
            </div>
          }
          center={
            <div
              style={{
                top: 20,
                borderRadius: 6,
                width: "80%",
                maxWidth: 1000,
                margin: "auto",
                display: "flex",
                flexDirection: "row",
                maxHeight: 35,
                minHeight: 35,
              }}
            >
              {ownerOfTranscript && <PrivacyLock supabase={supabase} />}
              <input
                type="text"
                name="name"
                autoComplete="off"
                id="title_box"
                defaultValue={transcriptTitle}
                // you can't rename a transcript you don't own
                disabled={!ownerOfTranscript}
                onKeyDown={(event) => {
                  if (["Enter", "Escape"].includes(event.key)) {
                    event.currentTarget.blur();
                  }
                }}
                onBlur={(e) => {
                  // edit transcript title if logged in
                  if (isLoggedIn) {
                    if (
                      e.currentTarget.value !== "" &&
                      e.currentTarget.value !== transcriptTitle
                    ) {
                      console.log(
                        `pushing new title to database: ${e.currentTarget.value}`
                      );
                      dispatch(
                        editTranscriptTitle({
                          newTitle: e.currentTarget.value,
                          supabase: supabase,
                        })
                      );
                    }
                    // otherwise show log in panel
                  } else if (e.currentTarget.value !== "") {
                    setGuestUserAction(TITLE_TRANSCRIPT_ATTEMPT);
                    e.currentTarget.value = transcriptTitle;
                    setLogInPanel(true);
                  }
                }}
                style={{
                  // border: "none",
                  fontWeight: 700,
                  fontSize: 20,
                  backgroundColor: darkMode ? colors.gray1 : colors.gray,
                  color: darkMode ? "white" : "black",
                  alignSelf: "center",
                  textAlign: "center",
                  // minWidth: 300,

                  // safari gives the input this ugly margin
                  margin: 0,

                  // safari gives the input this ugly weird border
                  border: 0,
                  justifySelf: "center",
                  overflowX: "hidden",
                  flexGrow: 1,
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                  borderRadius: ownerOfTranscript ? "undefined" : 6,
                  maxHeight: 35,
                  minHeight: 35,
                  paddingLeft: 8,
                  paddingRight: 8,
                  // flex: 0.5,
                }}
              />
            </div>
          }
          right={
            <div
              style={{
                color: "black",
                position: "absolute",
                // alignSelf: "center",
                alignItems: "center",
                right: 0,
                marginRight: 16,

                fontSize: 12,
                display: "flex",
                cursor: "pointer",
              }}
            >
              {/* google sign in */}
              {!menuWindowSize && !isLoggedIn && (
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      onSignInGSI(credentialResponse, dispatch, supabase);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    // useOneTap
                    auto_select
                    width={200}
                    shape="square"
                  />
                </GoogleOAuthProvider>
              )}

              {/* profile options in top right */}
              {isLoggedIn && <ProfileButton removeNameWhenSmall={768} left />}
            </div>
          }
          height={headerHeight}
        />
        {/* transcription text & GPT Panel */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            flexGrow: 1,
            maxHeight: `calc(100dvh - ${headerHeight + bottomHeight}px)`,
            width: "90%",
            maxWidth: 1500,
            justifyContent: "center",
            //backgroundColor: "yellowgreen",
          }}
          className="transcription_panel_body"
        >
          <TranscriptPanel
            started={started}
            setEditMode={setEditMode}
            editMode={editMode}
            GPT={GPT}
            trptRef={trptRef}
            autoscroll={autoscroll}
            scrollPosition={scrollPosition}
            setAutoscroll={setAutoscroll}
            supabase={supabase}
            gptPanelCutoff={gptPanelCutoff}
          />
          <GPTPanel
            GPTResponse={GPTResponse}
            GPT={GPT}
            supabase={supabase}
            started={started}
            gptPanelCutoff={gptPanelCutoff}
            setGPT={setGPT}
          />
        </div>

        {/* control panel */}
        <div style={{ minHeight: bottomHeight }}>
          <ControlPanel
            started={started}
            setTranscriptionSettings={setTranscriptionSettings}
            loading={loading}
            resumeTranscribing={resumeTranscribing}
            paused={paused}
            pauseTranscribing={pauseTranscribing}
            GPT={GPT}
            setGPT={setGPT}
            editMode={editMode}
            setEditMode={setEditMode}
            stopTranscribing={stopTranscribing}
            setShowSummary={setShowSummary}
            scrollPosition={scrollPosition}
            downloadOptionsVisible={downloadOptionsVisible}
            setDownloadOptionsVisible={setDownloadOptionsVisible}
            ownerOfTranscript={ownerOfTranscript}
            supabase={supabase}
          />
        </div>

        {/* summary panel for lectures */}
        {showSummary && (
          <SummaryPanel setShowSummary={setShowSummary} supabase={supabase} />
        )}
        {/* confirm download panel on ending transcription */}
        {confirmDownload && (
          <ConfirmDownloadPanel
            setConfirmDownload={setConfirmDownload}
            setStarted={setStarted}
            dispatch={dispatch}
            resetStartIndex={resetStartIndex}
            transcriptArray={transcriptArray}
            transcriptTitle={transcriptTitle}
            audioFile={audioFile}
            setGPT={setGPT}
            setGPTResponse={setGPTResponse}
            setAudioFile={setAudioFile}
          />
        )}
        {/* feedback button */}
        {!smallWindow && <FeedbackButton setFeedbackForm={setFeedbackForm} />}
        {/* feedback form */}
        {feedbackForm && (
          <FeedbackForm
            setFeedbackForm={setFeedbackForm}
            email={email}
            feedback={feedback}
            setEmail={setEmail}
            setFeedback={setFeedback}
            supabase={supabase}
            setSubmitFeedbackLoading={setSubmitFeedbackLoading}
            submitFeedbackLoading={submitFeedbackLoading}
          />
        )}
        {/* settings panel */}
        {transcriptionSettings && (
          <TranscriptSettingsPanel
            setSaveAudio={setSaveAudio}
            saveAudio={saveAudio}
            customVocab={customVocab}
            filterProfanity={filterProfanity}
            filterDisfluencies={filterDisfluencies}
            startTranscribing={startTranscribing}
            setCustomVocab={setCustomVocab}
            setLanguage={setLanguage}
            setFilterProfanity={setFilterProfanity}
            setFilterDisfluencies={setFilterDisfluencies}
            setTranscriptionSettings={setTranscriptionSettings}
            setLogInPanel={setLogInPanel}
            setGuestUserAction={setGuestUserAction}
            LIVE_TRANSCRIPT_ATTEMPT={LIVE_TRANSCRIPT_ATTEMPT}
            UPLOAD_TRANSCRIPT_ATTEMPT={UPLOAD_TRANSCRIPT_ATTEMPT}
            setLoading={setLoading}
            supabase={supabase}
          />
        )}
        {autoPausePanel && (
          <AutoPausePanel setAutoPausePanel={setAutoPausePanel} />
        )}
        {logInPanel && (
          <LogInPanel
            setLogInPanel={setLogInPanel}
            setLoading={setLoading}
            supabase={supabase}
            guestUserAction={guestUserAction}
            LIVE_TRANSCRIPT_ATTEMPT={LIVE_TRANSCRIPT_ATTEMPT}
            UPLOAD_TRANSCRIPT_ATTEMPT={UPLOAD_TRANSCRIPT_ATTEMPT}
            TITLE_TRANSCRIPT_ATTEMPT={TITLE_TRANSCRIPT_ATTEMPT}
            VIEW_TRANSCRIPT_ATTEMPT={VIEW_TRANSCRIPT_ATTEMPT}
            startTranscribing={startTranscribing}
          />
        )}
        {audioUploading && (
          <>
            {/* dark overlay */}
            <div
              style={{
                flex: 1,
                position: "fixed",
                backgroundColor: "black",
                opacity: 0.5,
                width: "100%",
                height: "100%",
              }}
            ></div>

            <div
              style={{
                color: "black",
                borderRadius: 6,
                width: 350,
                maxWidth: "75dvw",
                marginBottom: 6,
                display: "flex",
                alignItems: "center",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                boxShadow: boxShadow,
                padding: 20,
                flexDirection: "column",
              }}
            >
              <div style={{ textAlign: "left", fontSize: 12, marginBottom: 8 }}>
                Loading your audio... (Don't close this tab)
              </div>
              <div
                style={{
                  height: 20,
                  marginLeft: 10,
                  width: "100%",
                  backgroundColor: colors.gray,
                }}
              >
                <div
                  style={{
                    backgroundColor: colors.green,
                    height: 20,
                    width: `${audioUploadProgress}%`,
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TranscriptPage;
