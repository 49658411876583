import { useSelector, useDispatch } from "react-redux";

export const GOOGLE_CLIENT_ID =
  "1020047349054-oa3b87eo87rf6qhu392o2oc6afqmk4cb.apps.googleusercontent.com";

export const colors = {
  gray: "#e8e8e8",
  grayNumber: (opacity) => {
    return `rgba(232, 232, 232, ${opacity})`;
  },
  gray1Number: (opacity) => {
    return `rgba(61, 61, 61, ${opacity})`;
  },
  green: "mediumseagreen",
  ossyBlue1: "dodgerblue",
  darkBlue: "#0d6eff",
  lightGray: "#f5f5f5",
  ossyBlue2: "rgba(43, 156, 255, 0.5)", // ossy blue but translucent
  ossyBlue3: "rgba(43, 156, 255, 0.3)", // ossy blue but translucent
  gray1: "#3d3d3d",
  gray15: "#333333",
  gray2: "#2b2b2b",
  gray3: "#1f1f1f",
  gray4: "#1b1c1e",
  black: "#202020", // our eyes don't really like pure black
  gold: "#d6b600",
};

export const fontSizes = {
  regular: 16,
  big: 18,
};

export const formatDate = (dateString) => {
  if ([undefined, ""].includes(dateString)) {
    return "";
  }
  const months = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const [year, monthNum, date] = dateString.split("-");

  return `${months[monthNum]} ${date}, ${year}`;
};

export const boxShadow = "2px 2px 10px rgba(0, 0, 0, 0.4)";
export const boxShadowHalf = "2px 2px 10px rgba(0, 0, 0, 0.2)";
export const borderRadius = "8px";

export const emToPixels = 18;
export const lorenIpsum =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eget duis at tellus at urna condimentum mattis pellentesque id. Ultricies integer quis auctor elit sed vulputate mi sit. Mauris nunc congue nisi vitae suscipit. Non nisi est sit amet facilisis magna etiam. Condimentum vitae sapien pellentesque habitant morbi tristique senectus et. Id porta nibh venenatis cras sed felis eget. Pellentesque pulvinar pellentesque habitant morbi tristique. Proin fermentum leo vel orci porta non pulvinar neque. Egestas quis ipsum suspendisse ultrices gravida dictum fusce ut. Nunc faucibus a pellentesque sit amet porttitor. Dictum sit amet justo donec enim diam vulputate ut. Enim blandit volutpat maecenas volutpat blandit aliquam. Netus et malesuada fames ac. Molestie at elementum eu facilisis sed.";

export const WORDS_TO_TOKENS = 1500 / 2048;

export const getDate = () => {
  const date = new Date();
  const formattedDate =
    date.getFullYear() +
    "-" +
    // the month is offset by 1
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getDate()).padStart(2, "0");
  return formattedDate;
};

export const formatTimestamp = (timestamp) => {
  if (timestamp === undefined) {
    return "00:00";
  }
  const timestampArray = `${timestamp}`.split(".");
  const secondsFraction = `${Number(timestampArray[1])}`;
  const hours = `${Math.floor(Number(timestampArray[0]) / 3600)}`;
  const minutes = `${Math.floor((Number(timestampArray[0]) % 3600) / 60)}`;
  const seconds = `${Number(timestampArray[0]) % 60}`;

  let formattedTimestamp = "";
  if (hours !== "0") {
    formattedTimestamp += `${hours.padStart(2, "0")}:`;
  }
  formattedTimestamp += `${minutes.padStart(2, "0")}:${seconds.padStart(
    2,
    "0"
  )}`;
  return formattedTimestamp;
};
