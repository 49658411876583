import { colors } from "../utility/utility";
import { useSelector } from "react-redux";

const OssyText = () => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const textColor = darkMode ? "white" : colors.ossyBlue1;
  const textHoverColor = "lightgray";

  return (
    <a
      style={{
        textDecoration: "none",
        color: "black",
        cursor: "pointer",
        // pointerEvents: isLoggedIn ? "auto" : "none",
      }}
      href="/home"
    >
      <div>
        <p
          style={{
            margin: 0,
            padding: 0,
            fontSize: 16,
            fontWeight: 600,
            color: textColor,
          }}
          onMouseEnter={(event) => {
            event.currentTarget.style.color = textHoverColor;
          }}
          onMouseLeave={(event) => {
            event.currentTarget.style.color = textColor;
          }}
        >
          ossy
        </p>
      </div>
    </a>
  );
};

export default OssyText;
