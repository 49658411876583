import DropDown from "./DropDown";
import { useSelector, useDispatch } from "react-redux";
import { deleteFolder } from "../transcriptSlice";

const RightClickFolderPanel = ({
  setRightClickedFolder,
  points,
  setFolderTitleEditIndex,
  rightClickFolderIndex,
  setDeleteFolderWarningPanel,
  setRightClickedTranscription,
  supabase,
  selected,
  setSelected,
  folderSelected,
  setFolderSelected,
}) => {
  const transcripts = useSelector((state) => state.routes.transcripts);
  const folders = useSelector((state) => state.routes.folders);
  const dispatch = useDispatch();

  return (
    <DropDown
      overlay={true}
      top={points.y}
      left={points.x}
      func={() => setRightClickedFolder(false)}
      links={[
        {
          name: "Rename Folder",
          onClick: () => {
            // setFolderTitleEditIndex(rightClickFolderIndex);
            setFolderTitleEditIndex(rightClickFolderIndex);
            console.log(
              `setting folder title edit index: ${rightClickFolderIndex} `
            );
          },
        },
        {
          name: "Delete Folder",
          onClick: () => {
            if (
              transcripts.filter(
                (transcript) =>
                  transcript.transcriptFolder === folders[rightClickFolderIndex]
              ).length > 0
            ) {
              setDeleteFolderWarningPanel(true);
            } else {
              dispatch(
                deleteFolder({
                  supabase: supabase,
                  indexToRemove: rightClickFolderIndex,
                })
              );
              if (
                folderSelected &&
                selected === folders[rightClickFolderIndex]
              ) {
                setSelected("All Transcriptions");
                setFolderSelected(false);
              }
            }
            setRightClickedTranscription(false);
          },
        },
      ]}
    />
  );
};

export default RightClickFolderPanel;
