import { useDispatch, useSelector } from "react-redux";
import { goToTranscript, editTranscriptTitle } from "../transcriptSlice";
import {
  borderRadius,
  boxShadowHalf,
  colors,
  formatDate,
} from "../utility/utility";
import speaker from "../assets/speaker.png";

import React, { useRef } from "react";
import lock_black from "../assets/locked.png";
import unlock_black from "../assets/unlocked.png";
import lock_white from "../assets/locked_white.png";
import unlock_white from "../assets/unlocked_white.png";

const Transcript = ({
  transcriptionTitle,
  index,
  transcriptionTitleEditIndex,
  editingTranscriptTitle,
  setRightClickedTranscription,
  setPoints,
  setRightClickTranscriptIndex,
  setEditingTranscriptTitle,
  setRightClickTranscriptUrl,
  folderSelected,
  supabase,
  transcriptUrl,
  transcriptPrivacy,
  audioUrl,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const lock = darkMode ? lock_white : lock_black;
  const unlock = darkMode ? unlock_white : unlock_black;
  const editingThisTitle = JSON.parse(transcriptionTitleEditIndex) === index;
  const TextType = editingTranscriptTitle && editingThisTitle ? "input" : "p";
  const dispatch = useDispatch();
  const transcripts = useSelector((state) => state.routes.transcripts);
  const dragImageRef = useRef(null);

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text", index);
    // Set the drag image content
    const dragImage = dragImageRef.current;
    dragImage.textContent = transcriptionTitle;
    dragImage.style.display = "block";

    // Adjust styles if needed (e.g., font size, padding)
    dragImage.style.backgroundColor = "grey"; // Set a solid background color
    // dragImage.style.fontSize = "16px";
    dragImage.style.padding = "5px";
    // dragImage.style.borderRadius = 6;

    // Use the custom element as the drag image
    event.dataTransfer.setDragImage(dragImage, 0, 0);

    // Optionally hide the drag image element again after a short delay
    setTimeout(() => {
      dragImage.style.display = "none";
    }, 0);
  };

  return (
    <div
      style={{
        marginTop: 16,
        height: 240,
        width: 200,
        //   width: "100%",
        borderRadius: 6,
        cursor: "pointer",
        float: "left",
        padding: 0,
        display: "flex",
        flexDirection: "column",
        marginRight: 32,
        marginBottom: 32,
        backgroundColor: darkMode ? "#2f2f2f" : "white",
        // backgroundColor: darkMode ? "#161616" : "white",
        boxShadow: darkMode ? "none" : boxShadowHalf,
      }}
      // ref={documentRef}
      draggable="true"
      onDragStart={handleDragStart}
      onDragEnd={(event) => event.currentTarget.classList.remove("highlight")}
      onMouseEnter={(event) => {
        if (darkMode) {
          event.currentTarget.style.backgroundColor = "#454545";
        } else {
          event.currentTarget.style.boxShadow =
            "2px 2px 10px rgba(0, 0, 0, 0.35)";
        }
      }}
      onMouseLeave={(event) => {
        if (darkMode) {
          event.currentTarget.style.backgroundColor = "#2f2f2f";
        } else {
          event.currentTarget.style.boxShadow = darkMode
            ? "none"
            : boxShadowHalf;
        }
      }}
      onClick={() => {
        dispatch(goToTranscript({ transcriptUrl }));
      }}
      id={index}
      onContextMenu={(event) => {
        event.preventDefault();
        setRightClickedTranscription(true);
        setPoints({ x: event.pageX, y: event.pageY });
        setRightClickTranscriptIndex(Number(event.currentTarget.id));
        setRightClickTranscriptUrl(transcriptUrl);
      }}
    >
      <div
        ref={dragImageRef}
        style={{
          display: "none",
          position: "absolute",
          zIndex: "-1",
          borderRadius: borderRadius,
          fontSize: 14,
        }}
      >
        {/* This element will be used as the drag image */}
      </div>
      {/* <div
        style={{
          padding: 6,
          backgroundColor: "gray",
          borderRadius: 8,
          width: 18,
          height: 18,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={speakerWhite}
          alt="speaker"
          style={{ width: 14, height: 14, position: "absolute" }}
        />
      </div> */}
      {/* show some of the text of the transcription */}
      <div
        style={{
          overflow: "hidden",
          padding: 8,
          flex: 4,
        }}
      >
        <p
          style={{
            fontSize: 11,
            margin: 0,
            padding: 0,
            color: darkMode ? "white" : "gray",
            fontWeight: 400,
            lineHeight: 1.5,
          }}
        >
          {(transcripts[index].transcriptArray || []).join(" ")}
        </p>
      </div>

      <hr
        style={{
          margin: 0,
          border: `0.01px solid ${colors.gray}`,
        }}
      />
      <div
        style={{
          flex: 1,
          // width: "100%",
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          height: "100%",
          fontSize: 14,
          padding: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextType
            style={{
              fontWeight: 500,
              // height: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "100%",
              border: "none",
              fontSize: 14,
              fontFamily: "poppins",
              padding: 0,
              margin: 0,
              textAlign: "start",
              display: "flex",
              alignItems: "flex-start",
              lineHeight: "1.5em",
              maxHeight: "5em",
              whiteSpace: "nowrap",
              background: "transparent",
              color: darkMode ? "white" : "black",
            }}
            autoFocus={true}
            defaultValue={transcriptionTitle}
            onKeyDown={(event) => {
              if (["Enter", "Escape"].includes(event.key)) {
                event.currentTarget.blur();
              }
            }}
            onBlur={(event) => {
              setEditingTranscriptTitle(false);
              if (
                event.currentTarget.value !== "" &&
                event.currentTarget.value !== transcriptionTitle
              ) {
                dispatch(
                  editTranscriptTitle({
                    supabase: supabase,
                    newTitle: event.currentTarget.value,
                    index: index,
                  })
                );
              }
            }}
          >
            {TextType === "input" ? null : transcriptionTitle}
          </TextType>

          {/* show the audio label */}
          {audioUrl && (
            <img
              src={speaker}
              alt="transcript has audio"
              style={{ width: 14, height: 14, marginLeft: 8 }}
            />
          )}

          <img
            src={transcriptPrivacy ? lock : unlock}
            alt={
              transcriptPrivacy
                ? "transcript is private"
                : "transcript is public"
            }
            style={{
              height: 14,
              marginLeft: 8,
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontWeight: 400,
            fontSize: 12,
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              fontWeight: 200,
              fontSize: 12,
              whiteSpace: "nowrap",
              marginRight: 10,
            }}
          >
            {/* October 20, 2023 */}
            {formatDate(transcripts[index].creationDate)}
          </p>
          {!folderSelected && (
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {transcripts[index].transcriptFolder}
            </p>
          )}
        </div>
      </div>
    </div>
    // </a>
  );
};
export default Transcript;
