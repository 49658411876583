import { useSelector } from "react-redux";
import { colors } from "../utility/utility";
import OssyHeader from "../components/OssyHeader";
import { Button } from "../components";

const TranscriptIsPrivatePage = () => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        color: darkMode ? "white" : colors.black,
        textAlign: "center",
      }}
    >
      <OssyHeader />
      <p
        style={{
          paddingTop: 64,
          paddingBottom: 64,
          maxWidth: "80%",
          fontWeight: 600,
          fontSize: 24,
        }}
      >
        Sorry, this transcript is private.
      </p>
      <Button
        onClick={() => (window.location.href = "/home")}
        content={"Go To Home"}
      />
    </div>
  );
};

export default TranscriptIsPrivatePage;
