import { emToPixels } from "../../../utility/utility";

/*
we need to dynamically set height of edit boxes:
1. when we first enter edit mode
2. when we are editing some text in edit mode and the number of lines in the active blurb changes
3. when a new blurb is finalized and becomes editable while in edit mode
*/
export const setBlurbHeight = (blurb) => {
  // get the current style on the box
  const currentStyle = blurb.getAttribute("style");

  const padding = parseFloat(blurb.style.padding);
  const lineHeight = blurb.style.lineHeight;

  // reset the height to auto so it will shrink if text was just deleted
  blurb.setAttribute("style", currentStyle + "height: auto;");

  // compute number of lines
  const numLines =
    (blurb.scrollHeight - padding * 2) / (lineHeight * emToPixels);
  const newHeight = numLines * lineHeight * emToPixels;

  const newStyle = `${currentStyle} height: ${newHeight} px; max-height: ${newHeight}px; min-height: ${newHeight}px`;
  blurb.setAttribute("style", newStyle);
};
