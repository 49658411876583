import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./data/initialState";
import { v4 as uuidv4 } from "uuid";
import { getDate } from "./utility/utility";

// updating the user's google data (new users or new email, user_id, or names)
export const updateGoogleData = createAsyncThunk(
  "users/updateGoogleData",
  async (supabase, { getState }) => {
    const { userId, firstName, lastName, email } = getState().routes;
    console.log("updating user info");
    // if the user's personal info changed, update it in the database
    const { data: upsertNewData, error: upsertNewError } = await supabase
      .from("user data")
      .upsert(
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          user_id: userId,
        },
        { onConflict: "user_id", ignoreDuplicates: false }
      )
      .select();

    if (upsertNewError) {
      console.log("upsert error: " + JSON.stringify(upsertNewError));
    }
  }
);

const getDeviceType = () => {
  let mobile = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      mobile = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return mobile ? "mobile device" : "computer/ipad";
};

export const updateEmailPreferences = createAsyncThunk(
  "users/updateEmailPreferences",
  async ({ supabase, newsletters, sales }, { getState, dispatch }) => {
    const emailPreferences = { ...getState().routes.emailPreferences };
    if (newsletters !== undefined) {
      emailPreferences.newsletters = newsletters;
    }
    if (sales !== undefined) {
      emailPreferences.sales = sales;
    }

    // update email preferences locally
    dispatch(
      updateEmailPreferencesLocal({ emailPreferences: emailPreferences })
    );

    // update email preferences backend
    supabase
      .from("user data")
      .update({
        email_preferences: emailPreferences,
      })
      .eq("user_id", getState().routes.userId)
      .then((response) => {
        console.log(`RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const logAutoPause = createAsyncThunk(
  "users/logAutoPause",
  async ({ supabase }, { getState }) => {
    console.log(`logging the autopause`);

    const newDebugJson = { ...getState().routes.transcript.debugJson };
    newDebugJson.numAutoPauses += 1;

    // log the auto pause on the backend
    const response = await supabase
      .from("transcripts")
      .update({
        debug_json: newDebugJson,
      })
      .eq("transcript_id", getState().routes.transcript.transcriptId);

    return { debugJson: newDebugJson };
  }
);

export const getLatestTranscriptSession = createAsyncThunk(
  "sessions/getLatestTranscriptSession",
  async ({ supabase }, { getState }) => {
    const transcriptId = getState().routes.transcript.transcriptId;
    const userId = getState().routes.userId;

    // if user id is equal to default state then user has not logged in
    if (!getState().routes.isLoggedIn) {
      console.log("not logged in");
      return { latestSession: { ...initialState.session } };
    } else {
      // Get the last transcript session that matches
      // the current transcript id and user id
      const { data, error } = await supabase
        .from("sessions")
        .select()
        .eq("user_id", userId)
        .eq("transcript_id", transcriptId)
        .order("start_date", { ascending: false })
        .limit(1);

      // previous session doesn't exist
      if (data.length === 0) {
        return { latestSession: { ...initialState.session } };
      }

      const previousSession = { ...initialState.session };
      previousSession.startDate = data[0].start_date;
      previousSession.endDate = data[0].end_date;
      previousSession.sessionId = data[0].session_id;
      previousSession.userId = data[0].user_id;
      previousSession.transcriptId = data[0].transcript_id;
      previousSession.newUser = data[0].new_user;

      return { latestSession: previousSession };
    }
  }
);

export const createSession = createAsyncThunk(
  "sessions/createSession",
  async ({ supabase }, { getState }) => {
    console.log("creating session");
    const transcriptId = getState().routes.transcript.transcriptId;
    const userId = getState().routes.userId;
    const createdDate = new Date();
    const endDate = new Date();
    const sessionId = (transcriptId + createdDate.getTime()).toString();
    const isNewUser = getState().routes.isNewUser;

    // add a new session to the session table
    const { data, error } = await supabase
      .from("sessions")
      .insert({
        session_id: sessionId,
        start_date: createdDate,
        end_date: endDate,
        user_id: userId,
        transcript_id: transcriptId,
        new_user: isNewUser,
      })
      .select();

    const session = { ...initialState.session };
    session.sessionId = sessionId;
    session.userId = userId;
    session.transcriptId = transcriptId;
    session.newUser = isNewUser;
    session.createdDate = createdDate.toString();
    session.endDate = endDate.toString();

    return { session };
  }
);

export const updateSessionEndDate = createAsyncThunk(
  "sessions/updateSessionEndDate",
  async ({ supabase }, { dispatch, getState }) => {
    console.log(`updating session`);
    const session = getState().routes.session;
    let newSession = { ...session };

    // session does not exist locally...
    if (session.sessionId === null) {
      // check if there is a recent session in the backend
      let { latestSession } = await dispatch(
        getLatestTranscriptSession({ supabase })
      ).unwrap();

      // no recent sessions so create one
      if (latestSession.sessionId === null) {
        return await dispatch(createSession({ supabase })).unwrap();
      }

      // recent session exists, check how long ago it ended in minutes
      const currentTime = new Date();
      const timeSinceLastSessionEnded =
        (currentTime - new Date(latestSession.endDate)) / (1000 * 60);

      const SESSION_THRESHOLD = 5;

      // session ended too long ago so create a new one
      if (timeSinceLastSessionEnded >= SESSION_THRESHOLD) {
        return await dispatch(createSession({ supabase })).unwrap();
      }

      // session was recent enough so continue it
      newSession = latestSession;
    }

    // update the end_date
    const currentTime = new Date();
    const { error } = await supabase
      .from("sessions")
      .update({ end_date: currentTime })
      // .select()
      .eq("session_id", newSession.sessionId);

    newSession.endDate = currentTime.toString();
    return { session: newSession };
  }
);

export const isNewUser = createAsyncThunk(
  "sessions/isNewUser",
  async ({ supabase }, { getState, dispatch }) => {
    const userId = getState().routes.userId;
    // determine if user id already exists in database
    const { data, error } = await supabase
      .from("user data")
      .select()
      .eq("user_id", userId)
      .limit(1);

    const isNewUser = data.length === 0;
    return { isNewUser };
  }
);

export const logTranscriptSettings = createAsyncThunk(
  "users/logTranscriptSettings",
  async (
    { supabase, customVocab, filterProfanity, filterDisfluencies, saveAudio },
    { getState }
  ) => {
    console.log(`logging debug info`);

    const newDebugJson = {};
    newDebugJson.settings = {
      customVocab: customVocab,
      filterProfanity: filterProfanity,
      filterDisfluencies: filterDisfluencies,
      saveAudio: saveAudio,
    };
    const deviceType = getDeviceType();
    newDebugJson.deviceType = deviceType;
    newDebugJson.numAutoPauses = 0;
    console.log(`here is debug jsons: ${JSON.stringify(newDebugJson)}`);

    return { debugJson: newDebugJson };
  }
);

export const setDarkMode = createAsyncThunk(
  "users/setDarkMode",
  async ({ supabase, darkMode }, { getState }) => {
    console.log("just entered the function");
    const { userId } = getState().routes;

    // if the user doesn't exist, initialize this stuff to an empty list
    const { data: upsertData, error: upsertError } = await supabase
      .from("user data")
      .upsert(
        {
          user_id: userId,
          dark_mode: darkMode,
        },
        { onConflict: "user_id", ignoreDuplicates: false }
      )
      .select();

    if (upsertError) {
      console.log("upsert error: " + JSON.stringify(upsertError));
    } else {
      console.log("success");
    }

    console.log(`dark mode: ${darkMode}`);
    return { darkMode: darkMode };
  }
);

export const saveTranscriptAudio = createAsyncThunk(
  "users/saveTranscriptAudio",
  async (
    { supabase, audioBlobs, fileType = "audio/mpeg" },
    { getState, dispatch }
  ) => {
    dispatch(setAudioUploading({ uploadingState: true }));
    // create the audio file
    const audioBlob = new Blob(audioBlobs, {
      type: fileType,
    });
    console.log(`uploading the audio with type: ${fileType}`);

    // this is the code to upload audio
    const tus = require("tus-js-client");
    const {
      data: { session },
    } = await supabase.auth.getSession();
    return new Promise(async (resolve, reject) => {
      const upload = new tus.Upload(audioBlob, {
        endpoint: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/upload/resumable`,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        headers: {
          authorization: `Bearer ${session.access_token}`,
          "x-upsert": "true",
        },
        uploadDataDuringCreation: true,
        removeFingerprintOnSuccess: true,
        metadata: {
          bucketName: "recordings",
          objectName: `${getState().routes.userId}/${
            getState().routes.transcript.transcriptId
          }.ogg`,
          contentType: "audio/mpeg",
          cacheControl: 3600,
        },
        // NOTE: it must be set to 6MB (for now) do not change it
        chunkSize: 6 * 1024 * 1024,
        onError: (error) => {
          console.log("Failed because: " + error);
          reject(error);
          dispatch(setAudioUploading({ uploadingState: false }));
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log(bytesUploaded, bytesTotal, percentage + "%");
          dispatch(setAudioUploadProgress({ percentage: percentage }));
        },

        // successfully uploaded audio
        onSuccess: () => {
          console.log(`Download ${upload.file.name} from ${upload.url}`);

          const { data: audioUrlJson } = supabase.storage
            .from("recordings")
            .getPublicUrl(
              `${getState().routes.userId}/${
                getState().routes.transcript.transcriptId
              }.ogg`
            );
          const audioUrl = audioUrlJson.publicUrl;
          console.log(`here is the audio url: ${audioUrl}`);

          supabase
            .from("transcripts")
            .update({
              audio_url: audioUrl,
            })
            .eq("transcript_id", getState().routes.transcript.transcriptId)
            .then((response) => {
              console.log("added to databse");
              dispatch(
                addAudioUrlLocal({
                  audioUrl: audioUrl,
                })
              );
            });

          dispatch(setAudioUploading({ uploadingState: false }));
          resolve();
        },
      });

      // Check if there are any previous uploads to continue.
      return upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload
        upload.start();
      });
    });
  }
);

export const updateFolderTitle = createAsyncThunk(
  "users/updateFolderTitle",
  async (
    { supabase, indexToChange, newFolderTitle },
    { getState, dispatch }
  ) => {
    // update name of folder
    const folders = getState().routes.folders.map((folderTitle, index) =>
      index === indexToChange ? newFolderTitle : folderTitle
    );

    // update folders locally
    dispatch(updateFolders({ folders: folders }));

    // update folders backend
    supabase
      .from("user data")
      .update({
        folders: folders,
      })
      .eq("user_id", getState().routes.userId)
      .then((response) => {
        console.log(`RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const createTranscriptUrl = async (supabase) => {
  const letters = "qwertyuiopasdfghjklzxcvbnm";

  let transcriptUrl = "";
  let duplicateUrl = true;

  // keep creating urls until we found one that hasn't been used
  // 5 lowercase letters: 26^5 = 11,881,376
  do {
    transcriptUrl = "";
    for (let i = 0; i < 5; i++) {
      transcriptUrl += letters[getRandomInt(0, 25)];
    }
    const { data: urlData, error: selectUrlDataError } = await supabase
      .from("transcripts")
      .select("")
      .eq("transcript_url", transcriptUrl);
    duplicateUrl = selectUrlDataError ? true : false;
  } while (duplicateUrl === true);

  return transcriptUrl;
};

export const goToTranscript = createAsyncThunk(
  "users/goToTranscript",
  async ({ transcriptUrl }, { dispatch }) => {
    window.location.pathname = `t/${transcriptUrl}`;
  }
);

export const setTranscribingFile = createAsyncThunk(
  "users/setTranscribingFile",
  async ({ supabase, transcribingFile }, { getState }) => {
    const debugJson = { ...getState().routes.transcript.debugJson };
    debugJson.uploadAudio = true;

    // push to backend
    supabase
      .from("transcripts")
      .update({
        transcribing_file: transcribingFile,
        debug_json: debugJson,
      })
      .eq("transcript_id", getState().routes.transcript.transcriptId)
      .then((response) => {
        console.log(
          `RESPONSE to toggle transcribing file: ${JSON.stringify(response)}`
        );
        console.log(`new value of transcribing file: ${transcribingFile}`);
      });

    return { transcribingFile, debugJson };
  }
);

export const fetchTranscript = createAsyncThunk(
  "users/fetchTranscript",
  async ({ supabase, transcriptUrl }, { dispatch, getState }) => {
    // this is a new transcript
    if (transcriptUrl === "new") {
      return {
        transcript: { ...initialState.transcript },
        ownerOfTranscript: true,
      };
    }
    const { data: transcriptData, error: selectTranscriptDataError } =
      await supabase
        .from("transcripts")
        .select(
          "audio_url, creation_date, transcript_array, transcript_title, summary, timestamps, transcript_id, gpt_json, bookmarks, user_id, deleted, transcript_privacy, debug_json, transcript_folder, transcribing_file"
        )
        .eq("transcript_url", transcriptUrl);

    if (selectTranscriptDataError) {
      console.log(
        "selection error: " + JSON.stringify(selectTranscriptDataError)
      );
    }

    // couldn't find any matches; this transcript doesn't exist
    if (transcriptData.length === 0) {
      console.log("couldn't find the requested transcript");
      return initialState.FILE_DOES_NOT_EXIST;
    }

    const {
      audio_url,
      transcript_array,
      transcript_title,
      summary,
      timestamps,
      creation_date,
      transcript_id,
      gpt_json,
      bookmarks,
      transcript_privacy,
      user_id,
      deleted,
      transcript_folder,
      debug_json,
      transcribing_file,
    } = transcriptData[0];

    console.log(`value of transcribing file: ${transcribing_file}`);

    // user is trying to access a private transcript they don't own
    if (transcript_privacy && user_id !== getState().routes.userId) {
      console.log("tried to access a private transcript");
      return initialState.FILE_IS_PRIVATE;
    }

    // transcript has been deleted
    if (deleted) {
      return initialState.FILE_HAS_BEEN_DELETED;
    }

    // update time of session end
    dispatch(updateSessionEndDate({ supabase }));

    return {
      transcript: {
        audioUrl: audio_url,
        transcriptArray: transcript_array,
        transcriptTitle: transcript_title,
        summary: summary,
        creationDate: creation_date,
        transcriptId: transcript_id,
        transcriptFolder: transcript_folder,
        timestamps: timestamps,
        bookmarks: bookmarks,
        gptJson: gpt_json,
        transcriptPrivacy: true,
        transcriptUrl: transcriptUrl,
        debugJson: debug_json,
      },
      transcribingFile: transcribing_file,
      ownerOfTranscript: user_id === getState().routes.userId,
    };
  }
);

export const createFolder = createAsyncThunk(
  "users/createFolder",
  async ({ supabase, folderName }) => {}
);

export const deleteFolder = createAsyncThunk(
  "users/createFolder",
  async ({ supabase, indexToRemove }, { getState, dispatch }) => {
    // remove the folder
    const folders = getState().routes.folders.filter(
      (folder, index) => index !== indexToRemove
    );

    // update folders locally
    dispatch(updateFolders({ folders: folders }));

    // push to backend
    supabase
      .from("user data")
      .update({
        folders: folders,
      })
      .eq("user_id", getState().routes.userId)
      .then((response) => {
        console.log(`RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const toggleBookmark = createAsyncThunk(
  "users/toggleBookmark",
  async ({ supabase, index: indexToChange }, { getState, dispatch }) => {
    // toggle the bookmark
    const bookmarks = [...getState().routes.transcript.bookmarks];
    bookmarks[indexToChange] = !bookmarks[indexToChange];

    // toggle the bookmark locally to
    dispatch(updateBookmarks({ bookmarks: bookmarks }));

    supabase
      .from("transcripts")
      .update({
        bookmarks: bookmarks,
      })
      .eq("transcript_id", getState().routes.transcript.transcriptId)
      .then((response) => {
        console.log(`RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const togglePrivacy = createAsyncThunk(
  "users/togglePrivacy",
  async ({ supabase }, { getState }) => {
    const transcriptPrivacy = getState().routes.transcript.transcriptPrivacy;

    let newTranscriptPrivacy = !transcriptPrivacy;

    const { error, data } = await supabase
      .from("transcripts")
      .update({
        transcript_privacy: newTranscriptPrivacy,
      })
      .eq("transcript_id", getState().routes.transcript.transcriptId);

    return { privacy: newTranscriptPrivacy };
  }
);

export const addToFolder = createAsyncThunk(
  "users/addToFolder",
  async ({ supabase, transcriptIndex, folderName }, { dispatch, getState }) => {
    dispatch(
      updateTranscriptFolderLocal({
        transcriptFolder: folderName,
        transcriptIndex: transcriptIndex,
      })
    );

    supabase
      .from("transcripts")
      .update({
        transcript_folder: folderName,
      })
      .eq(
        "transcript_id",
        getState().routes.transcripts[transcriptIndex].transcriptId
      )
      .then((response) => {
        console.log(`RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const removeFromFolder = createAsyncThunk(
  "users/removeFromFolder",
  async ({ supabase, transcriptIndex }, { dispatch, getState }) => {
    dispatch(
      updateTranscriptFolderLocal({
        transcriptFolder: "",
        transcriptIndex: transcriptIndex,
      })
    );

    supabase
      .from("transcripts")
      .update({
        transcript_folder: "",
      })
      .eq(
        "transcript_id",
        getState().routes.transcripts[transcriptIndex].transcriptId
      )
      .then((response) => {
        console.log(`RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const deleteTranscript = createAsyncThunk(
  "users/deleteTranscript",
  async ({ supabase, index }, { getState, dispatch }) => {
    // get the id of the transcript we're deleting BEFORE modifying transcripts locally
    const deletedTranscriptId =
      getState().routes.transcripts[index].transcriptId;

    // delete the transcription from the front end
    dispatch(deleteTranscriptLocal({ index }));

    // delete the transcript from the backend
    supabase
      .from("transcripts")
      .update({
        deleted: true,
      })
      .eq("transcript_id", deletedTranscriptId)
      .then((response) => {
        console.log(`deleted transcript: ${JSON.stringify(response)}`);
      });

    // delete the transcript audio if it exists
    const { data, error } = await supabase.storage
      .from("recordings")
      .remove([`${getState().routes.userId}/${deletedTranscriptId}.ogg`]);
  }
);

export const massDeleteTranscripts = createAsyncThunk(
  "users/massDeleteTranscript",
  async ({ supabase, indicesToDelete }, { getState, dispatch }) => {
    const transcripts = getState().routes.transcripts;
    const newTranscripts = [];
    for (let i = 0; i < transcripts; i++) {
      if (indicesToDelete.includes[i]) {
        // delete the transcript from the backend
        supabase
          .from("transcripts")
          .update({
            deleted: true,
          })
          .eq("transcript_id", transcripts[i].transcriptId)
          .then((response) => {});

        // delete the transcript audio if it exists
        const { data, error } = await supabase.storage
          .from("recordings")
          .remove([
            `${getState().routes.userId}/${transcripts[i].transcriptId}.ogg`,
          ]);

        // include the transcript in the final result
      } else {
        newTranscripts.push(transcripts[i]);
      }
    }

    // delete the transcription from the front end
    dispatch(
      setTranscriptsLocal({
        transcripts: newTranscripts,
      })
    );
  }
);

export const editTranscriptTitle = createAsyncThunk(
  "users/editTranscriptTitle",
  async ({ supabase, newTitle, index }, { getState, dispatch }) => {
    try {
      if (window.location.pathname === "/t/new" && !fetchedUrl) {
        fetchedUrl = true;
        console.log("actually creating transcript");
        await dispatch(actuallyCreateTranscript({ supabase })).unwrap();
      }
      let { transcriptId } = getState().routes.transcript;

      // update the transcript title locally (from home page)
      if (index !== undefined) {
        // update transcript title locally
        dispatch(
          updateTranscriptTitleLocal({
            newTranscriptTitle: newTitle,
            index: index,
          })
        );
        transcriptId = getState().routes.transcripts[index].transcriptId;
      }

      // update transcript title on backend
      supabase
        .from("transcripts")
        .update({
          transcript_title: newTitle,
        })
        .eq("transcript_id", transcriptId)
        .then((response) => {
          if (response.error) {
            console.log("edit transcript title error: " + response.error);
            return;
          }
        });
    } catch (e) {
      console.error(e);
    }
  }
);

// when the user manually edits the transcript, save it on backend
export const saveTranscriptEdits = createAsyncThunk(
  "users/editTranscript",
  async (supabase, { getState }) => {
    supabase
      .from("transcripts")
      .update({
        transcript_array: getState().routes.transcript.transcriptArray,
      })
      .eq("transcript_id", getState().routes.transcript.transcriptId)
      .then((response) => {
        console.log(`response: ${JSON.stringify(response)}`);
      });
  }
);

// if this is a brand new transcript, go ahead and create it now
export const actuallyCreateTranscript = createAsyncThunk(
  "users/actuallyCreateTranscript",
  async (
    { supabase, folderName = "", transcriptTitle = undefined },
    { getState, dispatch }
  ) => {
    try {
      // get the current transcript with any text that's already been transcribed
      const newTranscript = { ...getState().routes.transcript };

      console.log(`transcript title: ${transcriptTitle}`);

      // update the creation date and set transcript id
      if (transcriptTitle !== undefined) {
        newTranscript.transcriptTitle = transcriptTitle;
        console.log(`transcript title: ${newTranscript.transcriptTitle}`);
      }
      newTranscript.creationDate = getDate();
      newTranscript.transcriptId = uuidv4();
      newTranscript.transcriptFolder = folderName;
      newTranscript.transcriptUrl = await createTranscriptUrl(supabase);
      console.log(`got the url: ${newTranscript.transcriptUrl}`);

      // push the new transcript to the backend
      const { data, error } = await supabase
        .from("transcripts")
        .insert({
          transcript_id: newTranscript.transcriptId,
          transcript_title: newTranscript.transcriptTitle,
          creation_date: newTranscript.creationDate,
          transcript_array: newTranscript.transcriptArray,
          timestamps: newTranscript.timestamps,
          bookmarks: newTranscript.bookmarks,
          audio_url: newTranscript.audioUrl,
          gpt_json: newTranscript.gptJson,
          summary: newTranscript.summary,
          transcript_url: newTranscript.transcriptUrl,
          debug_json: newTranscript.debugJson,
          transcript_folder: newTranscript.transcriptFolder,
          deleted: false,
          transcript_privacy: newTranscript.transcriptPrivacy,
          user_id: getState().routes.userId,
        })
        .select();

      // replace the current url with this new one
      window.history.replaceState(
        null,
        getState().routes.transcript.transcriptTitle,
        `/t/${newTranscript.transcriptUrl}`
      );

      if (error) {
        console.log(`error creating transcript: ${JSON.stringify(error)}`);
      }

      return {
        creationDate: newTranscript.creationDate,
        transcriptId: newTranscript.transcriptId,
        transcriptFolder: newTranscript.transcriptFolder,
        transcriptUrl: newTranscript.transcriptUrl,
        transcriptTitle: newTranscript.transcriptTitle,
      };
    } catch (e) {
      console.error(e);
    }
  }
);

let fetchedUrl = false;

// just received more text from transcription api
export const updateTranscript = createAsyncThunk(
  "users/updateTranscript",
  async ({ supabase, results }, { getState, dispatch }) => {
    const { timestamps, bookmarks, transcriptArray } =
      getState().routes.transcript;

    const newTranscriptArray = [...transcriptArray];
    const newTimestamps = [...timestamps];
    const newBookmarks = [...bookmarks];

    // console.log(
    //   `just got transcript array: ${JSON.stringify(newTranscriptArray)}`
    // );

    // keeping track of num blurbs since last realtime summary
    let numBlurbsSinceLastSummary = getState().routes.numBlurbsSinceLastSummary;

    // timestamp of the last result
    let prevEndTimestamp = getState().routes.prevEndTimestamp;

    // keeping track of if the last result was partial or final
    let prevTranscriptType = getState().routes.prevTranscriptType;
    let unfinishedText = getState().routes.unfinishedText;

    // we want to know if this result is a fragment or a complete sentence
    let endsSentence = false;

    // if the user paused at some point, add this timestamp onto the previous
    const timestamp = results.ts + getState().routes.pausedTimestamp;

    // maintain the previous timestamp
    prevEndTimestamp = results.end_ts + getState().routes.pausedTimestamp;

    // partial transcripts need to have spaces inserted between; final do not
    let delimiter = "";

    // new result; create new entry in transcript array
    if (prevTranscriptType === initialState.FINAL) {
      newTranscriptArray.push("");
      newTimestamps.push(0);
      newBookmarks.push(false);
      prevTranscriptType = initialState.PARTIAL;
    }

    // update the transcript
    if (results.type === "partial") {
      delimiter = " ";
    } else if (results.type === "final") {
      // we don't want blurbs that are fragments; only make a new blurb if the text
      // ends the sentence with proper punctuation.
      endsSentence =
        results.elements.at(-1).type === "punct" &&
        [".", "!", "?"].includes(results.elements.at(-1).value);

      if (endsSentence) {
        prevTranscriptType = getState().routes.FINAL;
        numBlurbsSinceLastSummary += 1;
      }
    }

    // put the result together in a sentence
    const newText = results.elements
      .map((result) => result.value)
      .join(delimiter);

    // populate last entry in transcript array with new information
    newTranscriptArray[newTranscriptArray.length - 1] =
      unfinishedText + newText;
    newTimestamps[newTimestamps.length - 1] = timestamp;

    // store the final result if we are not making a new blurb for it
    if (results.type === "final" && !endsSentence) {
      unfinishedText += newText + " ";

      // reset unfinishedText if we finally ended the sentence
    } else if (results.type === "final" && endsSentence) {
      unfinishedText = "";
    }

    dispatch(
      updateTranscriptLocal({
        prevTranscriptType,
        prevEndTimestamp,
        numBlurbsSinceLastSummary,
        newTranscriptArray,
        newTimestamps,
        newBookmarks,
        unfinishedText,
      })
    );

    // update backend with new shit
    if (results.type === "final" && endsSentence) {
      supabase
        .from("transcripts")
        .update({
          transcript_array: newTranscriptArray,
          timestamps: newTimestamps,
          bookmarks: newBookmarks,
        })
        .eq("transcript_id", getState().routes.transcript.transcriptId)
        .then(async (response) => {
          // actually create the transcript now that we've added results to the backend
          if (window.location.pathname === "/t/new" && !fetchedUrl) {
            fetchedUrl = true;
            await dispatch(actuallyCreateTranscript({ supabase })).unwrap();
          }
        });
    }
  }
);

export const updateSummary = createAsyncThunk(
  "users/updateSummaries",
  async ({ supabase, newSummary }, { getState, dispatch }) => {
    // update summary locally
    dispatch(updateSummaryLocal({ newSummary }));

    // update summaries on backend
    supabase
      .from("transcripts")
      .update({
        summary: newSummary,
      })
      .eq("transcript_id", getState().routes.transcript.transcriptId)
      .then((response) => {
        console.log(`RESPONSE: ${JSON.stringify(response)}`);
      });
  }
);

export const fetchUserData = createAsyncThunk(
  "users/fetchUserData",
  async ({ supabase }, { getState }) => {
    const { userId } = getState().routes;

    const { data: userData, error: selectDataError } = await supabase
      .from("user data")
      .select("folders, dark_mode, email_preferences")
      .eq("user_id", userId);

    if (selectDataError) {
      console.log("selection error: " + JSON.stringify(selectDataError));
    }

    return userData;
  }
);
export const fetchAllTranscripts = createAsyncThunk(
  "users/fetchAllTranscripts",
  async ({ supabase }, { getState }) => {
    console.log("fetching all transcripts");
    const { userId } = getState().routes;
    const { data: allTranscripts, error: selectDataError } = await supabase
      .from("transcripts")
      .select(
        "audio_url, creation_date, transcript_array, transcript_privacy, deleted, transcript_title, summary, transcript_url, timestamps, transcript_id, gpt_json, transcript_folder, bookmarks, debug_json"
      )
      .eq("user_id", userId)
      .eq("deleted", false)
      .order("created_at", { ascending: false });

    if (selectDataError) {
      console.log("selection error: " + JSON.stringify(selectDataError));
    }

    return allTranscripts;
  }
);

export const updateGptJsons = createAsyncThunk(
  "users/updateGptJsons",
  async ({ gptJsonString, supabase }, { getState, dispatch }) => {
    const { timestamps: gptTimestamps, summaries: gptSummaries } =
      getState().routes.transcript.gptJson;

    let parsedGptJson = {};
    console.log("are we getting here?");

    // could not parse for whatever reason
    try {
      parsedGptJson = JSON.parse(gptJsonString);
    } catch {
      return getState().routes.transcript.gptJson;
    }

    // this json failed but the previous didn't so keep the previous
    if (parsedGptJson.topics && gptTimestamps) {
      console.log(`we are entering here ${JSON.stringify(gptTimestamps)}`);
      return getState().routes.transcript.gptJson;
    }

    const prevTimestamps = [];
    const prevSummaries = [];

    let newGptJson = {};

    // there is no previous json object
    if (gptTimestamps === undefined) {
      console.log("we must be entering here");
      newGptJson = parsedGptJson;

      // there is a previous json object
    } else {
      console.log(
        `we are entering this branch. gptTimestamps: ${JSON.stringify(
          gptTimestamps
        )}`
      );
      for (let i = 0; i < gptTimestamps.length; i++) {
        // if the end timestamp of the previous json
        // is less than the start of the new, keep it
        console.log(
          `last finalized timestamp: ${
            getState().routes.lastFinalizedTimestamp
          }`
        );
        if (
          gptTimestamps[i].split("-").at(1) <=
          getState().routes.lastFinalizedTimestamp
        ) {
          prevTimestamps.push(gptTimestamps[i]);
          prevSummaries.push(gptSummaries[i]);
        }
      }
      newGptJson.timestamps = [...prevTimestamps, ...parsedGptJson.timestamps];
      newGptJson.summaries = [...prevSummaries, ...parsedGptJson.summaries];
    }

    console.log(
      `combined previous and new jsons. result: ${JSON.stringify(newGptJson)}`
    );

    // set the gpt json locally
    dispatch(setGptJsonsLocal({ gptJson: newGptJson }));

    // set the gpt json backend
    supabase
      .from("transcripts")
      .update({
        gpt_json: newGptJson,
      })
      .eq("transcript_id", getState().routes.transcript.transcriptId)
      .then((response) => {
        console.log(`RESPONSE: ${JSON.stringify(response)}`);
      });

    return newGptJson;
  }
);

export const routesSlice = createSlice({
  name: "transcription",
  initialState,
  reducers: {
    resetStartIndex: (state, { payload, type }) => {
      console.log("resetting start index");
      state.startIndex = 0;
      state.transcript.transcriptArray = [];
    },

    // index for when the user pauses transcribing
    handleResumeTranscribing: (state, { payload, type }) => {
      state.startIndex = state.transcript.transcriptArray.length;

      // when we resume, continue the timestamp from
      // where it left off instead of from 0 again
      state.pausedTimestamp = state.prevEndTimestamp;

      // the previous transcript type needs to be final
      // or it will get overwritten by new results
      state.prevTranscriptType = state.FINAL;
    },

    setAudioURL: (state, { payload: { url }, type }) => {
      state.transcript.audioUrl = url;
    },

    getGoogleInfo: (
      state,
      {
        payload: { pfpUrl, fullName, userId, firstName, lastName, email },
        type,
      }
    ) => {
      // updating state
      state.pfpUrl = pfpUrl;
      state.fullName = fullName;
      state.userId = userId;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
    },

    logOut: (state, { payload: { response }, type }) => {
      // window.location.pathname = "/login";
      localStorage.setItem("logout-event", "logout" + Math.random());

      for (const key in state) {
        if (initialState[key] !== undefined) {
          state[key] = initialState[key];
        }
      }

      localStorage.clear();
      state.justLoggedOut = true;
    },

    createNewFolderLocal: (state, { payload, type }) => {
      state.folders = ["", ...state.folders];
    },

    atLoginPage: (state, { payload }) => {
      state.justLoggedOut = false;
    },

    updateFolders: (state, { payload: { folders }, type }) => {
      state.folders = folders;
    },

    deleteTranscriptLocal: (state, { payload: { index }, type }) => {
      state.transcripts.splice(index, 1);
    },

    massDeleteTranscriptsLocal: (
      state,
      { payload: { indicesToRemove }, type }
    ) => {
      state.transcripts = state.transcripts.filter(
        (_, index) => !indicesToRemove.includes(index)
      );
    },

    updateTranscriptTitleLocal: (
      state,
      { payload: { newTranscriptTitle, index }, type }
    ) => {
      state.transcripts[index].transcriptTitle = newTranscriptTitle;
      console.log("renamed transcription title");
    },

    addAudioUrlLocal: (state, { payload: { audioUrl }, type }) => {
      state.transcript.audioUrl = audioUrl;
    },
    setAudioUploading: (state, { payload: { uploadingState }, type }) => {
      state.audioUploading = uploadingState;

      // reset audio upload state
      if (uploadingState === false) {
        state.audioUploadProgress = 0;
      }
    },

    setTranscriptResults: (
      state,
      { payload: { transcriptArray, bookmarks, timestamps, audioUrl }, type }
    ) => {
      console.log(`am i setting transcript results properly?`);
      console.log(`transcriptArray: ${JSON.stringify(transcriptArray)}`);
      console.log(`timestamps: ${JSON.stringify(timestamps)}`);
      state.transcript.transcriptArray = transcriptArray;
      state.transcript.bookmarks = bookmarks;
      state.transcript.timestamps = timestamps;
      state.transcript.audioUrl = audioUrl;
    },

    setAudioUploadProgress: (state, { payload: { percentage }, type }) => {
      state.audioUploadProgress = percentage;
    },

    updateTranscriptFolderLocal: (
      state,
      { payload: { transcriptFolder, transcriptIndex }, type }
    ) => {
      state.transcripts[transcriptIndex].transcriptFolder = transcriptFolder;
    },

    updateTranscriptArray: (state, { payload: { index, newValue }, type }) => {
      state.transcript.transcriptArray[index] = newValue;
    },

    updateSummaryLocal: (state, { payload: { newSummary, index }, type }) => {
      state.transcript.summary = newSummary;
      if (index) {
        state.transcripts[index].summary = newSummary;
      }
    },

    resetPrevTranscriptType: (state, { payload, type }) => {
      state.prevTranscriptType = state.FINAL;
    },

    resetNumBlurbsSinceLastSummary: (state, { payload }) => {
      state.numBlurbsSinceLastSummary = 0;
    },

    updateLastFinalizedTimestamp: (state, { payload: { timestamp } }) => {
      console.log(`old  finalized timestamp: ${state.lastFinalizedTimestamp}`);
      const newTimestamp = timestamp.split("-").at(-1);
      if (newTimestamp > state.lastFinalizedTimestamp) {
        state.lastFinalizedTimestamp = newTimestamp;
      }
      console.log(`New finalized timestamp: ${state.lastFinalizedTimestamp}`);
    },

    setAsFinal: (state, { payload }) => {
      if (state.prevTranscriptType === initialState.PARTIAL) {
        state.prevTranscriptType = state.FINAL;
        state.numBlurbsSinceLastSummary += 1;
      }
    },

    updateBookmarks: (state, { payload: { bookmarks } }) => {
      state.transcript.bookmarks = bookmarks;
    },

    setTranscriptsLocal: (state, { payload: { transcripts } }) => {
      state.transcripts = transcripts;
    },

    updateEmailPreferencesLocal: (state, { payload: { emailPreferences } }) => {
      state.emailPreferences = emailPreferences;
      console.log(`new prefs: ${JSON.stringify(state.emailPreferences)}`);
    },

    setGptJsonsLocal: (state, { payload: { gptJson } }) => {
      state.transcript.gptJson = gptJson;
      console.log(`setting gpt json: ${JSON.stringify(gptJson)}`);
    },

    scrollToBookmark(state, { payload: { index } }) {
      // scrolls to the given bookmark
      const timestampBox = document.getElementsByClassName(
        "timestamp_box " + index
      )[0];
      console.log(timestampBox, index);

      timestampBox.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },

    updateTranscriptLocal: (
      state,
      {
        payload: {
          prevTranscriptType,
          prevEndTimestamp,
          numBlurbsSinceLastSummary,
          newTranscriptArray,
          newTimestamps,
          newBookmarks,
          unfinishedText,
        },
      }
    ) => {
      state.prevTranscriptType = prevTranscriptType;
      state.prevEndTimestamp = prevEndTimestamp;
      state.numBlurbsSinceLastSummary = numBlurbsSinceLastSummary;
      state.transcript.transcriptArray = newTranscriptArray;
      state.transcript.timestamps = newTimestamps;
      state.transcript.bookmarks = newBookmarks;
      state.unfinishedText = unfinishedText;
    },

    resetUnfinishedText: (state, { payload }) => {
      state.unfinishedText = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.fulfilled, (state, { payload: userData }) => {
        const { folders, dark_mode, email_preferences } = userData[0];
        state.isLoggedIn = true;
        state.folders = folders || initialState.folders;
        state.darkMode = dark_mode;
        state.emailPreferences =
          email_preferences || initialState.emailPreferences;
      })
      .addCase(
        fetchAllTranscripts.fulfilled,
        (state, { payload: allTranscripts }) => {
          state.isLoggedIn = true;

          const transcripts = [];
          for (const {
            audio_url,
            transcript_array,
            transcript_title,
            summary,
            creation_date,
            transcript_id,
            transcript_folder,
            debug_json,
            transcript_privacy,
            transcript_url,
          } of allTranscripts) {
            const transcript = {
              audioUrl: audio_url,
              transcriptArray: transcript_array,
              transcriptTitle: transcript_title,
              summary: summary,
              creationDate: creation_date,
              transcriptId: transcript_id,
              transcriptFolder: transcript_folder,
              debugJson: debug_json,
              transcriptPrivacy: transcript_privacy,
              transcriptUrl: transcript_url,
            };
            transcripts.push(transcript);
          }

          state.transcripts = transcripts;
        }
      )
      .addCase(togglePrivacy.fulfilled, (state, { payload: { privacy } }) => {
        state.transcript.transcriptPrivacy = privacy;
      })
      .addCase(
        actuallyCreateTranscript.fulfilled,
        (
          state,
          {
            payload: {
              creationDate,
              transcriptId,
              transcriptFolder,
              transcriptUrl,
              transcriptTitle,
            },
          }
        ) => {
          state.transcript.creationDate = creationDate;
          state.transcript.transcriptId = transcriptId;
          state.transcript.transcriptFolder = transcriptFolder;
          state.transcript.transcriptUrl = transcriptUrl;
          state.transcript.transcriptTitle = transcriptTitle;
          document.getElementById("title_box").value = transcriptTitle;
          state.transcripts = [state.transcript, ...state.transcripts];
        }
      )
      .addCase(
        fetchTranscript.fulfilled,
        (
          state,
          { payload: { transcript, ownerOfTranscript, transcribingFile } }
        ) => {
          state.transcript = transcript;
          state.ownerOfTranscript = ownerOfTranscript;
          state.transcribingFile = transcribingFile;
        }
      )
      .addCase(updateGoogleData.fulfilled, (state, { payload: userData }) => {
        console.log("updated user data:");
      })
      .addCase(updateTranscript.fulfilled, (state, { payload: newState }) => {
        // technically if the backend fails, i'm supposed to change the state back on the frontend
      })
      .addCase(setDarkMode.fulfilled, (state, { payload: _darkMode }) => {
        const { darkMode } = _darkMode;
        state.darkMode = darkMode;
      })
      .addCase(
        logTranscriptSettings.fulfilled,
        (state, { payload: { debugJson } }) => {
          state.transcript.debugJson = debugJson;
        }
      )
      .addCase(createSession.fulfilled, (state, { payload: { session } }) => {
        state.session = session;
      })

      .addCase(
        updateSessionEndDate.fulfilled,
        (state, { payload: { session } }) => {
          state.session = session;
        }
      )
      .addCase(isNewUser.fulfilled, (state, { payload: { isNewUser } }) => {
        state.isNewUser = isNewUser;
      })
      .addCase(
        setTranscribingFile.fulfilled,
        (state, { payload: { transcribingFile, debugJson } }) => {
          state.transcribingFile = transcribingFile;
          state.transcript.debugJson = debugJson;
        }
      );
  },
});

// Action creators are generated for each case reducer function
export const {
  resetStartIndex,
  handleResumeTranscribing,
  addAudio,
  setAudioURL,
  getGoogleInfo,
  logOut,
  createNewFolderLocal,
  deleteTranscriptLocal,
  changeTranscriptionTitleLocal,
  addAudioUrlLocal,
  setAudioUploading,
  setAudioUploadProgress,
  updateTranscriptArray,
  updateSummaryLocal,
  resetPrevTranscriptType,
  resetNumBlurbsSinceLastSummary,
  updateFolders,
  updateTranscriptFolderLocal,
  updateLastFinalizedTimestamp,
  massDeleteTranscriptsLocal,
  setAsFinal,
  updateBookmarks,
  scrollToBookmark,
  updateEmailPreferencesLocal,
  setGptJsonsLocal,
  updateTranscriptLocal,
  updateTranscriptTitleLocal,
  setTranscriptsLocal,
  atLoginPage,
  resetUnfinishedText,
  setTranscriptResults,
} = routesSlice.actions;

export default routesSlice.reducer;
