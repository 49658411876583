import { FiXCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import DarkOverlay from "../DarkOverlay";
import { boxShadow, colors } from "../../utility/utility";
import Button from "../Button";

export const FeedbackButton = ({ setFeedbackForm }) => {
  return (
    <Button
      id="feedback_button"
      onClick={() => setFeedbackForm(true)}
      style={{
        position: "fixed",
        height: 70,
        width: 100,
        bottom: 16,
        right: 16,
        borderRadius: 6,
        fontSize: 14,
        fontWeight: 600,
        margin: 5,
      }}
      content={"Submit Feedback"}
    />
  );
};

export const FeedbackForm = ({
  setFeedbackForm,
  email,
  feedback,
  supabase,
  setEmail,
  setFeedback,
  setSubmitFeedbackLoading,
  submitFeedbackLoading,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  return (
    <>
      <DarkOverlay />
      <div
        style={{
          backgroundColor: darkMode ? colors.black : "white",
          borderRadius: 6,
          boxShadow: darkMode ? "none" : boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
        }}
      >
        <div
          style={{
            position: "fixed",
            // height: 10,
            // width: 10,
            top: 20,
            left: 20,
            cursor: "pointer",
          }}
          onClick={() => setFeedbackForm(false)}
        >
          <FiXCircle color={darkMode ? "white" : "gray"} />
        </div>
        <p
          style={{
            fontSize: 24,
            paddingTop: 0,
            marginTop: 0,
            fontWeight: 500,
            marginBottom: 8,
          }}
        >
          Feedback Form
        </p>
        <p style={{ fontSize: 16, fontWeight: 200, marginBottom: 8 }}>
          Help Ossy get better. Tell us about your experience, report a bug, or
          request a new feature.
        </p>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onSubmit={(e) => {
            setSubmitFeedbackLoading(true);
            submitFeedback(
              e,
              email,
              feedback,
              setFeedbackForm,
              supabase,
              setSubmitFeedbackLoading
            );
          }}
        >
          <input
            required
            type="text"
            placeholder="email"
            pattern=".*@.*\..*"
            style={{
              padding: 8,
              marginBottom: 8,
              borderRadius: 6,
              borderWidth: 0.5,
              borderStyle: "solid",
              fontFamily: "poppins",
              backgroundColor: darkMode ? colors.black : "white",
              color: darkMode ? "white" : "black",
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            required
            type="text"
            placeholder="feedback"
            style={{
              padding: 8,
              borderRadius: 6,
              borderWidth: 0.5,
              resize: "none",
              height: 100,
              marginBottom: 8,
              borderStyle: "solid",
              fontFamily: "poppins",
              backgroundColor: darkMode ? colors.black : "white",
              color: darkMode ? "white" : "black",
            }}
            onChange={(e) => setFeedback(e.target.value)}
          />

          <Button
            style={{
              alignSelf: "flex-end",
            }}
            loading={submitFeedbackLoading}
            content={"Submit"}
          />
        </form>
      </div>
    </>
  );
};

export const submitFeedback = async (
  event,
  email,
  feedback,
  setFeedbackForm,
  supabase,
  setSubmitFeedbackLoading
) => {
  event.preventDefault();
  supabase
    .from("ossy feedback")
    .insert({ email: email, feedback: feedback })
    .then((response) => {
      if (response.status === 201) {
        setSubmitFeedbackLoading(false);
        alert("Successfully submitted feedback. Thank you!");
        setFeedbackForm(false);
      } else {
        alert(
          "Something happened, please try again. If this continues, shoot an email to dajayi@mit.edu and I'll take a look."
        );
      }
    });
};
