import { FiXCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import { boxShadow, colors } from "../../../utility/utility";

const ConfirmDownloadPanel = ({
  setConfirmDownload,
  setStarted,
  dispatch,
  resetStartIndex,
  transcriptTitle,
  audioFile,
  audioBlobs,
  setGPT,
  setGPTResponse,
  setAudioFile,
}) => {
  const { transcriptArray } = useSelector((state) => state.routes.transcript);

  return (
    <>
      {/* dark overlay */}
      <div
        style={{
          flex: 1,
          position: "fixed",
          backgroundColor: "black",
          opacity: 0.5,
          width: "100%",
          height: "100%",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "black",
          width: 400,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => setConfirmDownload(false)}
            >
              <FiXCircle color="gray" />
            </div>
            <h1 style={{ fontSize: 16, paddingTop: 0, marginTop: 0 }}>
              Would you like to download your transcription before resetting?
            </h1>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <button
            style={{
              width: "fit-content",
              backgroundColor: colors.ossyBlue1,
              border: 0,
              color: "white",
              padding: 8,
              borderRadius: 6,
              fontFamily: "poppins",
            }}
            onClick={() => setConfirmDownload(false)}
          >
            Cancel
          </button>

          <div style={{ width: 10 }}></div>

          <button
            style={{
              width: "fit-content",
              backgroundColor: "gray",
              border: 0,
              color: "white",
              padding: 8,
              borderRadius: 6,
              fontFamily: "poppins",
            }}
          >
            <a
              //   href={window.URL.createObjectURL(
              //     new Blob([transcriptArray.join("")], {
              //       type: "text/plain",
              //     })
              //   )}
              // href={window.URL.createObjectURL(
              //   new Blob([audioBlobs], { type: "audio/ogg" })
              // )}
              // href={audioBlobURL}
              href={window.URL.createObjectURL(audioFile)}
              download={transcriptTitle}
              style={{
                // backgroundColor: "green",
                padding: 0,
                margin: 0,
                height: 0,
                color: "white",
                textDecoration: "none",
              }}
            >
              Download Audio
            </a>
          </button>

          <div style={{ width: 10 }}></div>
          <button
            style={{
              width: "fit-content",
              backgroundColor: "gray",
              border: 0,
              color: "white",
              padding: 8,
              borderRadius: 6,
              fontFamily: "poppins",
            }}
          >
            <a
              //   href={window.URL.createObjectURL(
              //     new Blob([transcriptArray.join("")], {
              //       type: "text/plain",
              //     })
              //   )}
              href={window.URL.createObjectURL(
                new Blob([transcriptArray.join(" ")], {
                  type: "text/plain",
                })
              )}
              download={transcriptTitle}
              style={{
                // backgroundColor: "green",
                padding: 0,
                margin: 0,
                height: 0,
                color: "white",
                textDecoration: "none",
              }}
            >
              Download Transcription
            </a>
          </button>

          <div style={{ width: 10 }}></div>

          <button
            style={{
              width: "fit-content",
              backgroundColor: "tomato",
              border: 0,
              color: "white",
              padding: 8,
              borderRadius: 6,
              fontFamily: "poppins",
            }}
            onClick={() => {
              setConfirmDownload(false);
              setStarted(false);
              dispatch(resetStartIndex({}));
              setGPT(false);
              setGPTResponse("");
              setAudioFile(
                new Blob([], {
                  type: "audio/ogg",
                })
              );
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmDownloadPanel;
