import { getDate } from "../utility/utility";

export const initialState = {
  // profile values
  pfpUrl: "",
  isLoggedIn: false,
  fullName: "",
  userId: "",
  isNewUser: false,

  // current values
  startIndex: 0,
  prevEndTimestamp: -1,
  unfinishedText: "",
  transcriptIndex: 0,
  pausedTimestamp: 0,

  folders: [],

  audioUploading: false,
  audioUploadProgress: 0,

  // used to indicate whether or not the
  // previous transcription was partial or final
  PARTIAL: 100,
  FINAL: 101,

  // this is used to:
  // 1. create a new blurb whenever we get a result that is 'final'
  // 2. to only generate a live summary upon reaching a threshold
  // of new 'final' transcript text
  prevTranscriptType: 101,

  numBlurbsSinceLastSummary: 0,
  lastFinalizedTimestamp: "00:00",
  darkMode: false,

  emailPreferences: { newsletters: true, sales: true },

  paidPlan: false,

  usageInfo: {
    paidUser: false,
    lifetimeTranscriptCount: 0,
    deletedTranscriptCount: 0,
    firstVisitDate: getDate(),
  },
  justLoggedOut: false,

  FILE_DOES_NOT_EXIST: 102,
  FILE_HAS_BEEN_DELETED: 103,
  FILE_IS_PRIVATE: 104,

  transcript: {
    audioUrl: "",
    transcriptArray: [],
    timestamps: [],
    bookmarks: [],
    transcriptTitle: "Untitled Transcript",
    creationDate: "",
    transcriptId: "",
    summary: "",
    gptJson: {},
    transcriptFolder: "",
    transcriptUrl: null,
    transcriptPrivacy: true,
    debugJson: {
      settings: {
        customVocab: false,
        filterProfanity: false,
        filterDisfluencies: false,
        saveAudio: false,
      },
      deviceType: "",
      numAutoPauses: 0,
      uploadAudio: false,
    },
  },
  transcripts: [],

  session: {
    sessionId: null,
    startDate: null,
    endDate: null,
    userId: null,
    transcriptId: null,
    newUser: null,
  },
  transcribingFile: false,
};
