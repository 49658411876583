import { borderRadius, colors, formatDate } from "../utility/utility";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState(false);
  const [results, setResults] = useState([]);
  const [searchBarSelected, setSearchBarSelected] = useState(false);
  const transcripts = useSelector((state) => state.routes.transcripts);
  const [searchResultsHover, setSearchResultsHover] = useState(false);
  const darkMode = useSelector((state) => state.routes.darkMode);

  useEffect(() => {
    // convert all the latex to actual math when a search query appears
    if (window.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  }, [results, searchQuery]);

  return (
    <div>
      <div
        style={{
          // justifySelf: "flex-start",
          // alignSelf: "flex-start",
          display: "flex",
          margin: "auto",
          maxWidth: 500,
          height: 32,
          borderRadius: borderRadius,
          backgroundColor: darkMode ? "#2f2f2f" : colors.gray,
          paddingLeft: 8,
          alignItems: "center",
          color: darkMode ? "white" : "gray",
          flexDirection: "column",
          justifyContent: "center",
          // display: "absolute",
          // transform: "translate(0%, 0%)",
        }}
        id="searchbar"
      >
        <input
          placeholder="search"
          autoComplete="off"
          style={{
            backgroundColor: "transparent",
            border: "none",
            fontSize: 14,
            width: "100%",
            color: darkMode ? "white" : "gray",
          }}
          id="searchbar_input"
          onFocus={() => setSearchBarSelected(true)}
          onKeyDown={(event) => {
            if (["Escape"].includes(event.key)) {
              event.currentTarget.blur();
            }
          }}
          onBlur={() => {
            console.log(
              `ACTIVE ELEMENT ID: ${
                document.activeElement.id
              }; type: ${typeof document.activeElement.id}; length: ${
                document.activeElement.id.length
              }`
            );
            console.log(
              `class list: ${JSON.stringify(document.activeElement.classList)}`
            );
            console.log(`active element: ${document.activeElement}`);

            setSearchBarSelected(searchResultsHover);
          }}
          onKeyUp={(event) => {
            const query = event.currentTarget.value.toLowerCase();
            setSearchQuery(query);
            const newResults = [];
            const resultIndices = new Set();

            // check for query in transcript titles
            for (let i = 0; i < transcripts.length; i++) {
              if (
                transcripts[i].transcriptTitle.toLowerCase().includes(query)
              ) {
                newResults.push({
                  title: transcripts[i].transcriptTitle,
                  transcriptIndex: i,
                  creationDate: transcripts[i].creationDate,
                });
                resultIndices.add(i);
              }
              if (newResults.length === 10) {
                console.log(`query results: ${JSON.stringify(newResults)}`);
                setResults(newResults);
                return;
              }
            }

            // check for query in transcription text
            for (let i = 0; i < transcripts.length; i++) {
              if (resultIndices.has(i)) {
                continue;
              }
              const fullText = transcripts[i].transcriptArray.join(" ");
              const fullQueryText = fullText.toLowerCase();
              const index = fullQueryText.indexOf(query);

              if (index !== -1) {
                console.log(`index: ${index}`);
                newResults.push({
                  title: transcripts[i].transcriptTitle,
                  transcriptIndex: i,
                  creationDate: transcripts[i].creationDate,
                  prevText: fullText.substring(Math.max(index - 10, 0), index),
                  query: query,
                  nextText: fullText.substring(
                    index + query.length,
                    Math.min(index + 100, fullText.length - 1)
                  ),
                });
              }
              if (newResults.length === 10) {
                console.log(`query results: ${JSON.stringify(newResults)}`);
                setResults(newResults);
                break;
              }
            }
            console.log(`query results: ${JSON.stringify(newResults)}`);
            setResults(newResults);
          }}
        />
      </div>
      {searchQuery && searchBarSelected && (
        <div
          style={{ height: 0, width: "100%" }}
          id="search_results"
          onMouseEnter={() => setSearchResultsHover(true)}
          onMouseLeave={() => setSearchResultsHover(false)}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 500,
              width: "100%",
              border: results.length ? `1px solid ${colors.gray}` : "none",
              backgroundColor: darkMode ? colors.gray1 : "white",
              borderRadius: borderRadius,
            }}
            className="search"
          >
            {results.map(
              (
                {
                  title,
                  prevText,
                  query,
                  nextText,
                  transcriptIndex,
                  creationDate,
                },
                index
              ) => (
                <div
                  style={{
                    width: "100%",
                    color: darkMode ? "white" : "black",
                  }}
                  key={transcriptIndex}
                  className="search"
                  onClick={async (event) => {
                    const transcriptUrl =
                      transcripts[transcriptIndex].transcriptUrl;
                    window.location.pathname = `t/${transcriptUrl}`;
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: 12,
                      cursor: "pointer",
                      justifyContent: "space-between",
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 12,
                      paddingBottom: 12,
                      borderTopLeftRadius: index === 0 ? borderRadius : 0,
                      borderTopRightRadius: index === 0 ? borderRadius : 0,
                      borderBottomLeftRadius:
                        index === results.length - 1 ? borderRadius : 0,
                      borderBottomRightRadius:
                        index === results.length - 1 ? borderRadius : 0,
                    }}
                    onMouseEnter={(event) => {
                      event.currentTarget.style.backgroundColor = darkMode
                        ? colors.gray2
                        : colors.lightGray;
                    }}
                    onMouseLeave={(event) => {
                      event.currentTarget.style.backgroundColor = "transparent";
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        overflow: "clip",
                        justifyContent: "space-between",
                        marginBottom: 4,
                        lineHeight: 0.8,
                      }}
                    >
                      <p style={{ whiteSpace: "nowrap", fontWeight: 500 }}>
                        {title}
                      </p>
                      <p
                        style={{
                          marginLeft: 8,
                          fontSize: 10,
                          minWidth: "fit-content",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {formatDate(creationDate)}
                      </p>
                    </div>
                    {/* text preview */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <p
                        style={{
                          opacity: 0.5,
                          fontWeight: 300,
                          whiteSpace: "pre",
                        }}
                      >
                        {prevText}
                      </p>
                      <p
                        style={{
                          opacity: 0.6,
                          fontWeight: 600,
                          whiteSpace: "pre",
                          // color: "gray",
                        }}
                      >
                        {query}
                      </p>
                      <p
                        style={{
                          opacity: 0.5,
                          flexGrow: 1,
                          overflow: "clip",
                          // maxWidth: "0",
                          textOverflow: "ellipsis",
                          fontWeight: 300,
                          whiteSpace: "pre",
                        }}
                      >
                        {nextText}
                      </p>
                    </div>
                  </div>

                  {/* line under each result (off for now) */}
                  {false && index !== results.length - 1 && (
                    <div
                      style={{
                        height: 1,
                        backgroundColor: colors.gray,
                        width: "100%",
                      }}
                    />
                  )}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
