import { logo } from "../assets";

const OssyLogo = () => {
  return (
    <a
      style={{
        textDecoration: "none",
        color: "black",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      href="/home"
    >
      <img src={logo} alt="logo" width={24} style={{ marginRight: 10 }} />
    </a>
  );
};

export default OssyLogo;
