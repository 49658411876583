import { useSelector, useDispatch } from "react-redux";
import { colors, borderRadius, fontSizes } from "../../../utility/utility";

const SummaryBlurb = ({
  index,
  summaryText,
  timestampRange,
  gptPanelCutoff,
  opacity = 1,
}) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  return summaryText ? (
    <div
      style={{
        marginBottom: 16,
        color: "black",
        marginRight: gptPanelCutoff ? 0 : 16,
        opacity: opacity,
      }}
      key={`unique_${index}`}
      id={`summary_${index}`}
    >
      <p
        style={{
          fontWeight: 800,
          color: "gray",
          margin: 0,
          padding: 0,
          fontSize: fontSizes.regular,
        }}
      >
        {timestampRange}
      </p>
      <p
        style={{
          padding: 8,
          margin: 0,
          fontSize: fontSizes.regular,
          lineHeight: 2.5,
          backgroundColor: darkMode ? colors.gray1 : colors.lightGray,
          borderRadius: borderRadius,
          color: darkMode ? "white" : "black",
        }}
      >
        {summaryText}
      </p>
    </div>
  ) : (
    <div key={`summary_${index}`}></div>
  );
};

export default SummaryBlurb;
