import { FiXCircle } from "react-icons/fi";
import DarkOverlay from "./DarkOverlay";
import { boxShadow, colors, GOOGLE_CLIENT_ID } from "../utility/utility";
import { useSelector } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { onSignInGSI } from "../features/login/api/loginApi";
import { useDispatch } from "react-redux";
import { createSession } from "../transcriptSlice";

const LogInPanel = ({
  setLogInPanel,
  supabase,
  setLoading,
  LIVE_TRANSCRIPT_ATTEMPT,
  UPLOAD_TRANSCRIPT_ATTEMPT,
  TITLE_TRANSCRIPT_ATTEMPT,
  VIEW_TRANSCRIPT_ATTEMPT,
  guestUserAction,
  startTranscribing,
}) => {
  const dispatch = useDispatch(); // setting up supabase for submitting feedback

  const darkMode = useSelector((state) => state.routes.darkMode);

  let message = "";
  switch (guestUserAction) {
    case LIVE_TRANSCRIPT_ATTEMPT:
      message = "Sign In To Start Transcribing";
      break;
    case UPLOAD_TRANSCRIPT_ATTEMPT:
      message = "Sign In To Start Transcribing";
      break;
    case TITLE_TRANSCRIPT_ATTEMPT:
      message = "Sign In To Edit Your Transcript Title";
      break;
    case VIEW_TRANSCRIPT_ATTEMPT:
      message = "Sign In To View This Transcript";
      break;
    default:
      message = "";
  }

  return (
    <>
      <DarkOverlay blur />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 400,
          height: 150,
          maxWidth: "80%",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: 20,
            left: 20,
            cursor: "pointer",
          }}
          onClick={() => {
            setLogInPanel(false);
            setLoading(false);
          }}
        >
          {!VIEW_TRANSCRIPT_ATTEMPT && (
            <FiXCircle
              style={{ width: 18, height: 18 }}
              color={darkMode ? "white" : "gray"}
            />
          )}
        </div>
        <h1 style={{ fontSize: 24, paddingTop: 0, marginTop: 0 }}>{message}</h1>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: 80,
          }}
        >
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                await onSignInGSI(credentialResponse, dispatch, supabase);

                setLogInPanel(false);

                // user has logged in, start transcribing
                if (guestUserAction === LIVE_TRANSCRIPT_ATTEMPT) {
                  startTranscribing();
                } else if (guestUserAction === UPLOAD_TRANSCRIPT_ATTEMPT) {
                }

                // start a log session becuase user just logged in on transcript page
                dispatch(createSession({ supabase }));
              }}
              onError={() => {
                console.log("Login Failed");
              }}
              // useOneTap
              auto_select
              width={200}
              shape="square"
            />
          </GoogleOAuthProvider>
        </div>
      </div>
    </>
  );
};

export default LogInPanel;
