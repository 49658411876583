const PlusSign = ({ color }) => {
  return (
    <div
      style={{
        width: 10,
        height: 10,
        marginRight: 6,
        marginLeft: 0,
        paddingLeft: 0,
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: color,
          borderRadius: 1000,
          height: 2,
          position: "absolute",
          top: "50%",
          transform: "translate(0%, -50%)",
        }}
      />
      <div
        style={{
          height: "100%",
          backgroundColor: color,
          borderRadius: 1000,
          width: 2,
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, 0%)",
        }}
      />
    </div>
  );
};
export default PlusSign;
