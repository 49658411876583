import "../App.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchTranscript } from "../transcriptSlice";
import { useLoaderData } from "react-router-dom";
import { initialState } from "../data/initialState";
import TranscriptDoesNotExistPage from "./TranscriptDoesNotExistPage";
import TranscriptHasBeenDeletedPage from "./TranscriptHasBeenDeletedPage";
import TranscriptIsPrivatePage from "./TranscriptIsPrivatePage";
import TranscriptPage from "./TranscriptPage";

const TranscriptRouter = ({ supabase }) => {
  const transcriptUrl = useLoaderData();

  const dispatch = useDispatch();

  const [page, setPage] = useState();
  // const [page, setPage] = useState(<TranscriptPage dummy />);

  // get the transcript data
  useEffect(() => {
    console.log("fetching transcript...");

    // https://ossy.ai/t/ -> https://ossy.ai/
    if (transcriptUrl === "") {
      window.location.pathname = "";
    }

    const fetchTranscript_ = async () => {
      // fetch the transcript data from the database
      const transcriptData = await dispatch(
        fetchTranscript({ supabase: supabase, transcriptUrl: transcriptUrl })
      ).unwrap();

      // invalid url, transcript doesn't exist
      if (transcriptData === initialState.FILE_DOES_NOT_EXIST) {
        setPage(<TranscriptDoesNotExistPage />);
        return;

        // the transcript used to exist but doesn't anymore
      } else if (transcriptData === initialState.FILE_HAS_BEEN_DELETED) {
        setPage(<TranscriptHasBeenDeletedPage />);
        return;

        // the transcript is private and the user is not the owner
      } else if (transcriptData === initialState.FILE_IS_PRIVATE) {
        setPage(<TranscriptIsPrivatePage />);
        console.log("detecting the right thing");
        return;

        // user can access the transcript with no issues
      } else {
        setPage(
          <TranscriptPage
            supabase={supabase}
            ownerOfTranscript={transcriptData.ownerOfTranscript}
          />
        );
        return;
      }
    };

    fetchTranscript_();
  }, [dispatch, transcriptUrl, supabase]);

  return page;
};

export default TranscriptRouter;
