import lock from "../../../assets/locked.png";
import lock_unlocked from "../../../assets/unlocked.png";
import { useDispatch, useSelector } from "react-redux";
import { togglePrivacy } from "../../../transcriptSlice";
import { ToolTip } from "../../../components";
import { initialState } from "../../../data/initialState";

const PrivacyLock = ({ supabase }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const { transcriptArray, transcriptTitle, transcriptPrivacy } = useSelector(
    (state) => state.routes.transcript
  );
  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: 38,
        minWidth: 38,
        maxWidth: 38,
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        margin: 0,
        backgroundColor: darkMode ? "gray" : "lightgray",
        maxHeight: 35,
        minHeight: 35,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
      }}
      onMouseEnter={(event) => {
        if (
          transcriptArray.length !== 0 &&
          transcriptTitle !== "Untitled Transcription"
        ) {
          event.currentTarget.firstChild.style.transform = "scale(1.2)";
        }
      }}
      onMouseLeave={(event) => {
        event.currentTarget.firstChild.style.transform = "scale(1)";
      }}
      onClick={(event) => {
        if (
          transcriptArray.length === 0 &&
          transcriptTitle === initialState.transcript.transcriptTitle
        ) {
          return;
        }
        console.log(`locked: ${transcriptPrivacy}`);
        dispatch(togglePrivacy({ supabase: supabase }));
      }}
    >
      <img
        src={transcriptPrivacy ? lock : lock_unlocked}
        alt="privacy controls"
        style={{ width: 16, transition: ".1s transform" }}
      />
      <ToolTip
        text={
          transcriptArray.length === 0 &&
          transcriptTitle === initialState.transcript.transcriptTitle
            ? "title this transcript or start transcribing to enable public mode"
            : `toggle privacy`
        }
        top={false}
        removeOnClick={false}
      />
    </div>
  );
};

export default PrivacyLock;
