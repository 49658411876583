import { FiXCircle } from "react-icons/fi";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSummaryLocal } from "../../../transcriptSlice";
import { colors, borderRadius, boxShadow } from "../../../utility/utility";
import DarkOverlay from "../../../components/DarkOverlay";
import { fetchSummary } from "../api/summaryApi";
import Button from "../../../components/Button";

const SummaryPanel = ({ setShowSummary, index, supabase }) => {
  const transcript_ = useSelector((state) => state.routes.transcript);
  const transcripts = useSelector((state) => state.routes.transcripts);

  let transcript = transcript_;
  if (index !== undefined) {
    transcript = transcripts[index];
  }
  const { summary, transcriptArray } = transcript;

  const dispatch = useDispatch();
  const [autoscroll, setAutoscroll] = useState(true);
  const darkMode = useSelector((state) => state.routes.darkMode);

  const trptRef = useRef(null);

  useEffect(() => {
    const textbox = document.getElementById("textbox") || trptRef.current;
    if (textbox !== null && autoscroll) {
      textbox.scrollTop = textbox.scrollHeight;
      textbox.scrollTo({
        top: textbox.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [summary, autoscroll]);

  return (
    <>
      {/* dark overlay */}
      <DarkOverlay zIndex={1} />

      <div
        style={{
          alignItems: "center",
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: borderRadius,
          boxShadow: boxShadow,
          color: darkMode ? "white" : "black",
          display: "flex",
          flexDirection: "column",
          left: "50%",
          maxHeight: 400,
          maxWidth: "75dvw",
          marginBottom: 6,
          position: "fixed",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          zIndex: 2,
        }}
      >
        {/* close summary panel button */}
        <div
          style={{
            position: "fixed",
            // height: 10,
            // width: 10,
            left: 20,
            top: 20,
            cursor: "pointer",
          }}
          onClick={() => setShowSummary(false)}
        >
          <FiXCircle color="gray" style={{ height: 20, width: 20 }} />
        </div>

        <div
          style={{
            textAlign: "left",
            fontSize: 20,
            margin: 20,
            fontWeight: 500,
            marginBottom: 0,
          }}
        >
          Summary
        </div>

        <div
          style={{
            marginTop: 0,
            paddingTop: 0,
            fontSize: 16,
            overflowY: "auto",
            padding: 15,
            margin: 5,
            fontWeight: 300,
          }}
          ref={trptRef}
          className="textbox"
          onScroll={(event) => {
            const textbox = event.target;
            setAutoscroll(
              Math.abs(
                textbox.scrollTop + textbox.offsetHeight - textbox.scrollHeight
              ) <= 20
            );
          }}
        >
          <p
            style={{
              textAlign: "left",
            }}
          >
            {summary ? summary : "Generating summary..."}
          </p>
        </div>

        <Button
          style={{
            fontSize: 14,
            borderRadius: borderRadius,
            alignSelf: "flex-end",
            margin: 15,
          }}
          loading={!summary}
          onClick={(event) => {
            // don't generate another summary while waiting for the first one
            if (!summary) {
              return;
            }
            dispatch(
              updateSummaryLocal({
                newSummary: "",
                index: index,
              })
            );
            fetchSummary(supabase, dispatch, transcriptArray, index);
          }}
          content="Generate New Summary"
        />
      </div>
    </>
  );
};

export default SummaryPanel;
