import { colors, borderRadius, fontSizes } from "../../../utility/utility";
import { Timestamp, TranscriptText } from "../../../components";
import { useSelector } from "react-redux";

const TranscriptBlurb = ({
  index,
  text,
  editMode,
  started,
  GPT,
  supabase,
  opacity = 1,
}) => {
  const { bookmarks } = useSelector((state) => state.routes.transcript);
  const listeningForSpeechIndicator = opacity === 0.5;

  return (
    <div
      style={{
        display: "flex",
        color: "black",
        //marginRight: 20,
        flexDirection: "row",
        marginBottom: 4,
        marginLeft: 4,
        paddingTop: 4,
        marginTop: index !== 0 ? 0 : 16,
        paddingBottom: 4,
        marginRight: 20,
        opacity: opacity,
        // padding: 6,
        //border: bookmarks[index] ? "2px solid " + colors.ossyBlue1 : "2px solid transparent",
        backgroundColor: bookmarks[index] ? colors.ossyBlue2 : "transparent",
        borderRadius: borderRadius,
      }}
      key={index}
    >
      <div
        style={{
          color: "gray", // TODO: fix bug where after page reloads, bookmarked timestamp is gray
          fontSize: fontSizes.regular,
          fontWeight: 800,
          // paddingTop: 7,
          // paddingBottom: 7,
          marginTop: 0,
          marginBottom: 0,
          display: "flex",
          alignItems: "flex-start",
          marginRight: 4,
          marginLeft: 4,
          lineHeight: 2.5,
          fontFamily: "poppins",
          width: "100%",
        }}
      >
        {/* timestamp */}
        <Timestamp
          index={index}
          supabase={supabase}
          listeningForSpeechIndicator={listeningForSpeechIndicator}
        />

        {/* transcript text */}
        <TranscriptText
          editMode={editMode}
          GPT={GPT}
          index={index}
          started={started}
          supabase={supabase}
          text={text}
          listeningForSpeechIndicator={listeningForSpeechIndicator}
        />
      </div>
    </div>
  );
};

export default TranscriptBlurb;
