import { colors } from "../utility/utility";
import { useSelector } from "react-redux";

const Header = ({ left, center, right, line, height }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);

  return (
    <div
      style={{
        width: "100%",
        top: 0,
      }}
    >
      <div
        style={{
          alignItems: "center",
          backgroundColor: darkMode ? colors.black : "white",
          display: "flex",
          height: height ? height : 60,
          width: "100%",
        }}
      >
        {/* left header */}
        <div
          style={{
            alignItems: "center",
            display: "flex",
            float: "left",
            height: "100%",
            maxWidth: "20%",
            minWidth: "20%",
            width: "20%",
          }}
        >
          <div style={{ marginLeft: 16, marginRight: 8 }}>{left}</div>
        </div>

        {/* center header */}
        <div
          style={{
            minWidth: "60%",
            maxWidth: "60%",
            margin: 0,
            padding: 0,
            // display: "flex",
            justifyContent: "center",
            width: "60%",
          }}
        >
          {center}
        </div>

        {/* right header */}
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            float: "left",
            height: "100%",
            justifyContent: "flex-end",
            margin: 0,
            maxWidth: "20%",
            minWidth: "20%",
            // overflow: "hidden",
            padding: 0,
            width: "20%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginLeft: 8,
              marginRight: 16,
              padding: 0,
            }}
            id="right-header"
          >
            {right}
          </div>
        </div>
      </div>

      {/* border line */}
      {line && (
        <div
          style={{
            backgroundColor: darkMode ? "gray" : colors.gray,
            height: 0.5,
          }}
        />
      )}
    </div>
  );
};

export default Header;
