import Header from "./Header";
import { SearchBar, ProfileButton, OssyText, OssyLogo } from "./";
import { useState } from "react";

const HomeHeader = ({ smallWindow, setMenuOpen, supabase }) => {
  const [menuColor, setMenuColor] = useState("gray");

  return (
    <Header
      left={
        smallWindow ? (
          <>
            <div
              style={{
                cursor: "pointer",
                borderRadius: 6,
                width: 22,
                height: 20,
                fontSize: 12,
                display: "flex",
                alignItems: "center",
                marginRight: 10,
                justifyContent: "space-between",
                flexDirection: "column",
                padding: 0,
              }}
              onClick={() => setMenuOpen(true)}
              onMouseEnter={() => setMenuColor("lightgray")}
              onMouseLeave={() => setMenuColor("gray")}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: menuColor,
                  height: 4,
                }}
              />
              <div
                style={{
                  width: "100%",
                  backgroundColor: menuColor,
                  height: 4,
                }}
              />
              <div
                style={{
                  width: "100%",
                  backgroundColor: menuColor,
                  height: 4,
                }}
              />
            </div>
          </>
        ) : (
          // show ossy in header if large window
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <OssyLogo />
            <OssyText />
          </div>
        )
      }
      center={<SearchBar supabase={supabase} />}
      right={
        // profile button in top right
        <div
          style={{
            color: "black",
            // alignSelf: "center",
            // alignItems: "center",
            right: 0,

            fontSize: 12,
            display: "flex",
            cursor: "pointer",
            justifyContent: "flex-end",
          }}
        >
          <ProfileButton removeNameWhenSmall={768} includeName={true} />
        </div>
      }
      line={true}
    />
  );
};

export default HomeHeader;
