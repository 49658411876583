import { borderRadius, colors } from "../utility/utility";
import { Button, HomeHeader } from "../components";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDarkMode,
  fetchUserData,
  updateEmailPreferences,
} from "../transcriptSlice";

const SettingsPage = ({ supabase }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 630px)").matches
  );
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.routes.darkMode);
  const emailPreferences = useSelector(
    (state) => state.routes.emailPreferences
  );

  // fetch user data. if user changes setting on another device/tab,
  // should reflect here as well (on page reload)
  useEffect(() => {
    dispatch(fetchUserData({ supabase }));
  }, [dispatch, supabase]);

  window.onresize = () => {
    const newSmallWindow = window.matchMedia("(max-width: 630px)").matches;
    setSmallWindow(newSmallWindow);

    // if the screen expands, reset small menu to disappear
    if (!newSmallWindow && menuOpen) {
      setMenuOpen(false);
    }
  };
  return (
    <div
      style={{
        flex: 1,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        alignItems: "center",
        backgroundColor: darkMode ? colors.black : "white",
        // justifyContent: "center",
        color: darkMode ? "white" : colors.black,
      }}
    >
      <HomeHeader supabase={supabase} />

      {/* dark mode */}
      <div
        style={{
          display: "flex",
          flexDirection: smallWindow ? "column" : "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "980px",
          maxWidth: "80%",
          marginTop: 40,
        }}
      >
        <p
          style={{
            fontSize: 24,
            fontWeight: 600,
            marginRight: 50,
            marginBottom: 8,
            maxWidth: smallWindow ? "100%" : 200,
            minWidth: 200,
          }}
        >
          Dark Mode
        </p>

        <div
          style={{
            backgroundColor: darkMode ? colors.gray1 : colors.gray,
            borderRadius: borderRadius,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: 16,
            maxWidth: "100%",
            flexGrow: 1,
            padding: 16,
            width: smallWindow ? "calc(100% - 32px)" : "auto",
          }}
        >
          {/* dark mode off */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* dark mode off image */}
            <div
              style={{
                display: "flex",
                borderRadius: borderRadius,
                backgroundColor: "white",
                // alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: 400,
                fontSize: 14,
                height: 100,
                width: 100,
                // padding: 8,

                marginBottom: 8,
                border: `5px solid ${
                  darkMode ? colors.gray1 : colors.darkBlue
                }`,
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setDarkMode({ supabase: supabase, darkMode: false }));
              }}
            >
              <div
                style={{
                  backgroundColor: colors.ossyBlue1,
                  height: 28,
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  width: "100%",
                }}
              />
            </div>
            <p
              style={{
                fontWeight: 600,
              }}
            >
              Off
            </p>
          </div>

          {/* dark mode on */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* dark mode image */}
            <div
              style={{
                display: "flex",
                borderRadius: borderRadius,
                backgroundColor: colors.black,
                // alignItems: "center",
                justifyContent: "center",
                fontWeight: 400,
                fontSize: 14,
                height: 100,
                width: 100,
                // padding: 8,

                marginBottom: 8,
                border: `5px solid ${darkMode ? colors.darkBlue : colors.gray}`,
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setDarkMode({ supabase: supabase, darkMode: true }));
              }}
            ></div>
            <p style={{ fontWeight: 600 }}>On</p>
          </div>
        </div>
      </div>

      {/* email preferences */}
      <div
        style={{
          display: "flex",
          flexDirection: smallWindow ? "column" : "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "980px",
          maxWidth: "80%",
          marginTop: 40,
        }}
      >
        <p
          style={{
            fontSize: 24,
            fontWeight: 600,
            marginRight: 50,
            marginBottom: 8,
            minWidth: 200,
            maxWidth: smallWindow ? "100%" : 200,
          }}
        >
          Email Preferences
        </p>

        <div
          style={{
            backgroundColor: darkMode ? colors.gray1 : colors.gray,
            borderRadius: borderRadius,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 16,
            maxWidth: "100%",
            flexGrow: 1,
            padding: 16,
            width: smallWindow ? "calc(100% - 32px)" : "auto",
          }}
        >
          <p style={{ fontWeight: 600, fontSize: 18 }}>
            Control what emails you get from ossy.ai
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              fontWeight: 400,
            }}
          >
            <input
              type="checkbox"
              defaultChecked={emailPreferences.newsletters}
              style={{ marginRight: 8 }}
              onChange={(event) => {
                console.log(`value: ${event.currentTarget.checked}`);
                dispatch(
                  updateEmailPreferences({
                    newsletters: event.currentTarget.checked,
                    supabase: supabase,
                  })
                );
              }}
            />
            <label>Ossy.ai updates, newsletters, and recommendations</label>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              fontWeight: 400,
            }}
          >
            <input
              type="checkbox"
              defaultChecked={emailPreferences.sales}
              style={{ marginRight: 8 }}
              onChange={(event) => {
                console.log(`value: ${event.currentTarget.checked}`);
                dispatch(
                  updateEmailPreferences({
                    sales: event.currentTarget.checked,
                    supabase: supabase,
                  })
                );
              }}
            />
            <label>Ossy.ai sales, giveaways, and promotions</label>
          </div>
          {/* <div
            style={{
              backgroundColor: colors.ossyBlue1,
              borderRadius: borderRadius,
              cursor: "pointer",
              padding: 8,
            }}
            onMouseEnter={(event) =>
              (event.currentTarget.style.backgroundColor = darkMode
                ? "gray"
                : colors.gray)
            }
            onMouseLeave={(event) =>
              (event.currentTarget.style.backgroundColor = colors.ossyBlue1)
            }
          >
            Save
          </div> */}
        </div>
      </div>

      <div style={{ padding: 50 }} />
    </div>
  );
};

export default SettingsPage;
