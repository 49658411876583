import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  borderRadius,
  colors,
  WORDS_TO_TOKENS,
  formatTimestamp,
  boxShadow,
  fontSizes,
} from "../../../utility/utility";
import {
  updateLastFinalizedTimestamp,
  updateGptJsons,
  resetNumBlurbsSinceLastSummary,
} from "../../../transcriptSlice";
import { FiXCircle } from "react-icons/fi";
import { initialState } from "../../../data/initialState";
import SummaryBlurb from "./SummaryBlurb";

const GPTPanel = ({
  GPT,
  started,
  editMode,
  supabase,
  gptPanelCutoff,
  setGPT,
}) => {
  const { gptJson, timestamps, transcriptArray } = useSelector(
    (state) => state.routes.transcript
  );
  const [fetchingSummary, setFetchingSummary] = useState(false);
  const [autoscroll, setAutoscroll] = useState(true);
  const lastFinalizedTimestamp = useSelector(
    (state) => state.routes.lastFinalizedTimestamp
  );
  const dispatch = useDispatch();
  const prevTranscriptType = useSelector(
    (state) => state.routes.prevTranscriptType
  );
  const FINAL = initialState.FINAL;
  const PARTIAL = initialState.PARTIAL;
  const numBlurbsSinceLastSummary = useSelector(
    (state) => state.routes.numBlurbsSinceLastSummary
  );
  const darkMode = useSelector((state) => state.routes.darkMode);

  const [awaitingNewExplanation, setAwaitingNewExplanation] = useState(false);

  const [tsRangeFetching, setTsRangeFetching] = useState(0);

  const transcribingFile = useSelector(
    (state) => state.routes.transcribingFile
  );

  // clicking on transcription blurb should auto-jump to the corresponding explanation
  useEffect(() => {
    if (gptJson === "" || !gptJson.timestamps) {
      return;
    }
    console.log(`setting up clicks between blurbs`);
    const gptTimestamps = gptJson.timestamps;
    const gptSummaries = gptJson.summaries;
    let t_i = 0;
    let timestamp = formatTimestamp(timestamps[t_i]);
    const gptMinLength = Math.min(gptTimestamps.length, gptSummaries.length);

    for (let i = 0; i < gptMinLength; i++) {
      const rangeEndTimestamp = gptTimestamps[i].split("-").at(-1);
      while (
        t_i < timestamps.length &&
        (timestamp < rangeEndTimestamp ||
          (i === gptMinLength - 1 && t_i < timestamps.length))
      ) {
        timestamp = formatTimestamp(timestamps[t_i]);

        const transcriptionBlurb = document.getElementsByClassName(
          `transcript_array_box ${timestamp}`
        )[0];

        // assign this summary index to all transcription blurbs that correspond to this summary
        if (transcriptionBlurb) {
          transcriptionBlurb.id = i;
        } else {
          console.log("could not find the blurb");
        }
        t_i += 1;
      }
    }

    const lastTranscriptBlurb = document.getElementsByClassName(
      `transcript_array_box ${timestamp}`
    )[0];
    if (lastTranscriptBlurb) {
      lastTranscriptBlurb.id = gptTimestamps.length - 1;
    }
  }, [gptJson, timestamps]);

  const generateGPTPanelSummary = () => {
    setFetchingSummary(true);
    dispatch(resetNumBlurbsSinceLastSummary({}));

    console.log("ok are we getting here?");

    // gpt 3.5 turbo-16k can take up to 16,384 tokens per response
    const token_limit = 13000;

    let requestText = "";
    let numWords = 0;
    let totalDuration = 0;
    let mostRecentTimestamp = -1;

    // start backwards; give api the most recent text from lecture
    // until we run out of tokens
    let i = transcriptArray.length - 1;

    // stay under token limit
    // send at most the last 10 minutes of lecture (to improve gpt response time)
    while (
      i >= 0 &&
      numWords * WORDS_TO_TOKENS < token_limit &&
      totalDuration < 60 * 10
    ) {
      // filter partial results from the text passed to the api
      if (i === transcriptArray.length - 1 && prevTranscriptType === PARTIAL) {
        continue;
      }

      if (mostRecentTimestamp === -1) {
        mostRecentTimestamp = timestamps[i];
      }

      requestText =
        `${formatTimestamp(timestamps[i])} - "${transcriptArray[i]}"\n` +
        requestText;
      numWords += transcriptArray[i].split(" ").length;
      totalDuration = mostRecentTimestamp - timestamps[i];
      console.log(`TOTAL DURATION: ${totalDuration}`);
      i -= 1;
    }

    // get ts of last final transcription result
    const endTimestamp = formatTimestamp(
      prevTranscriptType === FINAL ? timestamps.at(-1) : timestamps.at(-2)
    );

    // met conditions to get new summary
    console.log(
      `fetcing another summary. start ts: ${lastFinalizedTimestamp}; end ts: ${endTimestamp}`
    );

    setAwaitingNewExplanation(true);
    console.log(`num blurbs since summary: ${numBlurbsSinceLastSummary}`);
    console.log(
      `timestamp at index formatted: ${formatTimestamp(
        timestamps.at(-numBlurbsSinceLastSummary)
      )}`
    );
    console.log(
      `timestamp at index: ${timestamps.at(-numBlurbsSinceLastSummary)}`
    );
    console.log(`all timestamps: ${JSON.stringify(timestamps)}`);

    setTsRangeFetching(timestamps.at(-numBlurbsSinceLastSummary));

    // fetch the summary blurb
    fetch(
      // `http://localhost:8080/short_summary`,
      `https://ossy-backend-5sljdp5hja-uk.a.run.app/short_summary`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/x-www-form-urlencoded",
        },
        body: `full_text=${requestText}&start_ts=${lastFinalizedTimestamp}&end_ts=${endTimestamp}`,
      }
    )
      .then(async (response) => {
        console.log("Entered .then");
        let jsonString = "";
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            async function pump() {
              return reader.read().then(({ done, value }) => {
                jsonString += new TextDecoder().decode(value);

                // close the stream
                if (done) {
                  console.log(
                    `finished receiving json string. requested ${lastFinalizedTimestamp} to ${endTimestamp}: ${jsonString}`
                  );

                  dispatch(
                    updateGptJsons({
                      gptJsonString: jsonString,
                      supabase: supabase,
                    })
                  )
                    .unwrap()
                    .then((finalGptJson) => {
                      setAwaitingNewExplanation(false);
                      console.log("ummmmm does this even work?");
                      if (
                        finalGptJson &&
                        finalGptJson.timestamps &&
                        finalGptJson.timestamps.length >= 3
                      ) {
                        console.log("updating last finalized timestamp");
                        dispatch(
                          updateLastFinalizedTimestamp({
                            timestamp: finalGptJson.timestamps.at(-3),
                          })
                        );
                      } else {
                        console.log("not updating finalized timestamp yet");
                      }
                      console.log("are we making it here 1");

                      // don't start fetching another summary until we ahve finalized this one.
                      setFetchingSummary(false);

                      console.log("finished pushing json to supabase");

                      // autoscroll to the bottom AFTER receiving the summary if GPT panel is open
                      if (autoscroll && GPT) {
                        const aiExplanationsElement =
                          document.getElementById("ai_explanations");
                        if (aiExplanationsElement) {
                          aiExplanationsElement.scrollTop =
                            aiExplanationsElement.scrollHeight;
                          console.log(
                            `just tried to scroll to the bottom of ai explanations`
                          );
                        }
                      }
                    });
                  controller.close();
                  return;
                }

                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .catch((reason) => {
        setFetchingSummary(false);
        setAwaitingNewExplanation(false);
      });
  };

  useEffect(() => {
    if (transcriptArray.length > 0 && Object.keys(gptJson).length == 0) {
      generateGPTPanelSummary();
    }
  }, []);

  // automatically generating the short summaries
  useEffect(() => {
    // only provide AI summaries if user opts-in
    if (!GPT) {
      return;
    }

    // wait to come out of edit mode
    if (editMode) {
      return;
    }

    // if we are already fetching a summary then wait nigga.
    if (fetchingSummary) {
      console.log("already fetching");
      return;
    }

    // we already have summaries for this transcription
    if (!started && gptJson !== "") {
      return;
    }

    // there are no blurbs to summarize
    if (transcriptArray.length === 0) {
      return;
    }

    // at least 1 new transcript blurb
    if (numBlurbsSinceLastSummary === 0) {
      return;
    }

    console.log(`numBlurbsSinceLastSummary: ${numBlurbsSinceLastSummary}`);
    const numBlurbsSinceLastSummaryText = transcriptArray
      .slice(-numBlurbsSinceLastSummary)
      .join(" ");
    console.log(
      `numBlurbsSinceLastSummary text: ${numBlurbsSinceLastSummaryText}`
    );
    console.log(
      `numBlurbsSinceLastSummary length: ${
        numBlurbsSinceLastSummaryText.split(" ").length
      }`
    );

    // we want at least 4 new words to transcribe
    if (numBlurbsSinceLastSummaryText.split(" ").length < 4) {
      return;
    }

    // don't try to summarize a phrase; must end in punctuation
    if (![".", "!", "?"].includes(numBlurbsSinceLastSummaryText.at(-1))) {
      return;
    }
    console.log("bro wtf is going on");
    generateGPTPanelSummary();

    // only create a summary when receiving
    // new text or when coming out of edit mode.
  }, [
    transcriptArray,
    editMode,
    GPT,
    FINAL,
    PARTIAL,
    autoscroll,
    dispatch,
    fetchingSummary,
    gptJson,
    started,
    supabase,
    timestamps,
    lastFinalizedTimestamp,
    numBlurbsSinceLastSummary,
    prevTranscriptType,
  ]);

  return GPT && (started || transcriptArray.length || transcribingFile) ? (
    <>
      {gptPanelCutoff && (
        <div
          style={{
            flex: 1,
            position: "absolute",
            left: 0,
            top: 0,
            backgroundColor: "black",
            opacity: "0.3",
            width: "100%",
            height: "100%",
            cursor: "default",
            zIndex: 1,
          }}
        />
      )}
      <div
        style={{
          ...{
            flex: 1,
            color: "gray",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            fontSize: fontSizes.big,
            fontWeight: 400,
            lineHeight: 2.4,
            maxWidth: gptPanelCutoff ? "80%" : "50%",
            justifyContent: "center",
            margin: gptPanelCutoff ? 0 : 20,
            boxSizing: "border-box",
            resize: "none",
            overflow: gptPanelCutoff ? "auto" : "hidden",
            // backgroundColor: "green",
          },
          ...(gptPanelCutoff
            ? {
                boxShadow: boxShadow,
                padding: 20,
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflowY: "auto",
                backgroundColor: darkMode ? colors.black : "white",
                maxHeight: "80%",

                borderRadius: borderRadius,
                width: 400,
                minHeight: 400,
                zIndex: 2,
              }
            : {}),
        }}
      >
        {gptPanelCutoff && (
          <div
            style={{
              position: "fixed",
              // height: 10,
              // width: 10,
              zIndex: 100,
              top: 20,
              left: 20,
              cursor: "pointer",
            }}
            onClick={() => setGPT(false)}
          >
            <FiXCircle color={darkMode ? "white" : "gray"} />
          </div>
        )}

        <div
          style={{
            margin: 0,
            padding: 0,
            width: "100%",
          }}
        >
          <div
            style={{
              height: "fit-content",
              display: "flex",
              flexDirection: "row",
              position: "relative",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                backgroundColor: "lightslategray",
                color: "white",
                padding: 7,
                borderRadius: 6,
                margin: 0,
                height: "fit-content",
                WebkitBoxDecorationBreak: "clone",
                boxDecorationBreak: "clone",
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
                fontWeight: 600,
                lineHeight: 1.5,
                whiteSpace: "nowrap",
              }}
            >
              {"AI Summaries"}
            </span>
          </div>
        </div>
        <div
          style={{
            marginTop: 20,
            width: "100%",
            overflowX: "hidden",
            scrollbarWidth: "thin",
            textAlign: "left",
            fontSize: 20,
            flexGrow: 1,
            lineHeight: 2.5,
            display: "flex",
            opacity: gptJson.timestamps || gptJson.topics ? 1 : 0.5,
            justifyContent: "flex-start",
            alignItems:
              gptJson.timestamps ||
              (gptJson.topics && gptJson.topics.length) ||
              awaitingNewExplanation
                ? "flex-start"
                : "center",
            flexDirection: "column",

            // this line stops scrolbar from appearing when
            // there isn't enough text for it to be there
            overflowY: "auto",
          }}
          id="ai_explanations"
          onScroll={(event) => {
            const aiPanel = event.target;
            // determine whether or not to continue autoscrolling
            setAutoscroll(
              Math.abs(
                aiPanel.scrollTop + aiPanel.offsetHeight - aiPanel.scrollHeight
              ) <= 20
            );
          }}
        >
          {/* display the summary blurbs */}
          {gptJson !== "" &&
            gptJson.timestamps &&
            gptJson.timestamps.map((timestampRange, index) => {
              return (
                <SummaryBlurb
                  index={index}
                  summaryText={gptJson.summaries[index]}
                  timestampRange={timestampRange}
                  gptPanelCutoff={gptPanelCutoff}
                />
              );
            })}

          {/* no summary blurbs available, display the topics mentioned */}
          {gptJson !== "" &&
            !gptJson.timestamps &&
            gptJson.topics &&
            gptJson.topics.length && (
              <SummaryBlurb
                index={1001}
                summaryText={`The transcription appears to touch on topics such as
                  ${gptJson.topics.join(", ")}.`}
                timestampRange={formatTimestamp(timestamps[0])}
                gptPanelCutoff={gptPanelCutoff}
              />
            )}

          {!gptJson.timestamps &&
            !gptJson.topics &&
            !awaitingNewExplanation &&
            "AI Summaries will appear here"}

          {/* let user know if we're generating a new summary */}
          {awaitingNewExplanation && (
            <SummaryBlurb
              index={1001}
              summaryText={"Generating summary..."}
              timestampRange={`${formatTimestamp(tsRangeFetching)}-`}
              gptPanelCutoff={gptPanelCutoff}
              opacity={0.5}
            />
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default GPTPanel;
