import { FiXCircle } from "react-icons/fi";
import languages from "../../../data/languages_rev_ai.json";
import customVocabs from "../../../data/custom_vocabs.json";
import { useState } from "react";
import { borderRadius, boxShadow, colors } from "../../../utility/utility";
import DarkOverlay from "../../../components/DarkOverlay";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import { Switch } from "../../../components";
import file from "../../../assets/file.png";
import {
  actuallyCreateTranscript,
  setTranscribingFile,
  setTranscriptResults,
} from "../../../transcriptSlice";

const TranscriptSettingsPanel = ({
  startTranscribing,
  setTranscriptionSettings,
  setFilterProfanity,
  setFilterDisfluencies,
  setCustomVocab,
  customVocab,
  filterProfanity,
  filterDisfluencies,
  setLanguage,
  saveAudio,
  setLogInPanel,
  setSaveAudio,
  setLoading,
  setGuestUserAction,
  LIVE_TRANSCRIPT_ATTEMPT,
  UPLOAD_TRANSCRIPT_ATTEMPT,
  supabase,
}) => {
  const [toolTip, setToolTip] = useState({ display: "none" });
  const darkMode = useSelector((state) => state.routes.darkMode);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const [audioFile, setAudioFile] = useState(null);
  const [isValidFile, setIsValidFile] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  // const errorMessage = useRef();
  const [errorMsg, setErrorMsg] = useState();
  const userId = useSelector((state) => state.routes.userId);

  const dispatch = useDispatch();

  const LIVE_TRANSCRIPTION = 1000;
  const UPLOAD_AUDIO = 1001;
  const [transcriptMode, setTranscriptMode] = useState(LIVE_TRANSCRIPTION);

  const acceptedAudioFormats = [
    "mp3",
    "mp4",
    "mpeg",
    "mpga",
    "m4a",
    "wav",
    "webm",
    "x-m4a",
    "flac",
    "mpga",
    "ogg",
  ];

  // Determine if the selected file is valid
  const checkIfValidFile = (file) => {
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / 1024 ** 2;
      const MAX_FILE_SIZE_IN_MB = 25;
      // check if file size is under MAX_FILE_SIZE_IN_MB
      if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
        setErrorMsg(
          `File Size: ${fileSizeInMB.toFixed(
            2
          )} MB. Max file size allowed is 25 MB.`
        );
        setDisplayErrorMessage(true);
        return false;
      }
      // check if file is in an accepted format
      else if (!acceptedAudioFormats.includes(fileType)) {
        // setErrorMsg(
        //   `Allowed file types: ${acceptedAudioFormats.join(
        //     ", "
        //   )}. \n File type: ${fileType}`
        // );
        setErrorMsg("Invalid file format.");
        setDisplayErrorMessage(true);
        return false;
      }
      // file is valid
      else {
        setIsValidFile(true);
        return true;
      }
    }
  };

  // Calls the backend server to transcribe audio file to text
  const transcribeAudio = async () => {
    if (audioFile === null) {
      return;
    }
    const { creationDate, transcriptId, transcriptFolder, transcriptUrl } =
      await dispatch(
        actuallyCreateTranscript({ supabase, transcriptTitle: audioFile.name })
      ).unwrap();

    const formData = new FormData();
    formData.append("audio", audioFile);
    formData.append("userId", userId);
    formData.append("transcriptId", transcriptId);

    console.log("fetching audio transcription");

    dispatch(setTranscribingFile({ supabase, transcribingFile: true }));

    fetch(
      // `http://localhost:8080/transcribe_audio`,
      // `http://localhost:5000/transcribe_audio`,
      `https://ossy-backend-5sljdp5hja-uk.a.run.app/transcribe_audio`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then(async (response) => {
        dispatch(setTranscribingFile({ transcribingFile: false }));

        if (response.ok) {
          const responseJson = await response.json();
          console.log(
            "Created uploaded transcript in supabase. response json: ",
            JSON.stringify(responseJson)
          );

          const { data, error } = responseJson;

          if (error) {
            console.log(
              `couldn't transcibe file for whatever reason: ${error}`
            );
          }

          const { transcriptArray, bookmarks, timestamps, audioUrl } = data;

          dispatch(
            setTranscriptResults({
              transcriptArray,
              bookmarks,
              timestamps,
              audioUrl,
            })
          );
          // dispatch(goToTranscript({ transcriptUrl }));
        } else {
          // console.log(
          //   `Error transcribing the audio file: ${response.statusText}`
          // );
          setDisplayErrorMessage(true);
          setErrorMsg("Could not upload audio");
          throw new Error(
            `Error transcribing the audio file: ${response.statusText}`
          );
        }
      })
      .catch((error) => {
        dispatch(setTranscribingFile({ transcribingFile: false }));
        setDisplayErrorMessage(true);
        setErrorMsg("Could not upload audio");
        console.error(error.message);
      });
  };

  const unselectedBgColor = darkMode ? "gray" : "lightgray";

  return (
    <>
      <DarkOverlay />
      <div
        style={{
          backgroundColor: darkMode ? colors.gray1 : "white",
          borderRadius: 6,
          boxShadow: boxShadow,
          padding: 20,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: darkMode ? "white" : "black",
          width: 500,
          maxWidth: "80%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            position: "fixed",
            // height: 10,
            // width: 10,
            top: 20,
            left: 20,
            cursor: "pointer",
          }}
          onClick={() => setTranscriptionSettings(false)}
        >
          <FiXCircle color={darkMode ? "white" : "gray"} />
        </div>
        <h1 style={{ fontSize: 20, paddingTop: 0, marginTop: 0 }}>
          Transcription Settings
        </h1>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            if (transcriptMode === LIVE_TRANSCRIPTION) {
              startTranscribing();
            } else if (transcriptMode === UPLOAD_AUDIO) {
              transcribeAudio();
            }
            setTranscriptionSettings(false);
          }}
        >
          <div
            style={{ marginBottom: 16, display: "flex", flexDirection: "row" }}
          >
            {[
              { string: "Live Transcription", mode: LIVE_TRANSCRIPTION },
              { string: "Upload Audio", mode: UPLOAD_AUDIO },
            ].map(({ string, mode }, index) => {
              const selected = transcriptMode === mode;
              return (
                <div key={index} style={{ display: "flex" }}>
                  <Button
                    content={string}
                    onClick={(e) => {
                      e.preventDefault();
                      setTranscriptMode(mode);
                    }}
                    bgColor={selected ? undefined : unselectedBgColor}
                  />
                  <div style={{ marginRight: 12 }} />
                </div>
              );
            })}
          </div>

          {transcriptMode === LIVE_TRANSCRIPTION && (
            <div style={{ height: 150, maxHeight: 150 }}>
              {/* subject selection */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: 10,
                  marginTop: 8,
                }}
              >
                <select
                  name="subject"
                  id="subject_selector"
                  style={{
                    backgroundColor: colors.ossyBlue1,
                    // height: 20,
                    marginRight: 6,
                    appearance: "none",
                    padding: 8,
                    color: "white",
                    borderRadius: borderRadius,
                    border: "transparent",
                    WebkitTapHighlightColor: "transparent",
                    msScrollbarHighlighxtColor: "transparent",
                    fontSize: 12,
                    fontFamily: "poppins",
                  }}
                  onChange={(event) =>
                    setCustomVocab(event.currentTarget.value)
                  }
                >
                  {Object.keys(customVocabs).map((subject) => (
                    <option key={subject} value={subject}>
                      {subject}
                    </option>
                  ))}
                </select>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      borderRadius: 1000,
                      backgroundColor: darkMode ? colors.gray2 : colors.gray,
                      width: 24,
                      height: 24,
                      minWidth: 24,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      cursor: "pointer",
                      fontSize: 12,
                      marginRight: 8,
                      color: darkMode ? "white" : "black",
                    }}
                    onMouseEnter={(e) => {
                      setToolTip({ display: "block" });
                    }}
                    onMouseLeave={(e) => {
                      setToolTip({ display: "none" });
                    }}
                  >
                    i
                  </div>
                  <div style={toolTip}>
                    <p
                      style={{
                        fontSize: 12,
                        textAlign: "left",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Selecting a subject can increase the accuracy of
                      transcriptions.
                    </p>
                  </div>
                </div>
              </div>

              {/* profanity filter */}
              <div
                style={{
                  display: "flex",
                  fontSize: 12,
                  width: 200,
                  height: 30,
                  padding: 0,
                  alignItems: "center",
                }}
              >
                <p stye={{ margin: 0, padding: 0 }}>Filter Profanity</p>

                <Switch
                  style={{
                    marginLeft: 5,
                  }}
                  updateState={setFilterProfanity}
                />
              </div>

              {/* filter disfluencies */}
              <div
                style={{
                  display: "flex",
                  fontSize: 12,
                  // width: 200,
                  height: 30,
                  padding: 0,
                  alignItems: "center",
                }}
              >
                <p stye={{ margin: 0, padding: 0 }}>
                  Filter Disfluencies (umm, hmm, etc)
                </p>
                <Switch
                  style={{
                    marginLeft: 5,
                  }}
                  updateState={setFilterDisfluencies}
                />
              </div>

              {/* save audio */}
              <div
                style={{
                  display: "flex",
                  fontSize: 12,
                  width: 200,
                  height: 30,
                  padding: 0,
                  alignItems: "center",
                }}
              >
                <p stye={{ margin: 0, padding: 0 }}>Save Audio</p>
                <Switch
                  style={{
                    marginLeft: 5,
                  }}
                  updateState={setSaveAudio}
                />
              </div>
            </div>
          )}

          {transcriptMode === UPLOAD_AUDIO && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                height: 150,
                maxHeight: 150,
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center",
                justifySelf: "center",
              }}
            >
              {!audioFile && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      // padding: 6px 12px;
                      cursor: "pointer",
                      backgroundColor: darkMode ? "gray" : "lightgray",
                      borderRadius: borderRadius,
                      width: 100,
                      height: 100,
                      fontSize: 12,
                      padding: 8,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    onDrop={(e) => {
                      // stops the file from opening right away in a new tab
                      e.preventDefault();

                      // reset error message
                      setDisplayErrorMessage(false);
                      setIsValidFile(false);

                      [...e.dataTransfer.items].forEach((item, i) => {
                        if (item.kind === "file") {
                          const file = item.getAsFile();
                          if (checkIfValidFile(file)) {
                            setAudioFile(file);
                          }
                          return;
                        }
                      });
                    }}
                    onDragOver={(e) => {
                      // stops the file from opening right away in a new tab
                      e.preventDefault();
                    }}
                  >
                    {/* arrow */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          width: 0,
                          height: 0,
                          borderLeft: "20px solid transparent",
                          borderRight: "20px solid transparent",

                          borderBottom: `20px solid ${
                            darkMode ? "lightgray" : "gray"
                          }`,
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: darkMode ? "lightgray" : "gray",
                          width: 16,
                          height: 14,
                        }}
                      />
                      <div
                        style={{
                          backgroundColor: darkMode ? "lightgray" : "gray",
                          width: 40,
                          height: 6,
                          marginTop: 8,
                        }}
                      />
                    </div>
                    <input
                      style={{
                        marginBottom: 20,
                        fontSize: 16,
                        display: "none",
                      }}
                      type="file"
                      name="audio"
                      // accept="audio/*"
                      accept={acceptedAudioFormats
                        .map((format) => `audio/${format}`)
                        .join(", ")}
                      id="audio_input"
                      onChange={(event) => {
                        // reset error message
                        setDisplayErrorMessage(false);
                        setIsValidFile(false);

                        // determine if audio file meets size conditions
                        if (checkIfValidFile(event.target.files[0])) {
                          setAudioFile(event.target.files[0]);
                        }
                      }}
                    />
                  </label>
                  <p
                    style={{
                      fontSize: 12,
                      marginTop: 8,
                    }}
                  >
                    Drag + Drop or Click to Choose File
                  </p>
                </div>
              )}

              {audioFile && !displayErrorMessage && (
                <div
                  style={{
                    backgroundColor: darkMode ? "gray" : "lightgray",
                    borderRadius: borderRadius,
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: 14,
                    alignItems: "flex-start",
                    padding: 8,
                    lineHeight: 1.3,
                    alignSelf: "center",
                    width: "fit-content",
                  }}
                >
                  <FiXCircle
                    style={{
                      // position: "absolute",
                      left: 8,
                      top: 8,
                      width: 18,
                      height: 18,
                      cursor: "pointer",
                      marginBottom: 8,
                    }}
                    onClick={(e) => setAudioFile(null)}
                  />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img src={file} alt="file" style={{ width: 60 }} />
                    <div
                      style={{
                        marginLeft: 16,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        // justifyContent: "center",
                      }}
                    >
                      <p style={{ fontWeight: 600, fontSize: 14 }}>
                        {audioFile.name}
                      </p>
                      <p>{(audioFile.size / 1024 ** 2).toFixed(2)} mb</p>
                      <p>{audioFile.type}</p>
                    </div>
                  </div>
                </div>
              )}

              {displayErrorMessage && (
                <p
                  style={{ whiteSpace: "pre-line", fontSize: 12, color: "red" }}
                >
                  {errorMsg}
                </p>
              )}
            </div>
          )}

          <Button
            style={{
              alignSelf: "flex-end",
              borderRadius: 6,
              fontSize: 16,
              marginTop: 16,
              // justifySelf: "flex-end",
            }}
            content={
              transcriptMode === LIVE_TRANSCRIPTION
                ? "Start Transcribing"
                : "Upload Audio"
            }
            isClickable={transcriptMode === UPLOAD_AUDIO ? isValidFile : true}
            onClick={(event) => {
              if (!isLoggedIn) {
                event.preventDefault();

                if (transcriptMode === LIVE_TRANSCRIPTION) {
                  // make the mic start loading to indicate that transcription is about to start
                  setLoading(true);
                  setGuestUserAction(LIVE_TRANSCRIPT_ATTEMPT);
                } else if (transcriptMode === UPLOAD_AUDIO) {
                  setGuestUserAction(UPLOAD_TRANSCRIPT_ATTEMPT);
                }

                // close this settings panel
                setTranscriptionSettings(false);

                // make user log in before transcribing
                setLogInPanel(true);
              }
            }}
          />
        </form>
      </div>
    </>
  );
};

export default TranscriptSettingsPanel;
