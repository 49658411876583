import { colors, borderRadius } from "../utility/utility";
import { deleteFolder, massDeleteTranscripts } from "../transcriptSlice";
import { useDispatch, useSelector } from "react-redux";
import DarkOverlay from "./DarkOverlay";

const DeleteFolderWarningPanel = ({
  supabase,
  setDeleteFolderWarningPanel,
  setSelected,
  rightClickFolderIndex,
  setFolderSelected,
  selected,
}) => {
  const dispatch = useDispatch();
  const folders = useSelector((state) => state.routes.folders);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const transcripts = useSelector((state) => state.routes.transcripts);
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <DarkOverlay />

      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          backgroundColor: darkMode ? colors.gray1 : "white",
          top: "50%",
          left: "50%",
          borderRadius: borderRadius,
          padding: 16,
          textAlign: "center",
          maxWidth: "50%",
          maxHeight: "80%",
          overflowY: "auto",
          color: darkMode ? "white" : "black",
        }}
      >
        <p style={{ fontWeight: 500, fontSize: 16, marginBottom: 16 }}>
          Delete Folder?
        </p>
        <p
          style={{
            fontSize: 14,
            fontWeight: 300,
            marginBottom: 12,
            overflowWrap: "break-word",
          }}
        >{`Deleting '${folders[rightClickFolderIndex]}' will delete all the transcripts contained inside of it.`}</p>

        <button
          style={{
            width: "fit-content",
            backgroundColor: colors.ossyBlue1,
            border: 0,
            color: "white",
            padding: 8,
            borderRadius: 6,
            alignSelf: "flex-end",
            fontFamily: "poppins",
            marginRight: 10,
          }}
          onClick={() => setDeleteFolderWarningPanel(false)}
        >
          Cancel
        </button>
        <button
          style={{
            width: "fit-content",
            backgroundColor: "tomato",
            border: 0,
            color: "white",
            padding: 8,
            borderRadius: 6,
            alignSelf: "flex-end",
            fontFamily: "poppins",
          }}
          onClick={() => {
            if (selected === folders[rightClickFolderIndex]) {
              setSelected("transcriptions");
              setFolderSelected(false);
            }
            const folderToDelete = folders[rightClickFolderIndex];
            dispatch(
              deleteFolder({
                indexToRemove: rightClickFolderIndex,
                supabase: supabase,
              })
            );

            dispatch(
              massDeleteTranscripts(
                // get all the indices of the transcripts to delete
                {
                  indicesToDelete: transcripts
                    .map((transcript, index) => index)
                    .filter(
                      (index) =>
                        transcripts[index].transcriptFolder === folderToDelete
                    ),
                  supabase: supabase,
                }
              )
            );

            setDeleteFolderWarningPanel(false);
          }}
        >
          Delete Folder
        </button>
      </div>
    </div>
  );
};

export default DeleteFolderWarningPanel;
