import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  createNewFolderLocal,
  fetchAllTranscripts,
  fetchUserData,
} from "../transcriptSlice";
import { colors } from "../utility/utility";
import {
  SummaryPanel,
  Folder,
  Transcript,
  DarkOverlay,
  NewTranscriptButton,
  DeleteFolderWarningPanel,
  SelectFolderPanel,
  RightClickFolderPanel,
  RightClickTranscriptPanel,
  HomeHeader,
  UploadAudioPanel,
} from "../components";

const HomePage = ({ supabase }) => {
  const darkMode = useSelector((state) => state.routes.darkMode);
  const justLoggedOut = useSelector((state) => state.routes.justLoggedOut);
  const folders = useSelector((state) => state.routes.folders);
  const userId = useSelector((state) => state.routes.userId);
  const transcripts = useSelector((state) => state.routes.transcripts);
  const [showSummary, setShowSummary] = useState(false);
  const [deleteFolderWarningPanel, setDeleteFolderWarningPanel] =
    useState(false);
  const [rightClickedFolder, setRightClickedFolder] = useState(false);
  const [rightClickedTranscription, setRightClickedTranscription] =
    useState(false);
  const [rightClickFolderIndex, setRightClickFolderIndex] = useState(-1);
  const [rightClickTranscriptIndex, setRightClickTranscriptIndex] =
    useState(-1);
  const [selected, setSelected] = useState("transcriptions");
  const [points, setPoints] = useState({ x: 0, y: 0 });
  const [transcriptionTitleEditIndex, setTranscriptionTitleEditIndex] =
    useState(0);
  const [folderTitleEditIndex, setFolderTitleEditIndex] = useState(-1);
  const [editingTranscriptTitle, setEditingTranscriptTitle] = useState(false);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const [displayUploadAudioPanel, setDisplayUploadAudioPanel] = useState(false);
  const [folderSelected, setFolderSelected] = useState(false);
  const dispatch = useDispatch();
  const [smallWindow, setSmallWindow] = useState(
    window.matchMedia("(max-width: 575px)").matches
  );
  const [selectFolderPanel, setSelectFolderPanel] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [rightClickTranscriptUrl, setRightClickTranscriptUrl] = useState("");

  window.addEventListener("resize", () => {
    const newSmallWindow = window.matchMedia("(max-width: 575px)").matches;
    setSmallWindow(newSmallWindow);

    // if the screen expands, reset small menu to disappear
    if (!newSmallWindow && menuOpen) {
      setMenuOpen(false);
    }
  });

  // fetch user data
  useEffect(() => {
    if (isLoggedIn) {
      const fetchAllData = async () => {
        await dispatch(fetchUserData({ supabase })).unwrap();
        dispatch(fetchAllTranscripts({ supabase }));
      };
      fetchAllData();
    }
  }, [dispatch, userId, supabase, isLoggedIn]);

  useEffect(() => {
    // if the user isn't logged in,
    if (!isLoggedIn) {
      console.log("NO LONGER LOGGED IN");
      const protocol = window.location.protocol
        ? window.location.protocol + "//"
        : "";
      const host = window.location.host;

      // if they're trying to go to home screen (www.ossy.ai/home), make them log in
      // use .replace so it replaces the url history
      if (window.location.pathname === "/home") {
        window.location.replace(`${protocol}${host}/login`);
      } else if (!justLoggedOut) {
        // if they're just trying to go to www.ossy.ai, take them to the new transcript screen
        window.location.replace(`${protocol}${host}/t/new`);
      }
    }

    // convert all the latex to actual math when page first loads and when another folder is selected
    if (window.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  }, [isLoggedIn, dispatch, supabase, justLoggedOut]);

  return (
    // full screen
    <>
      {/* context menu for folders */}
      {rightClickedFolder && (
        <RightClickFolderPanel
          supabase={supabase}
          points={points}
          setRightClickedFolder={setRightClickedFolder}
          setFolderTitleEditIndex={setFolderTitleEditIndex}
          rightClickFolderIndex={rightClickFolderIndex}
          setDeleteFolderWarningPanel={setDeleteFolderWarningPanel}
          setRightClickedTranscription={setRightClickedTranscription}
          selected={selected}
          setSelected={setSelected}
          folderSelected={folderSelected}
          setFolderSelected={setFolderSelected}
        />
      )}

      {/* right click transcription */}
      {rightClickedTranscription && (
        <RightClickTranscriptPanel
          points={points}
          setRightClickedTranscription={setRightClickedTranscription}
          setTranscriptionTitleEditIndex={setTranscriptionTitleEditIndex}
          setEditingTranscriptTitle={setEditingTranscriptTitle}
          rightClickTranscriptIndex={rightClickTranscriptIndex}
          rightClickTranscriptUrl={rightClickTranscriptUrl}
          setShowSummary={setShowSummary}
          setSelectFolderPanel={setSelectFolderPanel}
          supabase={supabase}
        />
      )}

      {/* dark overlay when menu is open on small screens */}
      {menuOpen && smallWindow && (
        <DarkOverlay onClick={() => setMenuOpen(false)} opacity={0.5} />
      )}

      {/* summary panel for lectures */}
      {showSummary && (
        <SummaryPanel
          setShowSummary={setShowSummary}
          index={rightClickTranscriptIndex}
          supabase={supabase}
        />
      )}

      {/* the actual home page */}
      <div
        style={{
          flex: 1,
          // backgroundColor: darkMode ? "" : "white",
          backgroundColor: darkMode ? colors.black : "white",
          color: darkMode ? "white" : "black",
          height: "100dvh",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
          overflow: "hidden",
        }}
      >
        {/* header (top) */}
        <HomeHeader
          smallWindow={smallWindow}
          setMenuOpen={setMenuOpen}
          supabase={supabase}
        />

        {/* everything below the header */}
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            // overflow: "clip",
            overflowY: "clip",
          }}
        >
          {/* left panel */}
          {(!smallWindow || smallWindow) && (
            <div
              style={{
                backgroundColor: darkMode ? "#2f2f2f" : colors.gray,
                paddingTop: 32,
                paddingBottom: 32,
                paddingLeft: 16,
                paddingRight: 16,
                minWidth: 208,
                maxWidth: 208,
                display: "flex",
                flexDirection: "column",
                position: smallWindow ? "fixed" : "static",
                height: smallWindow ? "100%" : "auto",
                top: smallWindow ? 0 : "auto",

                // animating opening and closing of menu
                transform:
                  menuOpen || !smallWindow ? "none" : `translate(-250px)`,
                transition: smallWindow ? "transform 0.55s" : "",
              }}
            >
              <NewTranscriptButton
                supabase={supabase}
                setMenuOpen={setMenuOpen}
                smallWindow={smallWindow}
                folderSelected={folderSelected}
                selected={selected}
                setDisplayUploadAudioPanel={setDisplayUploadAudioPanel}
              />
              <div
                style={{
                  backgroundColor:
                    selected === "transcriptions"
                      ? darkMode
                        ? colors.gray1
                        : "lightgray"
                      : "transparent",
                  borderRadius: 6,
                  padding: 6,
                  cursor: "pointer",
                }}
                onMouseEnter={(event) => {
                  if (!folderSelected) {
                    return;
                  }
                  event.currentTarget.style.backgroundColor = darkMode
                    ? colors.gray15
                    : "gainsboro";
                }}
                onMouseLeave={(event) => {
                  if (!folderSelected) {
                    return;
                  }
                  event.currentTarget.style.backgroundColor = "transparent";
                }}
                onClick={() => {
                  if (smallWindow) {
                    setMenuOpen(false);
                  }
                  setSelected("transcriptions");
                  setFolderSelected(false);
                }}
              >
                <p
                  style={{
                    fontWeight: 400,
                    color: darkMode ? "white" : "gray",
                    fontSize: 16,
                  }}
                >
                  Transcriptions
                </p>
              </div>

              {/* folders */}
              <div
                style={{
                  marginTop: 24,
                  marginBottom: 24,
                  overflow: "clip",
                  flexGrow: 1,
                  overflowY: "clip",
                  height: "100",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* 'Folders' and new folder button */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: darkMode ? "white" : "gray",
                    marginBottom: 20,
                    padding: 6,
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: 400,
                      fontSize: 14,
                    }}
                  >
                    Folders
                  </p>

                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontWeight: 500,
                      width: 20,
                      height: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      color: darkMode ? "white" : "gray",
                      cursor: "pointer",
                      borderRadius: 10000,
                    }}
                    onClick={() => {
                      dispatch(createNewFolderLocal({}));
                    }}
                    onMouseEnter={(event) => {
                      event.currentTarget.style.backgroundColor = darkMode
                        ? colors.gray15
                        : "gainsboro";
                    }}
                    onMouseLeave={(event) => {
                      event.currentTarget.style.backgroundColor = "transparent";
                    }}
                  >
                    +
                  </p>
                </div>

                {/* folders in left panel */}
                <div
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    flexGrow: 1,
                    height: "0px",
                    boxSizing: "border-box",
                  }}
                >
                  {folders.map((folderName, index) => {
                    return (
                      <Folder
                        selected={selected}
                        folderName={folderName}
                        smallWindow={smallWindow}
                        setMenuOpen={setMenuOpen}
                        setSelected={setSelected}
                        setFolderSelected={setFolderSelected}
                        folderSelected={folderSelected}
                        folderTitleEditIndex={folderTitleEditIndex}
                        setRightClickedFolder={setRightClickedFolder}
                        setPoints={setPoints}
                        setRightClickFolderIndex={setRightClickFolderIndex}
                        setFolderTitleEditIndex={setFolderTitleEditIndex}
                        rightClickFolderIndex={rightClickFolderIndex}
                        userId={userId}
                        supabase={supabase}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {/* right side */}
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              fontSize: 24,
              paddingTop: 40,
              // display: "flex",
              flexDirection: "column",
              overflow: "clip",
              overflowY: "clip",
            }}
          >
            {/* title of what is being viewed ("All Transcriptions" or folder name) */}
            <div
              style={{
                paddingLeft: 40,
                paddingRight: 40,
              }}
            >
              <p
                style={{
                  paddingBottom: 20,
                  fontWeight: 500,
                  overflow: "hidden",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                {!folderSelected ? "All Transcriptions" : selected}
              </p>
            </div>
            <div
              style={{
                //   width: "100%",
                display: "inline-block",
                flexDirection: "column",
                paddingLeft: 40,
                paddingRight: 40,
                //   flex: 1,
                flexGrow: 1,
                overflowY: "auto",
                height: 0,
              }}
            >
              {transcripts.filter((transcript, index) => {
                // if there are any transcriptions in this folder to display, display them.
                // otherwise display "Transcriptions will appear here message"
                if (
                  (folderSelected &&
                    transcript.transcriptFolder === selected) ||
                  !folderSelected
                ) {
                  return true;
                }
                return false;
              }).length > 0 ? (
                transcripts.map(
                  (
                    {
                      transcriptTitle,
                      transcriptUrl,
                      transcriptPrivacy,
                      audioUrl,
                    },
                    index
                  ) => {
                    if (
                      (folderSelected &&
                        transcripts[index].transcriptFolder === selected) ||
                      !folderSelected
                    ) {
                      return (
                        <Transcript
                          editingTranscriptTitle={editingTranscriptTitle}
                          folderSelected={folderSelected}
                          key={index}
                          index={index}
                          setPoints={setPoints}
                          setRightClickedTranscription={
                            setRightClickedTranscription
                          }
                          setRightClickTranscriptIndex={
                            setRightClickTranscriptIndex
                          }
                          setEditingTranscriptTitle={setEditingTranscriptTitle}
                          transcriptionTitle={transcriptTitle}
                          transcriptionTitleEditIndex={
                            transcriptionTitleEditIndex
                          }
                          transcriptUrl={transcriptUrl}
                          transcriptPrivacy={transcriptPrivacy}
                          audioUrl={audioUrl}
                          setRightClickTranscriptUrl={
                            setRightClickTranscriptUrl
                          }
                          userId={userId}
                          supabase={supabase}
                        />
                      );
                    } else {
                      return null;
                    }
                  }
                )
              ) : (
                <p
                  style={{
                    color: darkMode ? "white" : "gray",
                    fontSize: 16,
                  }}
                >
                  {"Your Transcriptions will appear here."}
                </p>
              )}
            </div>
          </div>
        </div>

        {deleteFolderWarningPanel && (
          <DeleteFolderWarningPanel
            setDeleteFolderWarningPanel={setDeleteFolderWarningPanel}
            setSelected={setSelected}
            rightClickFolderIndex={rightClickFolderIndex}
            setFolderSelected={setFolderSelected}
            selected={selected}
            supabase={supabase}
          />
        )}

        {selectFolderPanel && (
          <SelectFolderPanel
            supabase={supabase}
            setSelectFolderPanel={setSelectFolderPanel}
            setSelected={setSelected}
            setFolderSelected={setFolderSelected}
            rightClickTranscriptIndex={rightClickTranscriptIndex}
          />
        )}

        {displayUploadAudioPanel && (
          <UploadAudioPanel
            setDisplayUploadAudioPanel={setDisplayUploadAudioPanel}
            supabase={supabase}
          />
        )}
      </div>
    </>
  );
};
export default HomePage;
