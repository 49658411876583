import { Button, DropDown, PlusSign } from "../components";
import { useState } from "react";

const NewTranscriptButton = ({
  smallWindow,
  setMenuOpen,
  folderSelected,
  selected,
  setDisplayUploadAudioPanel,
}) => {
  const [newTranscriptMenu, setNewTranscriptMenu] = useState(false);

  return (
    <>
      <Button
        style={{
          color: "white",
          padding: 6,
          fontSize: 16,
          borderRadius: 6,
          display: "flex",
          flexDirection: "row",
          backgroundColor: "dodgerblue",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "max-content",
          cursor: "pointer",
          marginBottom: 24,
        }}
        onClick={(event) => {
          if (smallWindow && setMenuOpen) {
            setMenuOpen(false);
          }
          window.location.pathname = "/t/new";
        }}
        content={
          <>
            {/* the plus sign */}
            <PlusSign color={"white"} />
            <p
              style={{
                color: "white",
                margin: 0,
                padding: 0,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              New
            </p>
          </>
        }
      />
    </>
  );
};

export default NewTranscriptButton;
