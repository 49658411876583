import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { colors, formatTimestamp } from "../../../utility/utility";
import {
  updateTranscriptArray,
  saveTranscriptEdits,
} from "../../../transcriptSlice";
import { setBlurbHeight } from "../../editmode/api/editApi";

const TranscriptText = ({
  index,
  editMode,
  supabase,
  text,
  GPT,
  listeningForSpeechIndicator = false,
}) => {
  const { bookmarks, timestamps, transcriptArray } = useSelector(
    (state) => state.routes.transcript
  );
  const darkMode = useSelector((state) => state.routes.darkMode);
  const prevTranscriptType = useSelector(
    (state) => state.routes.prevTranscriptType
  );
  const PARTIAL = useSelector((state) => state.routes.PARTIAL);

  let isEditable;
  let partialResult = false;

  if (editMode) {
    // last blurb is partial; user should not be able to edit it
    if (
      index === transcriptArray.length - 1 &&
      prevTranscriptType === PARTIAL
    ) {
      isEditable = false;
      partialResult = true;
    }

    // listening for speech indicator should not be editable
    else if (listeningForSpeechIndicator) {
      isEditable = false;
    }

    // otherwise we can edit the blurb
    else {
      isEditable = true;
    }
  }

  // not in edit mode, can't edit the blurb
  else {
    isEditable = false;
  }

  // make the box editable if it's editable
  let TranscriptionTextBox = isEditable ? "textarea" : "span";

  // dynamically set blurb height when a new blurb becomes editable
  useEffect(() => {
    if (!editMode) {
      return;
    }

    const blurb = document.getElementsByClassName(
      `transcript_array_box ${formatTimestamp(timestamps[index])}`
    )[0];
    setBlurbHeight(blurb);
  }, [TranscriptionTextBox, editMode, index, timestamps, partialResult]);

  const dispatch = useDispatch();

  return (
    <TranscriptionTextBox
      rows={1}
      style={{
        borderRadius: 6,
        WebkitBoxDecorationBreak: "clone",
        boxDecorationBreak: "clone",
        resize: "none",
        opacity: partialResult ? 0.5 : 1,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        borderTopRightRadius: 6,
        borderTopLeftRadius: 6,
        WebkitBorderBottomLeftRadius: 6,
        WebkitBorderBottomRightRadius: 6,
        color: darkMode ? "white" : "black",
        WebkitBorderTopLeftRadius: 6,
        WebkitBorderTopRightRadius: 6,
        textAlign: "left",
        padding: 7,
        lineHeight: 2.5,
        fontWeight: 400,
        margin: 0,
        fontFamily: "poppins",
        border: `1px solid ${isEditable ? "gray" : "transparent"}`,
        width: editMode ? "100%" : "fit-content",
        // backgroundColor: colors.gray,
        backgroundColor: darkMode
          ? colors.gray1Number(bookmarks[index] ? 0.8 : 1)
          : colors.grayNumber(bookmarks[index] ? 0.8 : 1),
        fontSize: 16,
        cursor: editMode || listeningForSpeechIndicator ? "normal" : "pointer",
      }}
      onMouseEnter={(event) => {
        // cannot click to jump explanations when in edit more or when listening for speech
        if (editMode || listeningForSpeechIndicator) {
          return;
        }
        event.currentTarget.style.background = darkMode
          ? colors.gray15
          : "lightgray";
      }}
      onMouseLeave={(event) => {
        // cannot click to jump explanations when in edit more or when listening for speech
        if (editMode || listeningForSpeechIndicator) {
          return;
        }
        event.currentTarget.style.backgroundColor = darkMode
          ? colors.gray1Number(bookmarks[index] ? 0.8 : 1)
          : colors.grayNumber(bookmarks[index] ? 0.8 : 1);
      }}
      onChange={(e) => {
        setBlurbHeight(e.currentTarget);

        dispatch(
          updateTranscriptArray({
            index: index,
            newValue: e.target.value,
          })
        );
      }}
      onBlur={(e) => {
        // post to the backend with edited transcript
        dispatch(saveTranscriptEdits(supabase));
      }}
      // this is necessary to find this element and
      // later map it to the corresponding summary blurb
      // also to find the elements for dynamic resizing in edit mode
      className={`transcript_array_box ${formatTimestamp(timestamps[index])}`}
      // id={formatTimestamp(timestamps[index])}
      onClick={(event) => {
        if (editMode) {
          return;
        }

        // audio player might not be on the page if the
        // user did not opt to save the audio
        const audio = document.getElementById("audio");
        if (audio) {
          audio.currentTime = Number(timestamps[index]);
          // const playPromise = audio.play();
          // if (playPromise !== undefined) {
          //   playPromise
          //     .then(() => {
          //       // this has started playing the audio
          //     })
          //     .catch(() => {
          //       // failed for some reason
          //     });
          // }
        }

        if (GPT) {
          const summary_i = event.currentTarget.id;
          console.log(`trying to find summary_${summary_i}`);
          const summaryBlurb = document.getElementById(`summary_${summary_i}`);
          if (summaryBlurb) {
            summaryBlurb.scrollIntoView({ behavior: "smooth" });
          } else {
            console.log("coulnd't find it");
          }
        }
      }}
      defaultValue={text}
      onKeyDown={(event) => {
        if (["Enter", "Escape"].includes(event.key)) {
          event.currentTarget.blur();
        }
      }}
    >
      {TranscriptionTextBox === "span" ? text : null}
    </TranscriptionTextBox>
  );
};

export default TranscriptText;
