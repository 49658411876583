import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import { createClient } from "@supabase/supabase-js";
import {
  LogInPage,
  SettingsPage,
  TranscriptRouter,
  NotFoundPage,
} from "./pages";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const response = await supabase.auth.signInWithPassword({
  email: process.env.REACT_APP_SUPABASE_EMAIL,
  password: process.env.REACT_APP_SUPABASE_PASSWORD,
});

if (response.error) {
  console.log("couldn't sign into supabase");
}

const router = createBrowserRouter([
  { path: "/", element: <HomePage supabase={supabase} /> },
  { path: "/home", element: <HomePage supabase={supabase} /> },
  {
    path: "/t/:transcriptUrl",
    // errorElement: <ErrorPage />,
    element: <TranscriptRouter supabase={supabase} />,
    loader: ({ params: { transcriptUrl } }) => {
      return transcriptUrl;
    },
  },
  { path: "/t/", element: <Navigate to="/" replace /> },
  { path: "/login", element: <LogInPage supabase={supabase} /> },
  { path: "/settings", element: <SettingsPage supabase={supabase} /> },
  { path: "*", element: <NotFoundPage /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <RouterProvider router={router} />
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
