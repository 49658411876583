import DropDown from "./DropDown";
import { useSelector, useDispatch } from "react-redux";
import { deleteTranscript, removeFromFolder } from "../transcriptSlice";

import { fetchSummary } from "../features/summary/api/summaryApi";

const RightClickTranscriptPanel = ({
  points,
  setRightClickedTranscription,
  setTranscriptionTitleEditIndex,
  setEditingTranscriptTitle,
  rightClickTranscriptIndex,
  rightClickTranscriptUrl,
  setShowSummary,
  setSelectFolderPanel,
  supabase,
}) => {
  const transcripts = useSelector((state) => state.routes.transcripts);
  const dispatch = useDispatch();

  return (
    <DropDown
      overlay={true}
      top={points.y + 1}
      left={points.x + 1}
      func={() => setRightClickedTranscription(false)}
      links={[
        {
          name: "Rename Transcription",
          onClick: () => {
            setRightClickedTranscription(false);
            setTranscriptionTitleEditIndex(rightClickTranscriptIndex);
            setEditingTranscriptTitle(true);
          },
        },
        {
          name: "Summarize",
          onClick: () => {
            setShowSummary(true);
            setRightClickedTranscription(false);
            if (!transcripts[rightClickTranscriptIndex].summary) {
              fetchSummary(
                supabase,
                dispatch,
                transcripts[rightClickTranscriptIndex].transcriptArray.join(
                  " "
                ),
                rightClickTranscriptIndex
              );
            }
          },
          disabled:
            transcripts[rightClickTranscriptIndex].transcriptArray.length === 0,
        },
        {
          name: "Add to Folder",
          onClick: () => {
            setRightClickedTranscription(false);
            setSelectFolderPanel(true);
          },
          disabled:
            transcripts[rightClickTranscriptIndex].transcriptFolder !== "",
        },
        {
          name: `Remove from ${
            transcripts[rightClickTranscriptIndex].transcriptFolder
              ? `'${transcripts[
                  rightClickTranscriptIndex
                ].transcriptFolder.substring(0, 12)}'`
              : "Folder"
          }`,
          onClick: () => {
            dispatch(
              removeFromFolder({
                supabase: supabase,
                transcriptIndex: rightClickTranscriptIndex,
              })
            );
            setRightClickedTranscription(false);
          },
          disabled:
            transcripts[rightClickTranscriptIndex].transcriptFolder === "",
        },

        {
          name: "Copy Link",
          onClick: async () => {
            const protocol = window.location.protocol
              ? window.location.protocol + "//"
              : "";
            const host = window.location.host;
            await navigator.clipboard.writeText(
              `${protocol}${host}/t/${rightClickTranscriptUrl}`
            );
          },
        },
        {
          name: "Delete Transcription",
          onClick: () => {
            //         <DeleteTranscriptionWarningPanel
            //   setDeleteFolderWarningPanel={setDeleteFolderWarningPanel}
            //   setSelected={setSelected}
            //   rightClickFolderIndex={rightClickFolderIndex}
            //   setFolderSelected={setFolderSelected}
            //   selected={selected}
            // />
            dispatch(
              deleteTranscript({
                supabase: supabase,
                index: rightClickTranscriptIndex,
              })
            );
            setRightClickedTranscription(false);
          },
        },
      ]}
    />
  );
};

export default RightClickTranscriptPanel;
