import { useSelector } from "react-redux";
import { colors, borderRadius } from "../utility/utility";
import loadingSymbol from "../assets/loading.gif";

const Button = ({
  onClick,
  content,
  style,
  loading,
  id,
  bgColor: colorString,
  isClickable = true,
}) => {
  let bgColor = colors.ossyBlue1;
  if (colorString === "green") {
    bgColor = colors.green;
  } else if (["gray", "lightgray"].includes(colorString)) {
    bgColor = colorString;
  }

  return (
    <button
      style={{
        color: colorString === "lightgray" ? "gray" : "white",
        fontSize: 12,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "max-content",
        fontFamily: "poppins",
        padding: 8,
        backgroundColor: isClickable ? bgColor : colors.ossyBlue2,
        borderRadius: borderRadius,
        fontWeight: 600,
        cursor: loading || !isClickable ? "default" : "pointer",
        ...style,
      }}
      id={id}
      onMouseEnter={(event) => {
        if (loading) {
          return;
        }
        if (isClickable && !loading) {
          event.currentTarget.style.backgroundColor = colors.ossyBlue2;
        }
      }}
      onMouseLeave={(event) => {
        if (isClickable) {
          event.currentTarget.style.backgroundColor = bgColor;
          event.currentTarget.style.color =
            colorString === "lightgray" ? "gray" : "white";
        }
      }}
      onClick={
        !loading && isClickable
          ? onClick
          : (e) => {
              e.preventDefault();
            }
      }
      disabled={loading}
    >
      {loading ? (
        <img
          src={loadingSymbol}
          style={{ width: 20, height: 20 }}
          alt="Loading"
        />
      ) : (
        content
      )}
    </button>
  );
};

export default Button;
