import { updateSummaryLocal, updateSummary } from "../../../transcriptSlice";

export const fetchSummary = async (
  supabase,
  dispatch,
  text,
  index = undefined
) => {
  console.log(`here is the text to summarize: ${text}`);
  try {
    fetch(
      `https://ossy-backend-5sljdp5hja-uk.a.run.app/summarize`,
      // `http://localhost:5000/summarize`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/x-www-form-urlencoded",
        },
        body: `text=${text}`,
      }
    ).then(async (response) => {
      let summaryText = "";
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          async function pump() {
            return reader.read().then(({ done, value }) => {
              console.log(`are we fetching a summary?`);
              summaryText += new TextDecoder().decode(value);
              dispatch(
                updateSummaryLocal({
                  newSummary: summaryText,
                  index: index,
                })
              );
              // When no more data needs to be consumed, close the stream
              if (done) {
                dispatch(
                  updateSummary({
                    supabase: supabase,
                    newSummary: summaryText,
                  })
                );
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    });
  } catch (error) {
    console.log("cancelled fetch");
  }
};
