import { configureStore } from "@reduxjs/toolkit/dist";
import routesReducer from "./transcriptSlice";
import { initialState } from "./data/initialState";
import throttle from "lodash/throttle";

const saveState = (state) => {
  try {
    // Convert the state to a JSON string
    const serialisedState = JSON.stringify(state);

    // Save the serialised state to localStorage against the key 'app_state'
    window.localStorage.setItem("app_state", serialisedState);
  } catch (err) {
    console.log("could not save state");
  }
};

// check if app state is saved in localStorage
const loadState = () => {
  // wrapped in a try in case the user's privacy settings don't allow accessing state
  try {
    // Load the data saved in localStorage, against the key 'app_state'
    const serialisedState = window.localStorage.getItem("app_state");

    // If no data is saved, return undefined
    if (serialisedState === null) {
      return undefined;
    }

    const jsonState = JSON.parse(serialisedState);
    const finalState = { routes: { ...initialState, ...jsonState.routes } };

    // De-serialise the saved state, and return it.
    return finalState;
  } catch (err) {
    console.log("could not find state");
    // Return undefined if localStorage is not available,
    // or data could not be de-serialised,
    // or there was some other error
    return undefined;
  }
};

const oldState = loadState();

export const store = configureStore({
  reducer: {
    routes: routesReducer,
  },
  preloadedState: oldState,
});

// Add a change listener to the store and invoke saveState function
store.subscribe(
  // save state a maximum of once every 1000 ms
  throttle(() => {
    const state = store.getState();
    saveState({
      routes: {
        transcripts: state.routes.transcripts || initialState.transcripts,
        folders: state.routes.folders || initialState.folders,
        isLoggedIn: state.routes.isLoggedIn || initialState.isLoggedIn,
        userId: state.routes.userId || initialState.userId,
        fullName: state.routes.fullName || initialState.fullName,
        pfpUrl: state.routes.pfpUrl || initialState.pfpUrl,
        darkMode: state.routes.darkMode || initialState.darkMode,
        emailPreferences:
          state.routes.emailPreferences || initialState.emailPreferences,
        paidPlan: state.routes.paidPlan || initialState.paidPlan,
      },
    });
  }),
  1000
);
