import {
  colors,
  borderRadius,
  formatTimestamp,
} from "../../../utility/utility";
import { scrollToBookmark } from "../../../transcriptSlice";
import { useSelector, useDispatch } from "react-redux";

const BookmarkBar = ({ setAutoscroll }) => {
  const { timestamps, bookmarks } = useSelector(
    (state) => state.routes.transcript
  );
  const darkMode = useSelector((state) => state.routes.darkMode);
  const dispatch = useDispatch();

  return (
    <div
      style={{
        backgroundColor: darkMode ? colors.gray1 : "lightgray",
        borderRadius: 4,
        position: "relative",
        width: 6,
        marginTop: 20,
        marginBottom: 20,
        display: "flex",
        zIndex: 0,
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 0,
        alignItems: "center",
      }}
      className="bookmark_scrollbar"
    >
      {/* render the points on the bookmark scrollbar */}
      {timestamps.map((_, index) => {
        return (
          <div
            style={{
              position: "relative",
              width: 0,
              height: 0,
              padding: 0,
              borderRadius: borderRadius,
              cursor: bookmarks[index] ? "pointer" : "auto",
            }}
            key={index}
            className={`bookmark_dot ${formatTimestamp(timestamps[index])}`}
          >
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: bookmarks[index]
                  ? colors.ossyBlue1
                  : "transparent",
                borderRadius: 1000,
                position: "absolute",
                display: bookmarks[index] ? "flex" : "none",
                top: -6,
                left: -6,
                transform: "scale(1)",
                cursor: bookmarks[index] ? "pointer" : "auto",

                // smooth animate scaling and shrinking
                transition: "transform 0.2s",
              }}
              onMouseEnter={(event) => {
                event.currentTarget.style.transform = "scale(2)";
              }}
              onMouseLeave={(event) => {
                event.currentTarget.style.transform = "scale(1)";
              }}
              onClick={() => {
                // toggles the timestamp bookmark and updates the UI
                console.log("just set autoscroll to false");
                setAutoscroll(false);
                dispatch(
                  scrollToBookmark({
                    index: formatTimestamp(timestamps[index]),
                  })
                );
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BookmarkBar;
