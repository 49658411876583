import { useSelector, useDispatch } from "react-redux";
import {
  colors,
  borderRadius,
  formatTimestamp,
  fontSizes,
} from "../../../utility/utility";
import { toggleBookmark } from "../../../transcriptSlice";
import ToolTip from "../../../components/ToolTip";

const Timestamp = ({ index, supabase, listeningForSpeechIndicator }) => {
  const { bookmarks, timestamps } = useSelector(
    (state) => state.routes.transcript
  );

  const dispatch = useDispatch();

  return (
    <div
      style={{
        borderRadius: borderRadius,
        paddingLeft: 4,
        paddingRight: 4,
        marginRight: 4,
        marginTop: 10,
        position: "relative",
        cursor: "pointer",
        fontSize: fontSizes.regular,
        color: bookmarks[index] ? "white" : "gray",
      }}
      className={`timestamp_box ${formatTimestamp(timestamps[index])}`}
      onMouseEnter={(event) => {
        if (!bookmarks[index]) {
          event.currentTarget.style.backgroundColor = colors.ossyBlue2;
          event.currentTarget.style.color = "white";
        } else {
          event.currentTarget.style.backgroundColor = colors.ossyBlue2;
          event.currentTarget.style.color = "white";
        }
      }}
      onMouseLeave={(event) => {
        event.currentTarget.style.backgroundColor = "transparent";
        event.currentTarget.style.color = bookmarks[index] ? "white" : "gray";
      }}
      onClick={() => {
        if (listeningForSpeechIndicator) {
          return;
        }

        // toggles the timestamp bookmark and updates the UI
        dispatch(
          toggleBookmark({
            index: index,
            supabase: supabase,
          })
        );
      }}
    >
      {!listeningForSpeechIndicator && <ToolTip text={"Bookmark Timestamp"} />}
      {formatTimestamp(timestamps[index])}
    </div>
  );
};

export default Timestamp;
