import { useEffect, useRef } from "react";
import { colors, borderRadius, fontSizes } from "../../../utility/utility";
import { useSelector } from "react-redux";
import { setBlurbHeight } from "../../editmode/api/editApi";
import { TranscriptBlurb, BookmarkBar } from "../../../components";

const TranscriptPanel = ({
  autoscroll,
  editMode,
  GPT,
  gptPanelCutoff,
  scrollPosition,
  setAutoscroll,
  started,
  supabase,
  trptRef,
  setEditMode,
}) => {
  const transcriptPanelRef = useRef(null);
  const useDetectClickOutside = (ref) => {
    useEffect(() => {
      /**
       * detect click outside of transcript panel to leave edit mode,
       * unless you just clicked on the edit mode pencil again
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          document.activeElement.className !== "editModeButton"
        ) {
          setEditMode(false);
        }
      }
      // Bind the event listener. we want to know when they are done clicking on the item, so on mouse up
      document.addEventListener("mouseup", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mouseup", handleClickOutside);
      };
    }, [ref]);
  };

  useDetectClickOutside(transcriptPanelRef);

  const darkMode = useSelector((state) => state.routes.darkMode);
  const transcribingFile = useSelector(
    (state) => state.routes.transcribingFile
  );
  const { transcriptArray } = useSelector((state) => state.routes.transcript);

  // smooth autoscrolling if autoscroll is true
  useEffect(() => {
    // don't autoscroll in edit mode
    if (editMode) {
      return;
    }

    const textbox = document.getElementById("transcribed_textbox");
    if (textbox !== null && !editMode && autoscroll) {
      textbox.scrollTop = textbox.scrollHeight;
      textbox.scrollTo({
        top: textbox.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [transcriptArray, autoscroll, editMode]);

  // dynamically set blurb heights when we enter edit mode
  useEffect(() => {
    // no need to dynamically resize just yet
    if (!editMode) {
      return;
    }

    const textbox = document.getElementsByClassName("transcribed_textbox")[0];
    const boxes = document.getElementsByClassName("transcript_array_box");

    for (let i = 0; i < boxes.length; i++) {
      setBlurbHeight(boxes[i]);
    }

    // edit mode height is now the same as before; scroll to same point
    textbox.scrollTo({ top: scrollPosition.current });
  }, [editMode, scrollPosition]);

  return (
    <>
      {/* bookmark scrollbar body element */}
      <BookmarkBar setAutoscroll={setAutoscroll} />

      <div
        ref={transcriptPanelRef}
        style={{
          flex: 1,
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: darkMode ? colors.gray2 : colors.lightGray,
          borderRadius: borderRadius,
          margin: 20,
          marginLeft: 14,
          // if gpt panel is open, 50%. else, 1000 pixels
          maxWidth:
            GPT && !gptPanelCutoff && (started || transcriptArray.length)
              ? "50%"
              : 1000,
        }}
      >
        {/* transcribed text */}
        <div
          style={{
            margin: 20,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 20,
            paddingBottom: 0,
            flex: 1,
            width: "100%",
            maxWidth: 1000,
            // overflow: "scroll",
            overflowY: "auto",
            // overflowX: "hidden",
            scrollbarWidth: "thin",
            textAlign: "center",
            fontSize: 20,
            fontFamily: "poppins",
            border: 0,
            resize: "none",
            fontWeight: 700,
            lineHeight: 2.4,
            padding: 0,
            // backgroundColor: "red",
            boxSizing: "border-box",
          }}
          ref={trptRef}
          className="transcribed_textbox"
          id="transcribed_textbox"
          onScroll={(event) => {
            const textbox = event.currentTarget;
            // determine whether or not to continue autoscrolling
            setAutoscroll(
              Math.abs(
                textbox.scrollTop + textbox.offsetHeight - textbox.scrollHeight
              ) <= 20
            );
          }}
        >
          {/* only render children if we are not in edit mode */}

          {/* display transcription blurb if it exists or if recording started, 
  otherwise 'Transcribed text will appear here.' */}
          {transcriptArray.length > 0 || started || transcribingFile ? (
            <div
              style={{
                // overflow: "scroll",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                color: "white",
                justifyContent: "center",
                alignText: "center",

                boxSizing: "border-box",
                // padding: 1,
              }}
            >
              {transcriptArray.length === 0 && (
                <TranscriptBlurb
                  editMode={editMode}
                  GPT={GPT}
                  index={0}
                  started={started}
                  supabase={supabase}
                  text={
                    transcribingFile
                      ? "Transcribing your audio file..."
                      : "Listening for speech..."
                  }
                  key={1000}
                  opacity={0.5}
                />
              )}

              {transcriptArray.map((text, index) => {
                return (
                  <div>
                    <TranscriptBlurb
                      editMode={editMode}
                      GPT={GPT}
                      index={index}
                      started={started}
                      supabase={supabase}
                      text={text}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <span
              style={{
                color: "gray",
                padding: 7,
                fontWeight: 600,
                margin: 20,
                opacity: 0.5,
                fontSize: fontSizes.big,
              }}
            >
              Transcribed text will appear here.
            </span>
          )}
        </div>
      </div>
    </>
  );
};
export default TranscriptPanel;
