import { BsFillFolderFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { deleteFolder, updateFolderTitle } from "../transcriptSlice";
import { colors } from "../utility/utility";
import { addToFolder } from "../transcriptSlice";
import { useState } from "react";

const Folder = ({
  selected,
  folderName,
  smallWindow,
  setMenuOpen,
  index,
  folderTitleEditIndex,
  setSelected,
  setFolderSelected,
  folderSelected,
  setRightClickedFolder,
  setPoints,
  setRightClickFolderIndex,
  setFolderTitleEditIndex,
  rightClickFolderIndex,
  supabase,
}) => {
  const folders = useSelector((state) => state.routes.folders);
  const darkMode = useSelector((state) => state.routes.darkMode);
  const textHoverColor = darkMode ? colors.gray15 : "gainsboro";
  const bgColor = darkMode ? colors.gray1 : "lightgray";

  const dispatch = useDispatch();

  const FolderTitle =
    folderName === "" || folderTitleEditIndex === index ? "input" : "p";

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const transcriptIndex = event.dataTransfer.getData("text/plain");
    dispatch(
      addToFolder({
        supabase: supabase,
        transcriptIndex: transcriptIndex,
        folderName: folderName,
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20,
        padding: 6,
        backgroundColor: selected === folderName ? bgColor : "transparent",
        borderRadius: 6,
        cursor: "pointer",
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={(e) => {
        e.currentTarget.style.backgroundColor = bgColor;
      }}
      onDragLeave={(e) => {
        e.currentTarget.style.backgroundColor = "transparent";
        console.log("exited");
      }}
      onClick={() => {
        if (smallWindow) {
          setMenuOpen(false);
        }
        // not currently editing the title
        if (FolderTitle !== "input") {
          setSelected(folderName);
          setFolderSelected(true);
          console.log("folder just selected");
        }
        return;
      }}
      onMouseEnter={(event) => {
        if (folderSelected && selected === folderName) {
          return;
        }
        event.currentTarget.style.backgroundColor = textHoverColor;
      }}
      onMouseLeave={(event) => {
        if (folderSelected && selected === folderName) {
          return;
        }
        event.currentTarget.style.backgroundColor = "transparent";
      }}
      value={index}
      id={index}
      // right click menu
      onContextMenu={(event) => {
        event.preventDefault();
        setRightClickedFolder(true);
        console.log(
          `YOU JUST CLICKED ON A FOLDER: ${event.pageX}, ${event.pageY}`
        );
        setPoints({ x: event.pageX, y: event.pageY });
        setRightClickFolderIndex(Number(event.currentTarget.id));
      }}
    >
      {/* folder icon + name */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          overflow: "clip",
          pointerEvents: "none",
        }}
      >
        <BsFillFolderFill
          style={{
            marginRight: 8,
            // width: 20,
            // height: "100%",
            minWidth: 20,
            // minHeight: "100%",
          }}
          color={darkMode ? "white" : "gray"}
        />

        <FolderTitle
          autoFocus={true}
          style={{
            border: "none",
            fontFamily: "poppins",
            margin: 0,
            // width: "100%",
            backgroundColor: "transparent",
            whiteSpace: "nowrap",
            overflow: "clip",
            textOverflow: "ellipsis",
            flexGrow: 1,

            color: darkMode ? "white" : "gray",
            padding: 0,
            fontSize: 16,
          }}
          defaultValue={folderName}
          onKeyDown={(event) => {
            if (["Enter", "Escape"].includes(event.key)) {
              event.currentTarget.blur();
            }
          }}
          // update folder title
          onBlur={(event) => {
            event.preventDefault();
            setFolderTitleEditIndex(-1);
            // if another folder already has this name, block it
            if (
              ![rightClickFolderIndex, -1].includes(
                folders.indexOf(event.currentTarget.value)
              ) &&
              event.currentTarget.value !== ""
            ) {
              // delete the folder if the user just made it
              if (["", undefined].includes(folders[rightClickFolderIndex])) {
                console.log("here?");
                dispatch(deleteFolder({ index: index }));
              }

              alert(
                `Sorry, but it seems that a folder with the name '${event.currentTarget.value}' already exists. Please choose a different name or use the existing folder. `
              );

              return;
            }

            // update folder name if non-empty
            if (event.currentTarget.value !== "") {
              if (folderSelected && selected === folders[index]) {
                setSelected(event.currentTarget.value);
              }
              dispatch(
                updateFolderTitle({
                  supabase: supabase,
                  indexToChange: index,
                  newFolderTitle: event.currentTarget.value,
                })
              );

              // if empty but previously wasn't, return to previous
            } else if (
              event.currentTarget.value === "" &&
              !["", undefined].includes(folders[rightClickFolderIndex])
            ) {
              return;

              // if empty and just created, delete it
            } else if (event.currentTarget.value === "") {
              dispatch(deleteFolder({ indexToRemove: index }));
            }
          }}
        >
          {FolderTitle === "p" ? folderName : null}
        </FolderTitle>
      </div>

      {/* folder dropdown to show contents */}
      {/* <RiArrowDropDownLine
    color="gray"
    size={20}
    style={{
      cursor: "pointer",
      margin: -5,
      padding: 0,
    }}
  /> */}
      {/* <p style={{ margin: 0, padding: 0, color: "gray" }}>^</p> */}
    </div>
  );
};
export default Folder;
