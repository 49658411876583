import {
  micInactive,
  download,
  pencil,
  loadingSymbol,
  pencilWhite,
  downloadWhite,
} from "../../../assets/index";
import { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DropDown from "../../../components/DropDown";
import { colors, formatTimestamp, boxShadow } from "../../../utility/utility";
import { ToolTip } from "../../../components";
import { fetchSummary } from "../../summary/api/summaryApi";

const ControlPanel = ({
  started,
  loading,
  paused,
  resumeTranscribing,
  pauseTranscribing,
  GPT,
  setGPT,
  editMode,
  setEditMode,
  stopTranscribing,
  setTranscriptionSettings,
  setShowSummary,
  supabase,
  scrollPosition,
  ownerOfTranscript,
  setDownloadOptionsVisible,
  downloadOptionsVisible,
}) => {
  const {
    transcriptArray,
    audioUrl,
    transcriptTitle,
    gptJson,
    summary,
    timestamps,
  } = useSelector((state) => state.routes.transcript);
  const isLoggedIn = useSelector((state) => state.routes.isLoggedIn);
  const completedTranscription = !started && transcriptArray.length !== 0;
  const darkMode = useSelector((state) => state.routes.darkMode);
  const backgroundColor = darkMode ? colors.gray1 : "white";

  const dispatch = useDispatch();
  const transcribingFile = useSelector(
    (state) => state.routes.transcribingFile
  );

  const selectedColor = darkMode ? colors.black : "gray";
  const unselectedColor = darkMode ? "gray" : colors.gray;
  const hoverColor = darkMode ? colors.gray15 : "lightgray";
  const textColor = darkMode ? "white" : "black";
  const newBoxShadow = darkMode ? "none" : boxShadow;

  const completedTranscriptionStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    bottom: 50, // 30,/
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 12,
    boxShadow: newBoxShadow,
    paddingRight: 6,
    paddingLeft: 6,
    paddingTop: 5,
    paddingBottom: 5,
    flexDirection: "column",
  };

  const unstartedControlPanelStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    height: 50,
    bottom: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 1000,
    boxShadow: newBoxShadow,
    paddingRight: started ? 10 : 5,
  };

  const activeControlPanelStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    height: 50,
    bottom: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 1000,
    boxShadow: newBoxShadow,
    paddingRight: started ? 10 : 5,
    flexDirection: "column",
  };

  const downloadOptionsRef = useRef(null);

  const useDetectClickOutside = (ref, func) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          func();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, func]);
  };

  useDetectClickOutside(downloadOptionsRef, () =>
    setDownloadOptionsVisible(false)
  );

  const UNSTARTED = 100;
  const COMPLETED_OR_UPLOADING_AUDIO = 101;
  const COMPLETED_OR_UPLOADING_NO_AUDIO_URL = 102;
  const CURRENT_LIVE_TRANSCRIPT = 103;
  let state = 0;

  let styles = {};
  if (
    // unstarted live transcription
    !started &&
    transcriptArray.length === 0 &&
    !transcribingFile
  ) {
    state = UNSTARTED;
    console.log("unstarted");
    styles = unstartedControlPanelStyle;

    // audio file upload or completed live trancription
  } else if ((!started && transcriptArray.length !== 0) || transcribingFile) {
    if (audioUrl !== "") {
      state = COMPLETED_OR_UPLOADING_AUDIO;
      styles = completedTranscriptionStyle;
      console.log("completed");
    } else {
      state = COMPLETED_OR_UPLOADING_AUDIO;
      styles = unstartedControlPanelStyle;
    }

    // currently in live transcription
  } else if (started) {
    state = CURRENT_LIVE_TRANSCRIPT;
    styles = activeControlPanelStyle;
    console.log("still live");
  }

  return (
    <div
      style={{
        ...styles,
        maxWidth: "80dvw",
        minWidth: "fit-content",
      }}
    >
      {/* the audio seekbar */}
      {state === COMPLETED_OR_UPLOADING_AUDIO && audioUrl !== "" && (
        <>
          <audio
            controls
            id="audio"
            onSeeking={(event) => console.log(event.currentTarget.currentTime)}
            style={{
              height: 40,
              borderRadius: 60,
              width: 350,
              maxWidth: "75dvw",
              marginBottom: 6,
              backgroundColor: selectedColor,
            }}
            src={audioUrl}
            // preload="none"
          >
            <source src={audioUrl} type="audio/mpeg" />
            <source src={audioUrl} type="audio/ogg" />
            <source src={audioUrl} type="audio/wav" />
          </audio>
          <div
            style={{
              border: `0.000001px solid ${
                darkMode ? colors.black : "lightgray"
              }`,
              width: "100%",
              marginBottom: 6,
            }}
          />
        </>
      )}

      {/* the bottom half of the bottom bar */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* begin transcription */}
        {state === UNSTARTED && (
          <button
            disabled={loading}
            onClick={async (event) => {
              setTranscriptionSettings(event);
            }}
            style={{
              height: 40,
              width: 40,
              display: "flex",
              flexDirection: "row",
              marginLeft: started ? 10 : 5,
              border: "none",
              cursor: "pointer",
              borderRadius: 60,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: darkMode ? "gray" : colors.gray,
              // backgroundColor: "mediumseagreen",
              color: "white",
              fontSize: 12,
              position: "relative",
            }}
            onMouseEnter={(event) => {
              event.currentTarget.style.backgroundColor = hoverColor;
            }}
            onMouseLeave={(event) => {
              event.currentTarget.style.backgroundColor = darkMode
                ? "gray"
                : colors.gray;
            }}
          >
            <ToolTip text={"Start Transcription"} />
            <img
              src={loading ? loadingSymbol : micInactive}
              style={{ width: 20, height: 20 }}
              alt="Logo"
            />
          </button>
        )}

        {/* pause/resume */}
        {state === CURRENT_LIVE_TRANSCRIPT && (
          <button
            disabled={loading}
            onClick={paused ? resumeTranscribing : pauseTranscribing}
            style={{
              height: 40,
              // width: 40,
              display: "flex",
              flexDirection: "row",
              marginLeft: 10,
              fontWeight: "700",
              paddingLeft: 10,
              paddingRight: 10,
              border: "none",
              cursor: "pointer",
              borderRadius: 60,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: darkMode ? "gray" : colors.gray,
              color: darkMode ? "white" : "gray",
              fontSize: 12,
              position: "relative",
            }}
            onMouseEnter={(event) => {
              event.currentTarget.style.backgroundColor = hoverColor;
            }}
            onMouseLeave={(event) => {
              event.currentTarget.style.backgroundColor = darkMode
                ? "gray"
                : colors.gray;
            }}
          >
            <ToolTip text={paused ? "Resume" : "Pause"} />
            {loading ? (
              <img
                src={loadingSymbol}
                style={{ width: 20, height: 20 }}
                alt="Logo"
              />
            ) : paused ? (
              <p style={{ padding: 8 }}>Resume</p>
            ) : (
              <div
                style={{
                  width: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: 14.5,
                    height: 16,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      backgroundColor: darkMode ? "white" : "gray",
                      width: 6,
                      borderRadius: 4,
                    }}
                  />
                  <div
                    style={{
                      height: "100%",
                      backgroundColor: darkMode ? "white" : "gray",
                      width: 6,
                      borderRadius: 4,
                    }}
                  />
                </div>
              </div>
            )}
          </button>
        )}

        {/* edit mode button */}
        {[CURRENT_LIVE_TRANSCRIPT, COMPLETED_OR_UPLOADING_AUDIO].includes(
          state
        ) &&
          ownerOfTranscript && (
            <button
              className="editModeButton"
              style={{
                border: 0,
                backgroundColor: editMode ? selectedColor : unselectedColor,
                borderRadius: 60,
                marginLeft: 10,
                height: 40,
                width: 40,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                // in edit mode, leaving edit mode
                if (editMode) {
                  // update the transcript with user changes +
                  // add all the text that was being recorded while in edit mode
                } else {
                  scrollPosition.current = document.getElementsByClassName(
                    "transcribed_textbox"
                  )[0].scrollTop;
                }

                // toggle the state of edit mode
                setEditMode(!editMode);
              }}
              onMouseEnter={(event) => {
                if (editMode) {
                  return;
                }
                event.currentTarget.style.backgroundColor = hoverColor;
              }}
              onMouseLeave={(event) => {
                if (editMode) {
                  return;
                }
                event.currentTarget.style.backgroundColor = unselectedColor;
              }}
            >
              <ToolTip text={"Edit Transcript"} />
              <img
                style={{ width: 14, height: 14 }}
                src={editMode || darkMode ? pencilWhite : pencil}
                alt="edit mode button"
              />
            </button>
          )}

        {/* ai button */}
        {[CURRENT_LIVE_TRANSCRIPT, COMPLETED_OR_UPLOADING_AUDIO].includes(
          state
        ) && (
          <button
            onClick={() => {
              if (!GPT) {
                // turn on GPT Panel
                setGPT(true);
              } else {
                // turn off GPT panel
                setGPT(false);
              }
            }}
            style={{
              height: 40,
              width: 40,
              // display: "flex",
              position: "relative",
              flexDirection: "row",
              marginLeft: 10,
              border: "none",
              cursor: "pointer",
              justifySelf: "flex-end",
              alignSelf: "flex-end",
              // backgroundColor: "transparent",
              borderRadius: 60,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: GPT ? selectedColor : unselectedColor,
              color: GPT ? textColor : "gray",
              fontWeight: "600",
              fontSize: 12,
              display: "flex",
            }}
            onMouseEnter={(event) => {
              if (GPT) {
                return;
              }
              event.currentTarget.style.backgroundColor = hoverColor;
            }}
            onMouseLeave={(event) => {
              if (GPT) {
                return;
              }
              event.currentTarget.style.backgroundColor = unselectedColor;
            }}
          >
            <ToolTip text={"AI Explanations"} />
            <span style={{ color: darkMode ? "white" : "white" }}>AI</span>
          </button>
        )}

        {/* download button */}
        {[CURRENT_LIVE_TRANSCRIPT, COMPLETED_OR_UPLOADING_AUDIO].includes(
          state
        ) && (
          <button
            style={{
              border: 0,
              backgroundColor: downloadOptionsVisible
                ? selectedColor
                : unselectedColor,
              borderRadius: 60,
              marginLeft: 10,
              height: 40,
              width: 40,
              cursor: "pointer",
              // necessary so the dropdown shows up when clicked
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(event) => {
              if (!downloadOptionsVisible) {
                setDownloadOptionsVisible(true);
              } else {
                setDownloadOptionsVisible(false);
              }
              event.currentTarget.children[1].src = downloadWhite;
            }}
            onMouseEnter={(event) => {
              if (downloadOptionsVisible) {
                return;
              }
              event.currentTarget.style.backgroundColor = hoverColor;
            }}
            onMouseLeave={(event) => {
              if (downloadOptionsVisible) {
                return;
              }
              event.currentTarget.style.backgroundColor = unselectedColor;
            }}
            onBlur={(event) => {
              if (!darkMode) {
                event.currentTarget.children[1].src = download;
              }
            }}
          >
            <ToolTip text={"Download"} />
            {downloadOptionsVisible && (
              <DropDown
                links={[
                  {
                    name: "Transcript",
                    href: window.URL.createObjectURL(
                      new Blob(
                        [
                          (transcriptArray || [])
                            .map(
                              (blurbText, index) =>
                                formatTimestamp(timestamps[index]) +
                                "\n" +
                                blurbText
                            )
                            .join("\n\n"),
                        ],
                        {
                          type: "text/plain",
                        }
                      )
                    ),
                    downloadName: `${transcriptTitle} (Transcript)`,
                  },
                  {
                    name: "AI Summaries",
                    href: window.URL.createObjectURL(
                      new Blob(
                        [
                          ((gptJson || []).summaries || [])
                            .map(
                              (summary, index) =>
                                (gptJson || []).timestamps[index] +
                                "\n" +
                                summary
                            )
                            .join("\n\n"),
                        ],
                        {
                          type: "text/plain",
                        }
                      )
                    ),
                    downloadName: `${transcriptTitle} (AI Summaries)`,
                    disabled:
                      !gptJson.summaries || gptJson.summaries.length === 0,
                  },
                  {
                    name: "Audio Recording",
                    href: audioUrl,
                    newTab: true,
                    downloadName: `${transcriptTitle} (Audio)`,
                    disabled: audioUrl === "",
                  },
                ]}
                func={() => setDownloadOptionsVisible(false)}
                top={-94}
                left={-12}
              />
            )}
            <img
              style={{ width: 14, height: 14 }}
              src={darkMode ? downloadWhite : download}
              alt="download button"
            />
          </button>
        )}

        {/* reset button for not logged in */}
        {started && !isLoggedIn && (
          <button
            onClick={stopTranscribing}
            style={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              marginLeft: 10,
              marginRight: 0,
              border: "none",
              cursor: "pointer",
              borderRadius: 60,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "tomato",
              color: "white",
              fontWeight: "700",
              fontSize: 12,
            }}
          >
            <span style={{ padding: 8 }}>Reset</span>
          </button>
        )}

        {/* done button for logged in */}
        {state === CURRENT_LIVE_TRANSCRIPT && isLoggedIn && (
          <button
            onClick={stopTranscribing}
            style={{
              height: 40,
              display: "flex",
              flexDirection: "row",
              marginLeft: 10,
              marginRight: 0,
              border: "none",
              cursor: "pointer",
              borderRadius: 60,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "mediumseagreen",
              color: darkMode ? "white" : "black",
              fontWeight: "700",
              fontSize: 12,
              position: "relative",
            }}
            onMouseEnter={(event) => {
              event.currentTarget.style.backgroundColor = "#79d9a4";
            }}
            onMouseLeave={(event) => {
              event.currentTarget.style.backgroundColor = "mediumseagreen";
            }}
          >
            <ToolTip text={"End Transcription"} />
            <span style={{ padding: 8, color: darkMode ? "white" : "white" }}>
              Done
            </span>
          </button>
        )}

        {state === COMPLETED_OR_UPLOADING_AUDIO && (
          <button
            onClick={() => {
              setShowSummary(true);
              if (!summary) {
                fetchSummary(supabase, dispatch, transcriptArray.join(" "));
              }
            }}
            style={{
              height: 40,
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              flexDirection: "row",
              marginLeft: 10,
              border: "none",
              cursor: "pointer",
              justifySelf: "flex-end",
              alignSelf: "flex-end",
              // backgroundColor: "transparent",
              borderRadius: 60,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: unselectedColor,
              color: darkMode ? "white" : "gray",
              fontWeight: "600",
              marginRight: 5,
              fontSize: 12,
              position: "relative",
            }}
            onMouseEnter={(event) => {
              event.currentTarget.style.backgroundColor = hoverColor;
            }}
            onMouseLeave={(event) => {
              event.currentTarget.style.backgroundColor = unselectedColor;
            }}
          >
            <ToolTip text={"Get Summary"} />
            <p>SUMMARIZE</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default ControlPanel;
